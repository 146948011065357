import { CSSProperties, lazy } from 'react';

import { SuspenseWrapper } from './SuspenseWrapper';

type Props = {
  className: string,
  src: string,
  alt?: string,
  style?: CSSProperties,
};


export function LazyImageSuspense(props: Props) {
  const LazyImage = lazy(() => import('./LazyImage'));

  return (
    <SuspenseWrapper>
      <LazyImage
        className={props.className}
        src={props.src}
        alt={props.alt}
        style={props.style}
      />
    </SuspenseWrapper>
  );
}
