import { PureComponent } from 'react';

import appMobile from '../../images/appointment/mobile.png';

import { RouteComponentProps, withRouter } from "react-router-dom";
import { DoctorId, HospitalId, TreatmentId } from "../../interfaces";
import { QuoteFormWithEntity } from "./QuoteFormWithEntity";
import { getQueryParams } from "../../utilities/helpers";
import {LazyImageSuspense} from "../../shared";

import './Quotes.scss';

type Props = RouteComponentProps<{
  treatment?: TreatmentId,
  doctor?: DoctorId,
  hospital?: HospitalId,
}>;
type State = Record<string, never>;

class QuoteWithEntity extends PureComponent<Props, State> {
  render() {
    const hospital = getQueryParams<HospitalId>(this.props.history.location.search, 'hospital') || null;
    const treatment = getQueryParams<TreatmentId>(this.props.history.location.search, 'treatment') || null;
    const doctor = getQueryParams<DoctorId>(this.props.history.location.search, 'doctor') || null;

    return (
      <>
        <section className="section-area account-wraper1">
          <div className="container-fluid">
            <div
              className="appointment-inner section-sp2"
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-xl-5 g-0 col-lg-6 col-md-6">
                    <QuoteFormWithEntity
                        hospital={hospital}
                        doctor={doctor}
                        treatment={treatment}
                    />
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-6">
                    <div className="appointment-thumb">
                      <LazyImageSuspense className="" src={appMobile} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </>
    );
  }
}

const WithRouter = withRouter(QuoteWithEntity);

export { WithRouter as QuoteWithEntity };
