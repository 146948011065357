import {Component, ContextType} from 'react';

import { Doctor } from '../../API/interfaces';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
} from '../../shared/Pagination/PaginationContext';
import { FiltersProvider } from '../../shared/Filters';
import { I18Context } from '../../shared/I18Context';
import { DoctorsAPI } from '../../API/DoctorsAPI';

import { Doctors } from './Doctors';
import { Quote } from "../Quotes";

type Props = {
  doctors: Doctor[],
  count: number,
};

type State = Record<string, never>;

class DoctorsWrapper extends Component<Props, State> {
  context!: ContextType<typeof I18Context>;
  static contextType = I18Context;

  static async getInitialProps(): Promise<any> {
    try {
      const doctors = await DoctorsAPI.doctorsWithCount({
        page: DEFAULT_PAGE,
        limit: DEFAULT_LIMIT,
      });
      return {
        doctors: doctors.data,
        count: doctors.count,
      };
    } catch (err: any) {
      console.error(err);
    }
  }

  render() {
    return (
      <>
        <FiltersProvider>
          <Doctors
            i18Initialized={this.context.isInitialized}
            doctors={this.props.doctors || []}
            count={this.props.count || 0}
          />
        </FiltersProvider>

        <Quote />
      </>
    );
  }
}

export { DoctorsWrapper };

export default DoctorsWrapper;
