import get from 'lodash/get';

import {api} from '../axiosConfig';
import {doctorsURL} from '../utilities/urls';
import {
  injectPageNumber,
  injectPageNumberWithCount,
  Paginated
} from '../utilities/tools';
import {DoctorId, HospitalId} from '../interfaces';

import {Doctor, DoctorShort} from './interfaces';

export class DoctorsAPI {
  static doctors(
    params: {
      page: number,
      limit: number,
      hospital?: HospitalId,
      name?: string,
      city?: string | null,
      speciality?: string | null,
    },
  ): Promise<Paginated<Doctor>[] | never> {
    return api.get(`${doctorsURL}/`, {
      params: {
        page: params.page,
        limit: params.limit,
        hospital: params?.hospital,
        name: params?.name,
        city: !params?.city || params?.city === 'All' ? undefined : decodeURI(params?.city || ''),
        speciality: !params?.speciality || params?.speciality === 'All' ? undefined : decodeURI(params?.speciality || ''),
      },
    })
      .then((response) => {
        const data = get(response, ['data', 'results'], null);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return data as Doctor[];
      })
      .then(injectPageNumber(params.page, params.limit));
  }

  static doctorsShort(
    params: {
      page: number,
      limit: number,
      hospital?: HospitalId,
      name?: string,
      city?: string | null,
      cityId?: string | null,
      speciality?: string | null,
    },
  ): Promise<Paginated<DoctorShort>[] | never> {
    return api.get(`${doctorsURL}-short/`, {
      params: {
        page: params.page,
        limit: params.limit,
        hospital: params?.hospital,
        name: params?.name,
        city: !params?.city || params?.city === 'All' ? undefined : decodeURI(params?.city || ''),
        cirtId: params?.cityId,
        speciality: !params?.speciality || params?.speciality === 'All' ? undefined : decodeURI(params?.speciality || ''),
      },
    })
      .then((response) => {
        const data = get(response, ['data', 'results'], null);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return data as Doctor[];
      })
      .then(injectPageNumber(params.page, params.limit));
  }

  static doctorsWithCount(
    params: {
      page: number,
      limit: number,
      hospital?: HospitalId,
      name?: string,
      city?: string | null,
      speciality?: string | null,
    },
  ): Promise<{
    data: Paginated<Doctor>[],
    count: number,
  } | never> {
    return api.get(`${doctorsURL}/`, {
      params: {
        page: params.page,
        limit: params.limit,
        hospital: params?.hospital,
        name: params?.name,
        city: !params?.city || params?.city === 'All' ? undefined : decodeURI(params?.city || ''),
        speciality: !params?.speciality || params?.speciality === 'All' ? undefined : decodeURI(params?.speciality || ''),
      },
    })
      .then((response) => {
        const data = get(response, ['data', 'results'], null);
        const count = get(response, ['data', 'count'], 0);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return {
          data: data as Doctor[],
          count,
        };
      })
      .then(injectPageNumberWithCount(params.page, params.limit));
  }

  static doctor(doctorId: DoctorId): Promise<Doctor | never> {
    return api.get(`${doctorsURL}/${doctorId}/`)
      .then((response) => {
        const data = get(response, ['data'], null);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return data;
      });
  }
}