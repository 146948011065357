import {Component, ContextType} from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Accordion, Alert, Spinner } from 'react-bootstrap';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import './Details.scss';

import { PageStatus } from '../../enums';
import { Hospital } from '../../API/interfaces';
import { HospitalsAPI } from '../../API/HospitalsAPI';
import { HospitalId } from '../../interfaces';
import { getParam } from '../../utilities/helpers';
import {
  PageHeader,
  Show,
  BookAppointmentLink,
  WhatsappContact,
  LazyImageSuspense
} from '../../shared';
import { DoctorsListShort } from '../Doctors';
import { I18Context } from '../../shared/I18Context';
import { HospitalsSliderByCities } from "../../shared/Similars";
import {AccreditationIcons} from "../../shared/AccreditationIcons";
import { Quote } from "../Quotes";
import {BlogsSlider} from "../Blogs/BlogsSlider";

/* eslint-disable @typescript-eslint/object-curly-spacing */

type Props = {
  hospital: Hospital | null,
} & RouteComponentProps<{
  hospitalId: HospitalId,
}> & WithTranslation;

type State = {
  status: PageStatus,
  hospital: Hospital | null,
  error: string | null,
};

class Details extends Component<Props, State> {
  context!: ContextType<typeof I18Context>;
  static contextType = I18Context;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      hospital: this.props.hospital,
      error: null,
    };

    this.fetchHospital = this.fetchHospital.bind(this);
  }

  componentDidMount() {
    this.props.i18n.on('languageChanged', this.fetchHospital);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.hospital?.id !== prevProps.hospital?.id) {
      this.setState({
        hospital: this.props.hospital,
      });
    }
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.fetchHospital);
  }

  fetchHospital(): void {
    if (!this.context.isInitialized) return;

    Promise.resolve()
      .then(() => {
        this.setState({ status: PageStatus.Loading });
      })
      .then(() => {
        const hospitalId = getParam<HospitalId>(this, 'hospitalId');
        return HospitalsAPI.hospital(hospitalId);
      })
      .then((hospital) => {
        this.setState({ hospital, status: PageStatus.Loaded });
      })
      .catch((err) => {
        this.setState({ status: PageStatus.Error, error: err.message });
      });
  }

  getWhatsAppMessage(): string {
    return this.state.hospital
        ? `Hello, I want to contact, regarding ${this.state.hospital.name}-${this.state.hospital.city.city} https://www.alfplus.com/${this.props.i18n.language}/hospitals/${this.state.hospital.id}, Thanks`
        : 'Hello, I have a query';
  }

  render() {
    const hospitalId = getParam<HospitalId>(this, 'hospitalId', false);
    const city = this.state.hospital?.city.city;

    return (
      <>
        <Helmet>
          <html lang={this.props.i18n.language}/>
          <title>
            {this.props.t(
                'hospitalTitle',
                {
                  name: this.state.hospital?.name,
                }
            )}
          </title>
          <meta
              name="description"
              content={this.props.t(
                  'hospitalDescription',
                  {
                    name: this.state.hospital?.name,
                    overview: this.state.hospital?.overviewheading,
                    city: this.state.hospital?.city.city,
                  }
              )}
          />
        </Helmet>

        <Show when={this.state.status === PageStatus.Loading}>
          <div className="d-flex justify-content-center mt-5">
            <Spinner animation="border"/>
          </div>
        </Show>

        <Alert show={this.state.status === PageStatus.Error}>
          {this.state.error}
        </Alert>

        <Show
            when={this.state.status === PageStatus.Loaded || !!this.state.hospital}>
          <div className="page-content bg-white">
            <PageHeader title={`${this.state.hospital?.name}, India`}/>

            <section className="section-area section-sp1 hospital">
              <div className="container mb-3">
                <div className="row">
                  <div className="col-lg-8 mb-3 order-1 order-lg-0">
                    <div className="ttr-media mb-3">
                      <LazyImageSuspense
                          src={this.state.hospital?.image1 || ''}
                          className="rounded"
                          alt={this.state.hospital?.name}
                      />
                    </div>

                    <div className="clearfix p-2">
                      <div className="head-text mb-3">
                        <h1 className="title mb-15">
                          {this.state.hospital?.name}
                        </h1>
                        <h6 className="mb-0">
                          {this.state.hospital?.overviewheading}
                        </h6>

                        <hr/>

                        <p
                            className="mb-0 overview"
                            dangerouslySetInnerHTML={{
                              __html: this.state.hospital?.overview || '',
                            }}
                        />
                      </div>
                    </div>

                    <div className="my-2">
                      <h6>{this.props.t('Specialities')}</h6>
                      <ul className="row list-unstyled">
                        {
                          this.state.hospital?.treatments?.map((spec) => (
                              <li
                                  className="col-md-3 col-lg-4"
                                  key={spec.id}
                              >
                                <Link
                                    to={`/${this.props.i18n.language}/costs`}
                                >
                                  &bull; {spec.name}
                                </Link>
                              </li>
                          ))
                        }
                      </ul>
                    </div>

                    <hr />

                    <div className="clearfix">
                      <Accordion className="accordion ttr-accordion1">
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            {this.props.t('Doctors')}
                          </Accordion.Header>
                          <Accordion.Body>
                            <Show when={!!hospitalId}>
                              <DoctorsListShort hospitalId={hospitalId}/>
                            </Show>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>

                  <div className="col-lg-4 order-0 order-lg-1">
                    <aside className="sticky-top pb-1">
                      <div className="widget">
                        <div>
                          <strong><u>{this.props.t('Accreditation')}</u>:
                          </strong>

                          <AccreditationIcons
                              acreditation={this.state.hospital?.acreditation || null}
                          />
                        </div>
                        <div>
                          <strong><u>{this.props.t('Location')}</u>: </strong>
                          <span className="text-14">
                            <Trans city={city}>
                              {{city}}, India
                            </Trans>
                          </span>
                        </div>

                        <hr/>

                        <div>
                          <strong><u>{this.props.t('Address')}</u>: </strong>
                          <span className="text-14">
                            {this.state.hospital?.address}
                          </span>
                        </div>
                      </div>
                      <div className="widget">
                        <BookAppointmentLink
                            onClick={() => {
                              this.props.history.push(
                                `/${this.props.i18n.language}/quotes/create?hospital=${this.state.hospital?.id}`,
                              );
                            }}
                        />
                      </div>

                      <div className="widget">
                        <WhatsappContact
                            size="fa-2x"
                            className="btn btn-danger-custom text-16 fw-bold d-inline-flex align-items-center py-1 px-3"
                            message={this.getWhatsAppMessage()}
                            showText
                        />
                      </div>
                    </aside>
                  </div>
                </div>

                <hr />

                <div className="similar-hospitals mt-4">
                  <HospitalsSliderByCities
                      city={this.state.hospital?.city.city}
                      currentId={this.state.hospital?.id}
                  />
                </div>
              </div>

              <Quote/>

              <hr />

              <BlogsSlider hospitalId={this.state.hospital?.id} />
            </section>
          </div>
        </Show>
      </>
    );
  }
}

const WithRouter = withRouter(Details);

const WithTrans = withTranslation()(WithRouter);

(WithTrans as any).getInitialProps = async function ({ match }): Promise<any> {
  try {
    const hospital = await HospitalsAPI.hospital(match.params.hospitalId);
    return {
      hospital,
    };
  } catch (err: any) {
    console.error(err);
  }
};

export { WithTrans as Details };

export default WithTrans;