import { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Show } from '../../shared';
import { Language } from '../../enums';

/* eslint-disable max-len */

type Props = WithTranslation;
type State = Record<never, never>;

class TermsOfUseSub extends PureComponent<Props, State> {
  render() {
    return (
      <>
        <h1>
          {this.props.t('Terms Of Use')}
        </h1>

        <Show when={this.props.i18n.language === Language.en}>
          The legal owner of this website is only Alfplus.com. Therefore, it has the
          sole authority to carry out any modification, addition, or deletion in any
          content of this website, which will be effective from the immediate date.
          <br/>
          The following website is a legal agreement binding upon the company and
          the
          users of the websites. The primary objective of Alfplus.com is to connect
          the users of the healthcare industry with the services providers in this
          sector. In other words, it is a medical tourism company that provides
          various products to make people aware of them and their importance.
          <br/>
          Every reader visiting this page must abide by all the conditions mentioned
          here. Through the act of accepting the terms and conditions, viewing the
          content, and accessing different functions contained herein, the user is
          deemed to have understood every point and is bound by the Agreement. If,
          in
          any case, he or she disagrees with any point given in the Terms of Use
          section, it will not be right to check the box for accepting the
          conditions.
          In such circumstances, they must desist from visiting the website further
          and not use any of the tools given on this website. Every time a user is
          accessing the website, he or she is agreeing to all the amendments and
          updates being made.
          <br/>
          All the contents of this website are completely for non-commercial
          purposes.
          It is at the users&apos; discretion whether to accept the terms of the
          website and use the content and tools.
        </Show>

        <Show when={this.props.i18n.language === Language.ar}>
          المالك القانوني لهذا الموقع هو ألف بلس ميديكلس فقط. لذلك ، لديها السلطة
          الوحيدة لإجراء أي تعديل أو إضافة أو حذف في أي محتوى من هذا الموقع ، والذي
          سيكون ساري المفعول من التاريخ الفوري.
          <br/>
          الموقع التالي هو اتفاقية قانونية ملزمة للشركة ومستخدمي المواقع
          الإلكترونية. الهدف الأساسي لـ ألف بلس ميديكلس هو ربط مستخدمي صناعة الرعاية
          الصحية بمقدمي الخدمات في هذا القطاع. بمعنى آخر ، إنها شركة سياحة طبية تقدم
          منتجات متنوعة لتوعية الناس بها وبأهميتها.
          <br/>
          يجب على كل قارئ يزور هذه الصفحة الالتزام بجميع الشروط المذكورة هنا. من
          خلال قبول الشروط والأحكام ، وعرض المحتوى ، والوصول إلى الوظائف المختلفة
          الواردة هنا ، يُعتبر المستخدم أنه قد فهم كل نقطة ويلتزم بالاتفاقية. إذا لم
          يوافق ، على أي حال ، على أي نقطة واردة في قسم شروط الاستخدام ، فلن يكون من
          المناسب تحديد المربع لقبول الشروط. في مثل هذه الظروف ، يجب عليهم الامتناع
          عن زيارة الموقع مرة أخرى وعدم استخدام أي من الأدوات المتوفرة على هذا
          الموقع. في كل مرة يقوم فيها المستخدم بالدخول إلى الموقع ، فإنه يوافق على
          جميع التعديلات والتحديثات التي يتم إجراؤها.
          <br/>
          جميع محتويات هذا الموقع بالكامل لأغراض غير تجارية. وفقًا لتقدير المستخدمين
          ، ما إذا كانوا سيقبلون شروط الموقع واستخدام المحتوى والأدوات.
        </Show>

        <Show when={this.props.i18n.language === Language.uz}>
          Bu veb -saytning qonuniy egasi faqat Alfplus.com. Shu sababli, u ushbu veb
          -saytning har qanday tarkibiga o&apos;zgartirish kiritish, qo&apos;shish yoki
          o&apos;chirishni amalga oshirish huquqiga ega, u eng yaqin kundan boshlab
          kuchga kiradi.
          <br/>
          Quyidagi veb -sayt kompaniya va veb -saytlar foydalanuvchilari uchun
          majburiy bo&apos;lgan huquqiy bitimdir. Alfplus.com ning asosiy maqsadi
          sog&apos;liqni saqlash sohasi foydalanuvchilarini ushbu sohadagi xizmat
          ko&apos;rsatuvchi provayderlar bilan bog&apos;lashdir. Boshqacha qilib aytganda, bu
          tibbiy turistik kompaniya bo&apos;lib, ular odamlarga ular va ularning
          ahamiyati to&apos;g&apos;risida xabardor qilish uchun turli xil mahsulotlarni taqdim
          etadi.
          <br/>
          Bu sahifaga kirgan har bir o&apos;quvchi bu erda ko&apos;rsatilgan shartlarga rioya
          qilishi shart. Shartlar va shartlarni qabul qilish, mazmunini ko&apos;rish va
          bu erda mavjud bo&apos;lgan turli xil funktsiyalarga kirish orqali,
          foydalanuvchi har bir narsani tushungan deb hisoblanadi va Shartnoma bilan
          bog&apos;liqdir. Agar har qanday holatda ham, u &quot;Foydalanish shartlari&quot;
          bo&apos;limida ko&apos;rsatilgan har qanday fikrga qo&apos;shilmasa, shartlarni qabul
          qilish uchun katakchani belgilash to&apos;g&apos;ri bo&apos;lmaydi. Bunday holda, ular
          veb -saytga tashrif buyurishni to&apos;xtatishi va ushbu veb -saytda berilgan
          vositalardan foydalanmasligi kerak. Har safar foydalanuvchi veb -saytga
          kirganda, u kiritilgan barcha o&apos;zgartirishlar va yangilanishlarga rozi
          bo&apos;ladi.
          <br/>
          Ushbu veb-saytdagi barcha ma&apos;lumotlar tijorat maqsadlarida ishlatilmagan.
          Veb -sayt shartlarini qabul qilish va tarkib va vositalardan foydalanish
          foydalanuvchilar ixtiyorida.
        </Show>
      </>
    );
  }
}

const WithTrans = withTranslation()(TermsOfUseSub);

export { WithTrans as TermsOfUseSub };
