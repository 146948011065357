import { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';

import { LazyImageSuspense } from './LazyImageSuspense';

import earliestAppointment from 'images/earliest-appointment.jpg';
import healthCareProvider from 'images/health-care-provider.jpg';
import fastHospitalisation from 'images/fast-hospitalisation.jpg';
import affordablePackage from 'images/affordable-package.jpg';

import './Work.scss';

type Props = WithTranslation;
type State = Record<string, never>;

class Work extends PureComponent<Props, State> {
  render() {
    return (
      <>
        <section className="section-area section-sp5 work-area">
          <div className="container-sm">
            <div className="heading-bx text-center">
              <h6 className="title-ext text-primary">
                {this.props.t('We can assist you across India')}
              </h6>
              <h2 className="title">
                {this.props.t('Transparent, Professional and Hassle-free')}
              </h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-3 col-sm-6 mb-3">
                <div className="work-bx work-bx-1 position-relative">
                  <LazyImageSuspense
                    src={earliestAppointment}
                    alt="earliest-appointment"
                    className="position-absolute w-100 h-100"
                    style={{
                      left: '0',
                      top: '0',
                      zIndex: '-1',
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                  />
                  <div className="work-content">
                    <div className="title text-white">
                      {this.props.t('2000+ best doctors partnered for early appointments')}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 mb-3">
                <div className="work-bx work-bx-2 position-relative">
                  <LazyImageSuspense
                    src={healthCareProvider}
                    alt="healthCareProvider"
                    className="position-absolute w-100 h-100"
                    style={{
                      left: '0',
                      top: '0',
                      zIndex: '-1',
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                  />

                  <div className="work-content">
                    <div className="title text-white">
                      {this.props.t('100+ best hospitals across India listed')}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 mb-3">
                <div className="work-bx work-bx-3 position-relative">
                  <LazyImageSuspense
                    src={affordablePackage}
                    alt="affordablePackage"
                    className="position-absolute w-100 h-100"
                    style={{
                      left: '0',
                      top: '0',
                      zIndex: '-1',
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                  />

                  <div className="work-content">
                    <div className="title text-white">
                      {this.props.t('700+ patients treated, given great discounts')}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 mb-3">
                <div className="work-bx work-bx-4 position-relative">
                  <LazyImageSuspense
                    src={fastHospitalisation}
                    alt="fastHospitalisation"
                    className="position-absolute w-100 h-100"
                    style={{
                      left: '0',
                      top: '0',
                      zIndex: '-1',
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                  />

                  <div className="work-content">
                    <div className="title text-white">
                      {this.props.t('10000+ patients assisted online / offline')}
                    </div>
                  </div>
                </div>
              </div>

              <Link
                to={`/${this.props.i18n.language}/quotes/create`}
                className="btn btn-primary btn-lg shadow w-200px"
              >
                {this.props.t('Start Planning')}
              </Link>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const WithTrans = withTranslation()(Work);

export { WithTrans as Work };