import {Component, ContextType, Suspense} from 'react';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Show} from '../Show';

import {PaginationContext} from './PaginationContext';

import {PageStatus} from 'enums';
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";


const DEFAULT_LIMIT = 10;
const DEFAULT_PAGE = 1;

type State = {
  status: PageStatus,
  manualMode: boolean,
};

type Props = {
  onLoad: (
      page: number,
      limit: number,
  ) => Promise<void>,
};

class Pagination extends Component<Props, State> {
  static contextType = PaginationContext;
  context!: ContextType<typeof PaginationContext>;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      manualMode: false,
    };

    this.onPrevious = this.onPrevious.bind(this);
    this.onNext = this.onNext.bind(this);
    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    this.context.reset();
    this.context.emitter.on('PAGINATION', this.fetch);
  }

  fetch(page: number, limit: number): void {
    Promise.resolve()
        .then(() => {
          this.setState({status: PageStatus.Loading});
        })
        .then(() => {
          return this.props.onLoad(
              page || DEFAULT_PAGE,
              limit || DEFAULT_LIMIT,
          );
        })
        .then(() => {
          this.setState({status: PageStatus.Loaded});
        })
        .catch(() => {
          this.setState({status: PageStatus.Error});
        });
  }

  onNext() {
    this.context.setPage(this.context.page + 1);
  }

  onPrevious() {
    this.context.setPage(this.context.page - 1);

  }

  onPageChange(value: string): void {
    const page = Number.isNaN(value) || !value ? 0 : parseInt(value, 10);
    this.context.setPage(page, true);
  }

  isNextAllowed(): boolean {
    return this.state.status !== PageStatus.Loading;
  }

  isPreviousAllowed(): boolean {
    return this.context.page > 1 && this.state.status !== PageStatus.Loading;
  }

  render() {
    return (
        <>
          <div className="pagination-main d-flex align-items-center">
            <Show when={!this.state.manualMode}>
              <Button
                  onClick={this.onPrevious}
                  variant="primary"
                  disabled={!this.isPreviousAllowed()}
                  size="sm"
                  className="btn btn-sm btn-primary p-2"
                  title={`Previous page: ${this.context.page - 1}`}
              >
                <Suspense>
                  <FontAwesomeIcon
                      icon={solid('arrow-alt-circle-left')}
                      size="lg"
                  />
                </Suspense>
              </Button>
            </Show>

            <div className="form-group">
              <input
                  type="text"
                  id="page"
                  value={this.context.page || 0}
                  onChange={(event) => {
                    this.onPageChange(event.target.value);
                  }}
                  onClick={() => {
                    this.setState({manualMode: true});
                  }}
                  disabled={this.state.status === PageStatus.Loading}
                  style={{width: '70px'}}
                  className=" form-control border border-primary px-3 py-2 mx-2"
              />
            </div>

            <Show when={!this.state.manualMode}>
              <Button
                  onClick={this.onNext}
                  variant="primary"
                  disabled={!this.isNextAllowed()}
                  size="sm"
                  className="btn btn-sm btn-primary p-2"
                  title={`Next page: ${this.context.page + 1}`}
              >
                <Suspense>
                  <FontAwesomeIcon
                      icon={solid('arrow-alt-circle-right')}
                      size="lg"
                  />
                </Suspense>
              </Button>
            </Show>

            <Show when={this.state.manualMode}>
              <Button
                  onClick={() => {
                    this.setState({manualMode: false}, () => {
                      this.fetch(this.context.page, this.context.limit);
                    });
                  }}
                  variant="primary"
                  disabled={this.state.status === PageStatus.Loading}
                  size="sm"
                  className="btn btn-sm btn-primary p-2 me-2"
                  title={`Next page: ${this.context.page + 1}`}
              >
                <Suspense>
                  <FontAwesomeIcon
                      icon={solid('check-circle')}
                      size="lg"
                  />
                </Suspense>
              </Button>

              <Button
                  onClick={() => {
                    this.setState({manualMode: false});
                  }}
                  variant="primary"
                  disabled={this.state.status === PageStatus.Loading}
                  size="sm"
                  className="btn btn-sm btn-primary p-2"
                  title={`Next page: ${this.context.page + 1}`}
              >
                <Suspense>
                  <FontAwesomeIcon
                      icon={solid('times-circle')}
                      size="lg"
                  />
                </Suspense>
              </Button>
            </Show>
          </div>

          <div className="text-12 text-info fst-italic">
            Enter page number
          </div>
        </>
    );
  }
}

export {Pagination};
