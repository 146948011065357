import { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import { HospitalShort } from '../../API/interfaces';
import { AccreditationIcons } from '../../shared/AccreditationIcons';
import { LazyImageSuspense, WhatsappContact } from '../../shared';

import './HospitalCardMain.scss';

type Props = {
  hospital: HospitalShort,
} & WithTranslation;

type State = Record<string, never>;

class HospitalCardMain extends PureComponent<Props, State> {
  getWhatsAppMessage(): string {
    return this.props.hospital
      ? `Hello, I want to contact, regarding ${this.props.hospital.name}-${this.props.hospital.address} https://www.alfplus.com/${this.props.i18n.language}/hospitals/${this.props.hospital.id}, Thanks`
      : 'Hello, I have a query';
  }

  render() {
    return (
      <div className="hospital-card-main">
        <div className="hospital-card-main-media">
          <Link
            to={`/${this.props.i18n.language}/hospitals/${this.props.hospital.id}`}
          >
            <LazyImageSuspense
              src={this.props.hospital.image1 || ''}
              className="hospital-img"
              alt={this.props.hospital.name}
            />
          </Link>
        </div>

        <div className="hospital-card-main-media-info">
          <h6 className="hospital-card-main-media-title line-clamp-2">
            <Link
              to={`/${this.props.i18n.language}/hospitals/${this.props.hospital.id}`}
            >
              {this.props.hospital.name}
            </Link>
          </h6>

          <div className="text-14 hospital-card-main-media-details">
            <div className="line-clamp-2 fst-italic">
              {this.props.hospital.overviewheading}
            </div>

            <div className="line-clamp-2 text-13">
              <strong>{this.props.t('Location')}: </strong>
              {this.props.hospital.address}
            </div>

            <div className="accreditation">
              <strong>{this.props.t('Accreditation')}: </strong>

              <AccreditationIcons
                acreditation={this.props.hospital.acreditation}
              />
            </div>
          </div>

          <Link
            to={`/${this.props.i18n.language}/hospitals/${this.props.hospital.id}`}
            className="btn btn-outline-primary btn-sm"
          >
            {this.props.t('View Details')}
          </Link>

          <WhatsappContact
            message={this.getWhatsAppMessage()}
            className="btn btn-sm bg-transparent border-0 p-0 whatsapp-btn ms-3"
          />
        </div>
      </div>
    );
  }
}

const WithTrans = withTranslation()(HospitalCardMain);

export { WithTrans as HospitalCardMain };
