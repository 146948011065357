import { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Language } from '../../enums';

import { Show } from 'shared';

/* eslint-disable max-len */

type Props = WithTranslation;
type State = Record<never, never>;

class CopyrightsSub extends PureComponent<Props, State> {
  render() {
    return (
      <>
        <h1>{this.props.t('Copyrights')}</h1>

        <Show when={this.props.i18n.language === Language.en}>
          <>
        Each and every content and information of Alfplus.com is in compliance
        with
        the Indian and international laws, regulations, and provisos thereunder.
        Furthermore, the copyright, logo, and trademark of Alfplus.com are the
        sole
        property of the company only. No person is allowed to copy, republish,
        reproduce, post, display, frame, transmit or upload any of the content or
        logo without the prior consent of the company. In addition, the users are
        only permitted to download, display, view, and print any of the materials
        forming part of this website. However, you can use such contents for
        non-commercial and personal use only, just for one time. The company
        restricts the rights to modify, delete, add or change any of the content,
        image, or information on the side of the users or visitors. We will take
        strict action against the person not complying with the rules as stated in
        the Terms of Use section. Any discrepancy or infringement with any of the
        content of the page will be considered as a Breach of Contract. The
        company
        can also suspend that user even for a lifetime if the case is a serious
        one.
            <br/>
        We comply with every law, for the time being in force, in the domestic and
        international perspectives. However, being the users, it is your
        responsibility to follow the laws of your country whatsoever. If your
        country prohibits the publication or display of any information contained
        in
        this website; or disallows access to this website or any facilities
        provided, due to any reason, being your nationality, religion, residence,
        or
        otherwise, you must not use any content of this website. This is
        definitely
        applicable for the personal purposes of the users.
            <br/>
        We are governed by the local, state, and national laws of India
        substantially and also abide by some of the related international laws.
        Our
        company states that the terms of use, along with some modifications, at
        any
        time, will be construed as per the latest regulatory standards prevalent
        in
        India. The company is not involved in displaying any of the content or
        publishing any of the information, which is inappropriate as per the
        Indian
        Constitution. Any post in contravention to the said laws will not be
        accepted by any means.
            <br/>
        The user of this website must hereby agree to understand the rules in the
        same sense as portrayed by the company. Any proceeding or case having a
        connection with Alfplus.com or any of the matters contained therein can be
        heard by any competent authority or court in India as per the concerned
        jurisdiction. The concerned party may be summoned or irrevocably respond
        to
        such jurisdictional matters as necessary. However, according to the Terms
        of
        Use related to this website, the language of such pending proceedings or
        Dispute Resolution Panel must be English.
          </>
        </Show>

        <Show when={this.props.i18n.language === Language.ar}>
          <>
            يتوافق كل محتوى ومعلومات من ألف بلس ميديكلس مع القوانين واللوائح الهندية
            والدولية والبنود الواردة بموجبه. علاوة على ذلك ، فإن حقوق النشر والشعار
            والعلامة التجارية الخاصة بـ ألف بلس ميديكلس هي ملكية حصرية للشركة فقط. لا
            يُسمح لأي شخص بنسخ أو إعادة نشر أو إعادة إنتاج أو نشر أو عرض أو تأطير أو
            نقل أو تحميل أي محتوى أو شعار دون موافقة مسبقة من الشركة. بالإضافة إلى ذلك
            ، يُسمح للمستخدمين فقط بتنزيل أي من المواد التي تشكل جزءًا من هذا الموقع
            وعرضها وعرضها وطباعتها. ومع ذلك ، يمكنك استخدام هذه المحتويات للاستخدام
            الشخصي وغير التجاري فقط ، ولمرة واحدة فقط. تقيد الشركة حقوق تعديل أو حذف
            أو إضافة أو تغيير أي محتوى أو صورة أو معلومات من جانب المستخدمين أو
            الزوار. سنتخذ إجراءات صارمة ضد الشخص الذي لا يمتثل للقواعد كما هو مذكور في
            قسم شروط الاستخدام. سيتم اعتبار أي تناقض أو انتهاك لأي من محتويات الصفحة
            بمثابة خرق للعقد. يمكن للشركة أيضًا تعليق هذا المستخدم حتى لمدى الحياة إذا
            كانت الحالة خطيرة.
            <br/>
            نحن نلتزم بكل قانون ، في الوقت الحالي ، ساري المفعول ، على الصعيدين المحلي
            والدولي. ومع ذلك ، نظرًا لكونك مستخدمًا ، فمن مسؤوليتك اتباع قوانين بلدك
            على الإطلاق. إذا كان بلدك يحظر نشر أو عرض أي معلومات واردة في هذا الموقع ؛
            أو لا يسمح بالوصول إلى هذا الموقع أو أي من المرافق المقدمة ، لأي سبب من
            الأسباب ، سواء كانت جنسيتك أو دينك أو إقامتك أو غير ذلك ، يجب عليك عدم
            استخدام أي محتوى من هذا الموقع. هذا ينطبق بالتأكيد على الأغراض الشخصية
            للمستخدمين.
            <br/>
            نحن محكومون بالقوانين المحلية والولائية والوطنية في الهند إلى حد كبير
            ونلتزم أيضًا ببعض القوانين الدولية ذات الصلة. تنص شركتنا على أن شروط
            الاستخدام ، إلى جانب بعض التعديلات ، في أي وقت ، سيتم تفسيرها وفقًا لأحدث
            المعايير التنظيمية السائدة في الهند. لا تشارك الشركة في عرض أي محتوى أو
            نشر أي معلومات غير مناسبة وفقًا للدستور الهندي. لن يتم قبول أي مشاركة
            تتعارض مع القوانين المذكورة بأي شكل من الأشكال.
            <br/>
            يجب أن يوافق مستخدم هذا الموقع بموجبه على فهم القواعد بنفس المعنى الذي
            تصوره الشركة. يمكن سماع أي إجراء أو قضية لها صلة بـ ألف بلس ميديكلس أو أي
            من المسائل الواردة فيه من قبل أي سلطة مختصة أو محكمة في الهند وفقًا
            للاختصاص القضائي المعني. يجوز استدعاء الطرف المعني أو الرد بشكل غير قابل
            للنقض على مثل هذه المسائل القضائية حسب الضرورة. ومع ذلك ، وفقًا لشروط
            الاستخدام المتعلقة بهذا الموقع ، يجب أن تكون لغة هذه الإجراءات المعلقة أو
            لوحة حل النزاع هي اللغة الإنجليزية.
          </>
        </Show>

        <Show when={this.props.i18n.language === Language.uz}>
          <>
            Alfplus.com saytining har bir tarkibi va ma&apos;lumotlari Hindiston va xalqaro
            qonunlar, qoidalar va shartlarga mos keladi. Bundan tashqari, Alfplus.com
            saytining mualliflik huquqi, logotipi va savdo belgisi faqat kompaniyaning
            mulki hisoblanadi. Hech kim
          </>
        </Show>
      </>
    );
  }
}

const WithTrans = withTranslation()(CopyrightsSub);

export { WithTrans as CopyrightsSub };
