import { EventEmitter } from 'events';

import { createContext } from 'react';

export const DEFAULT_LIMIT = 15;
export const DEFAULT_PAGE = 1;

export type TPaginationContext = {
  page: number,
  limit: number,
  emitter: EventEmitter,
  setPage: (page: number, silent?: boolean) => void,
  setLimit: (limit: number, silent?: boolean) => void,
  reset: (silent?: boolean) => void,
};

const PaginationContext = createContext<TPaginationContext>(
  {
    page: DEFAULT_PAGE,
    limit: DEFAULT_LIMIT,
    emitter: new EventEmitter(),
    setPage: () => void 0,
    setLimit: () => void 0,
    reset: () => void 0,
  },
);

export {
  PaginationContext,
};
