import {Component, ContextType, Suspense} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {WithTranslation, withTranslation} from 'react-i18next';

import {Show} from '../Show';

import {FiltersContext} from './FiltersContext';
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

type Props = WithTranslation;
type State = Record<string, never>;

class FiltersMenu extends Component<Props, State> {
  static contextType = FiltersContext;
  context!: ContextType<typeof FiltersContext>;

  render() {
    return (
        <div>
          <div className="d-block d-lg-none">
            <button
                onClick={() => {
                  this.context.onToggle();
                }}
                className="btn btn-sm btn-outline-primary p-2"
            >
              <Suspense>
                <FontAwesomeIcon icon={solid('sliders-h')} className="me-1"/>
              </Suspense>
              {this.props.t('Filters')}
            </button>
          </div>

          <Show when={!!this.context.speciality || !!this.context.city}>
            <div className="d-none d-lg-block">
              <h6>
                <Suspense>
                  <FontAwesomeIcon icon={solid('sliders-h')} className="me-1"/>
                </Suspense>
                {this.props.t('Filters')}
              </h6>
            </div>
          </Show>

          <div className="d-flex align-items-center flex-wrap mb-1">
            <Show when={!!this.context.city}>
              <button
                  onClick={() => {
                    this.context.onCity(null);
                  }}
                  className="btn btn-light p-2"
              >
                {this.props.t('City')}:
                {' '}
                {this.context.city?.city}
                <Suspense>
                  <FontAwesomeIcon icon={solid('times')} className="ms-2"/>
                </Suspense>
              </button>
            </Show>

            <Show when={!!this.context.speciality}>
              <button
                  onClick={() => {
                    this.context.onSpeciality(null);
                  }}
                  className="btn btn-light p-2"
              >
                {this.props.t('Speciality')}:
                {' '}
                {this.context.speciality?.name}
                <Suspense>
                  <FontAwesomeIcon icon={solid('times')} className="ms-2"/>
                </Suspense>
              </button>
            </Show>

            <Show when={!!this.context.speciality || !!this.context.city}>
              <button
                  onClick={() => {
                    this.context.onReset();
                  }}
                  className="btn btn-dark p-2 rounded-3"
              >
                {this.props.t('Clear All')}
                <Suspense>
                  <FontAwesomeIcon icon={solid('times')} className="ms-2"/>
                </Suspense>
              </button>
            </Show>
          </div>
        </div>
    );
  }
}

const WithTrans = withTranslation()(FiltersMenu);

export {WithTrans as FiltersMenu};
