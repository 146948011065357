import { Component, ContextType } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {PageStatus} from "../../enums";
import {Hospital} from "../../API/interfaces";
import {I18Context} from "../I18Context";
import {HospitalsAPI} from "../../API/HospitalsAPI";
import {HospitalsSliderMain} from "../HospitalsSliderMain";
import {Show} from "../Show";
import {HospitalId} from "../../interfaces";

/* eslint-disable @typescript-eslint/object-curly-spacing */

type Props = {
  city: string,
  currentId?: HospitalId | null,
} & WithTranslation;

type State = {
  status: PageStatus,
  hospitals: Hospital[],
  error: string | null,
};

class HospitalsSliderByCities extends Component<Props, State> {
  context!: ContextType<typeof I18Context>;
  static contextType = I18Context;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      hospitals: [],
      error: null,
    };

    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    this.fetchInitial();

    this.props.i18n.on('languageChanged', this.fetch);
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.fetch);
  }

  fetchInitial(): void {
    if (!this.props.city) return;

    Promise.resolve()
      .then(() => {
        this.setState({ status: PageStatus.Loading });
      })
      .then(() => {
        return HospitalsAPI.hospitals(
            {
              page: 1,
              limit: 6,
              city: this.props.city,
            },
        );
      })
      .then((hospitalsRes) => {
        const hospitals = hospitalsRes.filter((item) => {
          return item.id !== this.props.currentId
        });
        this.setState({ hospitals, status: PageStatus.Loaded });
      })
      .catch((err) => {
        this.setState({ status: PageStatus.Error, error: err.message });
      });
  }

  fetch(): void {
    if (!this.context.isInitialized) return;
    if (!this.props.city) return;

    Promise.resolve()
      .then(() => {
        this.setState({ status: PageStatus.Loading });
      })
      .then(() => {
        return HospitalsAPI.hospitals(
            {
              page: 1,
              limit: 6,
              city: this.props.city,
            },
        );
      })
        .then((hospitalsRes) => {
          const hospitals = hospitalsRes.filter((item) => {
            return item.id !== this.props.currentId
          });
          this.setState({ hospitals, status: PageStatus.Loaded });
        })
      .catch((err) => {
        this.setState({ status: PageStatus.Error, error: err.message });
      });
  }

  render() {
    return (
      <>
        <Show when={!!this.state.hospitals.length}>
          <h3>
            {this.props.t('Similar Hospitals')}
          </h3>

          <HospitalsSliderMain hospitals={this.state.hospitals} />
        </Show>
      </>
    );
  }
}


const WithTrans = withTranslation()(HospitalsSliderByCities);

export { WithTrans as HospitalsSliderByCities };
