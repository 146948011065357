import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';

import {Show} from '../../shared';
import {PageStatus} from '../../enums';
import {Blog} from '../../API/interfaces';
import {BlogsAPI} from '../../API/BlogsAPI';
import {BlogCard} from "./BlogCard";
import {DoctorId, HospitalId, SpecialityId} from "../../interfaces";
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE
} from "../../shared/Pagination/PaginationContext";

type Props = {
  specialityId?: SpecialityId,
  doctorId?: DoctorId,
  hospitalId?: HospitalId,
} & WithTranslation;

type State = {
  status: PageStatus,
  blogs: Blog[],
  error: string | null,
};

class BlogsSlider extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      blogs: [],
      error: null,
    };
    this.fetchBlogs = this.fetchBlogs.bind(this);
  }

  componentDidMount() {
    this.fetchBlogs();
    this.props.i18n.on('languageChanged', this.fetchBlogs);
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.fetchBlogs);
  }

  fetchBlogs(): void {
    Promise.resolve()
      .then(() => {
        return BlogsAPI.blogs(
          {
            page: DEFAULT_PAGE,
            limit: DEFAULT_LIMIT,
            specialityId: this.props.specialityId,
            hospitalId: this.props.hospitalId,
            doctorId: this.props.doctorId,
          },
        );
      })
        .then((blogs) => {
          this.setState({
            blogs,
            status: PageStatus.Loaded
          })
        })
        .catch((err) => {
          this.setState({
            status: PageStatus.Error,
            blogs: [],
            error: err.message,
          });
        })
  }

  render(){
    return (
      <>
        <Show when={!!this.state.blogs.length}>
          <div className="container">
            <h4 className="mb-3">{this.props.t('blogs.blogs.heading')}</h4>

            <div className="row">
              {
                this.state.blogs.map((blog) => (
                    <div className="col-12">
                      <BlogCard blog={blog} />
                    </div>
                ))
              }
            </div>
          </div>
        </Show>
      </>
    );
  }
}

const WithTrans = withTranslation()(BlogsSlider);

export { WithTrans as BlogsSlider };
