import { Component, ContextType } from 'react';
import { Spinner } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';

import { PageStatus } from '../../enums';
import { Speciality } from '../../API/interfaces';
import { SpecialitiesAPI } from '../../API/SpecialitiesAPI';
import { Show } from '../Show';

import { FiltersContext } from './FiltersContext';

type Props = {
  i18Initialized: boolean,
} & WithTranslation;

type State = {
  status: PageStatus,
  specialities: Speciality[],
  error: string | null,
};

class FilterBySpecialities extends Component<Props, State> {
  context!: ContextType<typeof FiltersContext>;
  static contextType = FiltersContext;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      specialities: [],
      error: null,
    };

    this.fetchSpecialities = this.fetchSpecialities.bind(this);
  }

  componentDidMount() {
    this.fetchSpecialitiesInitial();

    this.props.i18n.on('languageChanged', this.fetchSpecialities);
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.fetchSpecialities);
  }

  fetchSpecialitiesInitial() {
    Promise.resolve()
      .then(() => {
        this.setState({ status: PageStatus.Loading });
      })
      .then(() => {
        return SpecialitiesAPI.specialities(1, 1000);
      })
      .then((specialities) => {
        this.updateFilter(specialities);
        this.setState({ specialities, status: PageStatus.Loaded });
      })
      .catch((err) => {
        this.setState({ status: PageStatus.Error, error: err.message });
      });
  }

  fetchSpecialities() {
    if (!this.props.i18Initialized) return;

    Promise.resolve()
      .then(() => {
        this.setState({ status: PageStatus.Loading });
      })
      .then(() => {
        return SpecialitiesAPI.specialities(1, 1000);
      })
      .then((specialities) => {
        this.updateFilter(specialities);
        this.setState({ specialities, status: PageStatus.Loaded });
      })
      .catch((err) => {
        this.setState({ status: PageStatus.Error, error: err.message });
      });
  }

  updateFilter(specialities: Speciality[]): void {
    if (!!this.context.speciality) {
      const speciality = specialities.find((specialityIn) => {
        return this.context.speciality?.id === specialityIn.id;
      });
      this.context.onSpeciality(speciality || null, true);
    }
  }

  render() {
    return (
      <>
        <Show when={this.state.status === PageStatus.Loading}>
          <Spinner animation="border" />
        </Show>

        <Show
          when={!this.state.error && this.state.status === PageStatus.Loaded}
        >
          <div className="h6">{this.props.t('By Specialities')}</div>
          {
            this.state.specialities.map((speciality) => (
              <div key={speciality.id} className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={(event) => {
                    if (event.target.checked) {
                      this.context.onSpeciality(speciality);
                    } else {
                      this.context.onSpeciality(null);
                    }
                  }}
                  checked={this.context.speciality?.id === speciality.id}
                  id={`${speciality.name}-${speciality.id}`}
                />
                <label
                  className="form-check-label"
                  htmlFor={`${speciality.name}-${speciality.id}`}
                >
                  {speciality.name}
                </label>
              </div>
            ))
          }
        </Show>
      </>
    );
  }
}

const WithTrans = withTranslation()(FilterBySpecialities);

export { WithTrans as FilterBySpecialities };
