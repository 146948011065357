import { PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { PageHeader } from '../../shared';

import { CopyrightsSub } from './CopyrightsSub';
import { TermsOfUseSub } from './TermsOfUseSub';
import { DisclaimerSub } from './DisclaimerSub';

/* eslint-disable max-len */

type Props = WithTranslation;
type State = Record<never, never>;

class Copyrights extends PureComponent<Props, State> {
  render() {
    return (
      <>
        <Helmet>
          <html lang={this.props.i18n.language} />
          <title>
            Alfplus Copyrights | Alfplus
          </title>
          <meta
              name="description"
              content="Each and every content and information of Alfplus.com is in compliance with the Indian and international laws, regulations, and provisos thereunder. Furthermore, the copyright, logo, and trademark of Alfplus.com are the sole property of the company only."
          />
        </Helmet>

        <div className="page-content bg-white copyrights">
          <PageHeader title={this.props.t('Copyrights')} />

          <section className="copyrights-wrap">
            <div className="container">
              <div>
                <CopyrightsSub />

                <hr />

                <TermsOfUseSub />

                <hr />

                <DisclaimerSub />
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const WithTrans = withTranslation()(Copyrights);

export { WithTrans as Copyrights };

export default WithTrans;
