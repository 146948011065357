import { PureComponent } from 'react';
import { WithTranslation, withTranslation} from 'react-i18next';
import { Helmet } from 'react-helmet';

import { PageHeader, Show } from '../../shared';
import { Language } from '../../enums';

type Props = WithTranslation;
type State = Record<never, never>;

class OurServices extends PureComponent<Props, State> {
  render() {
    return (
      <>
        <Helmet>
          <html lang={this.props.i18n.language} />
          <title>
            {this.props.t('Alfplus Services |  Alfplus')}
          </title>
          <meta
              name="description"
              content={this.props.t('AboutUsDescription')}
          />
        </Helmet>

        <div className="page-content bg-white our-services">
          <PageHeader title={this.props.t('Our Services')} />

          <section className="our-services-section">
            <div className="container">
              <div>
                <h1>{this.props.t('Our Services')}</h1>

                <div className="text-info fst-italic my-3">
                  <Show when={this.props.i18n.language === Language.en}>
                    Alfplus Medicals offers following services to the patients
                    travelling abroad for quality and cost effective
                    medical treatment.
                  </Show>

                  <Show when={this.props.i18n.language === Language.uz}>
                    Alfplus Medicals chet elga ketayotgan bemorlarga quyidagi
                    xizmatlarni taklif etadi sifatli va arzon narxlardagi
                    uchun tibbiy muolaja.
                  </Show>

                  <Show when={this.props.i18n.language === Language.ar}>
                    يقدم Alfplus Medicals الخدمات التالية للمرضى الذين يسافرون إلى الخارج للجودة
                    وفعالة من
                    حيث التكلفة العلاج الطبي.
                  </Show>
                </div>

                <Show when={this.props.i18n.language === Language.en}>
                  <h6>Treatment Related</h6>

                  <p>
                    • Doctor and Hospital Recommendation of highest standard<br />
                    • Evaluation of case from multiple specialist Doctors<br />
                    • Quote for treatment from hospitals<br />
                    • Second opinion<br />
                    • Priority Appointment with doctor on arrival in India<br />
                    • Priority Admission to chosen hospital<br />
                    • Assistance in post treatment rehabilitation or physiotherapy
                    at your place of stay<br />
                    • Assistance in Post treatment followups with surgeon
                  </p>

                  <h6>Travel</h6>
                  <p>
                  • Visa Assistance<br />
                  • Flight ticket Assistance<br />
                  • Airport pickup and drop<br />
                  • Assistance in travel within the city<br />
                  • Sightseeing recommendations and planning<br />
                  • Assistance in shopping
                  </p>

                  <h6>During Stay in India</h6>
                  <p>
                  • Local guide book<br />
                  • Assistance in local SIM Card<br />
                  • Assistance in Hotels or guest house selection<br />
                  • Daily Follow-up<br />
                  • Language Interpreter
                  </p>

                  <h6>Money Matters</h6>
                  <p>
                  • Assistance in resolving issues with the hospital bill<br />
                  • Assistance in Foreign Exchange
                  </p>
                </Show>

                <Show when={this.props.i18n.language === Language.ar}>
                  <h6>العلاج ذات الصلة</h6>
                  <p className="align-right">
                    • توصية الطبيب والمستشفى على أعلى مستوى<br />
                    • تقييم الحالة من عدة أطباء متخصصين<br />
                    • اقتباس للعلاج من المستشفيات<br />
                    • رأي ثاني<br />
                    • تحديد الأولوية مع الطبيب عند الوصول إلى الهند<br />
                    • أولوية القبول في المستشفى المختار<br />
                    • المساعدة في إعادة التأهيل بعد العلاج أو العلاج الطبيعي في
                    مكان إقامتك<br />
                    • المساعدة في متابعة ما بعد العلاج مع الجراح
                  </p>

                  <h6><strong>السفر</strong></h6>
                  <p>
                    • مساعدة التأشيرة<br />
                    • مساعدة تذكرة الطيران<br />
                    • الاستقبال في المطار وإسقاطه<br />
                    • المساعدة في السفر داخل المدينة<br />
                    • التوصيات والتخطيط لمشاهدة معالم المدينة<br />
                    • المساعدة في التسوق
                  </p>
                  <h6><strong>أثناء الإقامة في
                    الهند</strong></h6>
                  <p>
                    • كتاب الدليل المحلي<br />
                    • المساعدة في بطاقة SIM المحلية<br />
                    • المساعدة في اختيار الفنادق أو بيت الضيافة<br />
                    • المتابعة اليومية<br />
                    • مترجم لغة
                  </p>
                  <h6><strong>الأمور المالية</strong>
                  </h6>
                  <p>
                    • المساعدة في حل مشاكل فاتورة المستشفى<br />
                    • المساعدة في الصرف الأجنبي
                  </p>
                </Show>

                <Show when={this.props.i18n.language === Language.uz}>
                  <h6>Davolash bilan bog&apos;liq</h6>
                  <p>
                    • Doktor va shifoxona tavsiyalari eng yuqori standart<br />
                    • Bir nechta mutaxassis shifokorlar tomonidan ishni baholash<br />
                    • Kasalxonalardan davolanish uchun taklif<br />
                    • Ikkinchi fikr<br />
                    • Hindistonga kelganda shifokor bilan ustuvor uchrashuv<br />
                    • Tanlangan shifoxonaga birinchi navbatda qabul qilish<br />
                    • Yashash joyida davolanishdan keyingi reabilitatsiya yoki
                    fizioterapiyada yordam berish<br />
                    • Jarroh bilan davolanishdan keyingi kuzatuvlarda yordam
                  </p>
                  <h6><strong>Sayohat</strong></h6>
                  <p>
                    • Viza yordami<br />
                    • Parvoz chiptasi yordami<br />
                    • Aeroportda kutib olish va tushirish<br />
                    • Shahar ichida sayohat qilishda yordam<br />
                    • Ekskursiyalar bo&apos;yicha tavsiyalar va rejalashtirish<br />
                    • Xarid qilishda yordam
                  </p>
                  <h6><strong>Hindistonda qolish vaqtida</strong></h6>
                  <p>
                    • Mahalliy qo&apos;llanma<br />
                    • Mahalliy SIM-kartada yordam<br />
                    • Mehmonxona yoki mehmonxona tanlashda yordam<br />
                    • Kundalik kuzatuv<br />
                    • Til tarjimoni
                  </p>
                  <h6><strong>Pul muhim</strong></h6>
                  <p>
                    • Kasalxona to&apos;lovi bilan bog&apos;liq muammolarni hal qilishda yordam berish<br />
                    • Chet el valyutasida yordam
                  </p>
                </Show>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const WithTrans = withTranslation()(OurServices);

export { WithTrans as OurServices };

export default WithTrans;
