import {Component, ContextType} from 'react';
import {Alert, Spinner} from 'react-bootstrap';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';

import {PageHeader, Show} from '../../shared';
import {PageStatus} from '../../enums';
import {
  CityShort,
  Doctor,
  DoctorShort,
  HospitalShort
} from '../../API/interfaces';
import {getParam} from '../../utilities/helpers';
import {HospitalsAPI} from '../../API/HospitalsAPI';
import {DoctorsAPI} from '../../API/DoctorsAPI';
import {HospitalCardMain} from '../Hospitals';
import {DoctorCard} from '../Doctors';
import {I18Context} from '../../shared/I18Context';
import {Quote} from "../Quotes";
import {CityId} from "../../interfaces";
import {CitiesAPI} from "../../API/CitiesAPI";

type Props = {
  hospitals: HospitalShort[],
  city: CityShort | null,
  doctors: DoctorShort[],
} & RouteComponentProps<{
  cityId: string,
}> & WithTranslation;

type State = {
  status: PageStatus,
  hospitals: HospitalShort[],
  city: CityShort | null,
  doctors: DoctorShort[],
  error: string | null,
};

class HospitalsAndDoctors extends Component<Props, State> {
  static contextType = I18Context;
  context!: ContextType<typeof I18Context>;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      city: this.props.city,
      doctors: this.props.doctors || [],
      hospitals: this.props.hospitals || [],
      error: null,
    };

    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    this.props.i18n.on('languageChanged', this.fetch);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.city?.id !== prevProps.city?.id) {
      this.fetch();
    }
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.fetch);
  }

  fetch(): void {
    if (!this.context.isInitialized) return;

    Promise.resolve()
        .then(() => {
          this.setState({status: PageStatus.Loading});
        })
        .then(() => {
          const cityId = getParam<CityId>(this, 'cityId');

          return Promise.all([
            CitiesAPI.cityShort(cityId),
            HospitalsAPI.hospitalsShort({
              page: 1,
              limit: 6,
              cityId,
            }),
            DoctorsAPI.doctorsShort({
              page: 1,
              limit: 6,
              cityId,
            }),
          ]);
        })
        .then(([city, hospitals, doctors]) => {
          this.setState({
            city,
            doctors,
            hospitals,
            status: PageStatus.Loaded,
          });
        })
        .catch((err) => {
          this.setState({status: PageStatus.Error, error: err.message});
        });
  }

  render() {
    const city = this.state.city?.city;

    return (
        <>
          <Helmet>
            <html lang={this.props.i18n.language}/>
            <title>
              {this.props.t(
                  'bestHospitalsDoctorsTitle',
                  {city},
              )}
            </title>
            <meta
                name="description"
                content={this.props.t('bestHospitalsDoctorsDescription')}
            />
          </Helmet>

          <div className="page-content bg-white best-hospitals-and-doctors">
            <PageHeader
                title={this.props.t('Best hospitals and doctors in India')}
            />

            <Show when={this.state.status === PageStatus.Loading}>
              <div className="d-flex justify-content-center mt-5">
                <Spinner animation="border"/>
              </div>
            </Show>

            <Alert show={this.state.status === PageStatus.Error}>
              {this.state.error}
            </Alert>

            <section className="section-area section-sp1 hospitals-list">
              <div className="container">
                <h1>
                  {this.props.t(
                      'Best hospitals and doctors in {{city}}, India',
                      {city},
                  )}
                </h1>

                <Show when={this.state.hospitals.length > 0}>
                  <div className="mt-5 best-hospitals-list">
                    <h2 className="ms-2">
                      {this.props.t(
                          'Best hospitals in {{city}}, India',
                          {city},
                      )}
                    </h2>

                    <div className="row">
                      {
                        this.state.hospitals.map((hospital) => (
                            <div
                                key={hospital.id}
                                className="col-12 col-md-6 col-lg-4 mb-3"
                            >
                              <HospitalCardMain hospital={hospital}/>
                            </div>
                        ))
                      }
                    </div>

                    <div className="d-flex justify-content-center">
                      <Link
                          className="btn btn-sm btn-primary"
                          to={`/${this.props.i18n.language}/best-hospitals/cities/${this.state.city?.id}`}
                      >
                        {this.props.t('View All')}
                      </Link>
                    </div>
                  </div>
                </Show>

                <Show when={this.state.doctors.length > 0}>
                  <hr/>

                  <div className="mt-5 doctors-list">
                    <h2 className="ms-2">
                      {this.props.t(
                          'Best doctors in {{city}}, India',
                          {city},
                      )}
                    </h2>

                    <div className="row gx-2">
                      {
                        this.state.doctors.map((doctor) => (
                            <div
                                key={doctor.id}
                                className="col-12 col-md-4 col-lg-3 mb-3"
                            >
                              <DoctorCard
                                  doctor={doctor as Doctor}
                                  key={doctor.id}
                              />
                            </div>
                        ))
                      }
                    </div>

                    <div className="d-flex justify-content-center">
                      <Link
                          className="btn btn-sm btn-primary"
                          to={`/${this.props.i18n.language}/best-doctors/cities/${this.state.city?.id}`}
                      >
                        {this.props.t('View All')}
                      </Link>
                    </div>
                  </div>
                </Show>
              </div>

              <Quote/>
            </section>
          </div>
        </>
    );
  }
}

const WithRouter = withRouter(HospitalsAndDoctors);

const WithTrans = withTranslation()(WithRouter);

(WithTrans as any).getInitialProps = async function ({match}): Promise<any> {
  try {
    let city: CityShort | null = null;
    if (match.params.cityId !== 'All') {
      city = await CitiesAPI.cityShort(match.params.cityId);
    }
    const hospitals = await HospitalsAPI.hospitalsShort({
      page: 1,
      limit: 6,
      cityId: match.params.cityId,
    });
    const doctors = await DoctorsAPI.doctorsShort({
      page: 1,
      limit: 6,
      cityId: match.params.cityId,
    });
    return {
      city,
      hospitals,
      doctors,
    };

  } catch (err: any) {
    console.error(err);
  }
};

export {WithTrans as HospitalsAndDoctors};

export default WithTrans;
