import { lazy, PureComponent, ReactElement, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';

import '../index.scss';
import 'css/style.min.css';

import { Header } from './Header';
import { Footer } from './Footer';
import { FooterStickyContact } from './FooterStickyContact';

const BackToTop = lazy(() => import('shared/BackToTop'));

type Props = {
  children: ReactElement,
} & RouteComponentProps & WithTranslation;

type State = Record<string, never>;

class BodyLayout extends PureComponent<Props, State> {
  render() {
    return (
      <>
        <div className="page-wrapper">
          <Header/>

          {this.props.children}

          <Footer/>
          <FooterStickyContact/>
          <Suspense fallback={<>Loading...</>}>
            <BackToTop/>
          </Suspense>
        </div>
      </>
    );
  }
}

const WithRouter = withRouter(BodyLayout);

const WithTrans = withTranslation()(WithRouter);

export { WithTrans as BodyLayout };
