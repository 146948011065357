import {PureComponent} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Link} from "react-router-dom";

import {Blog} from '../../API/interfaces';
import {LazyImageSuspense} from "../../shared";
import logo from 'images/logo7.png'
import './BlogCard.scss';
import {getDate} from "../../utilities/helpers";

type Props = {
  blog: Blog,
} & WithTranslation;

type State = Record<string, never>;

class BlogCard extends PureComponent<Props, State> {
  render() {
    return (
        <div className="blog-card-wrapper">
          <div className="blog-card-custom">
            <div className="blog-media">
              <Link to={`/${this.props.i18n.language}/blogs/${this.props.blog.id}`}>
                <LazyImageSuspense
                    className=""
                    src={this.props.blog.thumbnail || logo}
                    alt={this.props.blog.name}
                />
              </Link>
            </div>
            <div className="blog-info">
              <div>
                <h6 className="line-clamp-2 blog-heading">
                  <Link to={`/${this.props.i18n.language}/blogs/${this.props.blog.id}`}>
                    {this.props.blog.name}
                  </Link>
                </h6>

                <div className="blog-date">
                  <i className="far fa-calendar-alt"></i> {getDate(this.props.blog.created_at)}
                </div>

                <div
                    className="blog-description"
                    dangerouslySetInnerHTML={{
                      __html: this.props.blog.description,
                    }}
                />
              </div>

              <Link
                  to={`/${this.props.i18n.language}/blogs/${this.props.blog.id}`}
                  className="btn btn-sm btn-primary blog-btn"
              >
                Read More
              </Link>
            </div>
          </div>
        </div>
    );
  }
}

const WithTrans = withTranslation()(BlogCard);

export {WithTrans as BlogCard};
