import { PureComponent, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation, WithTranslation } from 'react-i18next';

import './SpecialityLinkCard.scss';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Speciality } from '../API/interfaces';
import { SpecialityId } from '../interfaces';

import { Show } from './Show';
import { LazyImageSuspense } from './LazyImageSuspense';

type Props = {
  speciality: Speciality,
} & WithTranslation;

type State = Record<string, never>;

class SpecialityLinkCard extends PureComponent<Props, State> {
  render() {
    return (
      <Link
        to={`/${this.props.i18n.language}/specialities/${this.props.speciality.id}`}
      >
        <div className="featured-speciality-card">
          <Show when={!!this.props.speciality.thumbnail}>
            <div className="featured-speciality-card-media">
              <LazyImageSuspense
                alt={this.props.speciality.name}
                className=""
                src={this.props.speciality.thumbnail}
              />
            </div>
          </Show>

          <Show
            when={this.props.speciality.id === 28 as unknown as SpecialityId}
          >
            <div className="featured-speciality-card-media">
              <Suspense>
                <FontAwesomeIcon
                  icon={solid('hand-holding-heart')}
                  className="text-black lazy"
                  style={{
                    width: '60px',
                    height: '60px',
                  }}
                />
              </Suspense>
            </div>
          </Show>

          <Show
            when={this.props.speciality.id === 8 as unknown as SpecialityId}
          >
            <div className="featured-speciality-card-media">
              <Suspense>
                <FontAwesomeIcon
                  icon={solid('tooth')}
                  className="text-black lazy"
                  style={{
                    width: '60px',
                    height: '60px',
                  }}
                />
              </Suspense>
            </div>
          </Show>

          <div className="featured-speciality-card-title">
            {this.props.speciality.name}
          </div>
        </div>
      </Link>
    );
  }
}

const WithTrans = withTranslation()(SpecialityLinkCard);

export { WithTrans as SpecialityLinkCard };
