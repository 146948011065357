import { Component, ContextType } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PageStatus } from "../../enums";
import { Doctor } from "../../API/interfaces";
import { I18Context } from "../I18Context";
import { DoctorsSlider } from "../DoctorsSlider";
import { DoctorsAPI } from "../../API/DoctorsAPI";
import {Show} from "../Show";
import {DoctorId} from "../../interfaces";

/* eslint-disable @typescript-eslint/object-curly-spacing */

type Props = {
  speciality: string,
  currentId?: DoctorId | null,
} & WithTranslation;

type State = {
  status: PageStatus,
  doctors: Doctor[],
  error: string | null,
};

class DoctorsSliderByCities extends Component<Props, State> {
  context!: ContextType<typeof I18Context>;
  static contextType = I18Context;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      doctors: [],
      error: null,
    };

    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    this.fetchInitial();

    this.props.i18n.on('languageChanged', this.fetch);
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.fetch);
  }

  fetchInitial(): void {
    if (!this.props.speciality) return;

    Promise.resolve()
      .then(() => {
        this.setState({ status: PageStatus.Loading });
      })
      .then(() => {
        return DoctorsAPI.doctors(
            {
              page: 1,
              limit: 6,
              speciality: this.props.speciality,
            },
        );
      })
      .then((doctorsRes) => {
        const doctors = doctorsRes.filter((item) => {
          return item.id !== this.props.currentId;
        })
        this.setState({ doctors, status: PageStatus.Loaded });
      })
      .catch((err) => {
        this.setState({ status: PageStatus.Error, error: err.message });
      });
  }

  fetch(): void {
    if (!this.context.isInitialized) return;
    if (!this.props.speciality) return;

    Promise.resolve()
      .then(() => {
        this.setState({ status: PageStatus.Loading });
      })
      .then(() => {
        return DoctorsAPI.doctors(
            {
              page: 1,
              limit: 6,
              speciality: this.props.speciality,
            },
        );
      })
      .then((doctorsRes) => {
        const doctors = doctorsRes.filter((item) => {
          return item.id !== this.props.currentId;
        })
        this.setState({ doctors, status: PageStatus.Loaded });
      })
      .catch((err) => {
        this.setState({ status: PageStatus.Error, error: err.message });
      });
  }

  render() {
    return (
      <>
        <Show when={!!this.state.doctors.length}>
          <h3>
            {this.props.t('Similar Doctors')}
          </h3>

          <DoctorsSlider doctors={this.state.doctors} />
        </Show>
      </>
    );
  }
}


const WithTrans = withTranslation()(DoctorsSliderByCities);

export { WithTrans as DoctorsSliderByCities };
