import { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Hospital } from '../../API/interfaces';
import {LazyImageSuspense} from "../../shared";

type Props = {
  hospital: Hospital,
} & WithTranslation;

type State = Record<string, never>;

class HospitalCard extends PureComponent<Props, State> {
  render() {
    return (
      <div className="blog-card hospital-card">
        <div className="post-media">
          <Link to={`/${this.props.i18n.language}/hospitals/${this.props.hospital.id}`}>
            <LazyImageSuspense
              src={this.props.hospital.image1 || ''}
              alt={this.props.hospital.name}
              className=""
            />
          </Link>
        </div>

        <div className="post-info">
          <h6 className="post-title line-clamp-2">
            <Link to={`/${this.props.i18n.language}/hospitals/${this.props.hospital.id}`}>
              {this.props.hospital.name}
            </Link>
          </h6>

          <hr />

          <div className="text-primary post-meta-heading">
            {this.props.t('Featured Specialities')}
          </div>

          <ul className="post-meta">
            {
              this.props.hospital.featured_treatments.map((treatment) => {
                return (
                  <li className="list-unstyled text-primary" key={treatment.id}>
                    <Link
                      to={`/${this.props.i18n.language}/specialities/${treatment.id}`}
                      className="text-primary"
                    >
                      &bull; {treatment.name}
                    </Link>
                  </li>
                );
              })
            }
          </ul>

          <Link
            to={`/${this.props.i18n.language}/hospitals/${this.props.hospital.id}`}
            className="btn btn-outline-primary btn-sm"
          >
            {this.props.t('View Details')}
            <i className="btn-icon-bx fas fa-chevron-right" />
          </Link>
        </div>
      </div>
    );
  }
}

const WithTrans = withTranslation()(HospitalCard);

export { WithTrans as HospitalCard };
