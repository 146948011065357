import { PureComponent } from 'react';

import { Doctor } from '../API/interfaces';

import { DoctorCard } from 'modules/Doctors';

type Props = {
  doctors: Doctor[],
  responsive?: any[]
};

type State = Record<string, never>;

class DoctorsSlider extends PureComponent<Props, State> {
  render() {
    const settings = {
      dots: false,
      arrows: true,
      speed: 1000,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 591,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <>
        {/*<Slider*/}
        {/*  dots={false}*/}
        {/*  arrows*/}
        {/*  speed={500}*/}
        {/*  centerMode={false}*/}
        {/*  infinite*/}
        {/*  slidesToScroll={3}*/}
        {/*  slidesToShow={4}*/}
        {/*  responsive={this.props.responsive || [*/}
        {/*    {*/}
        {/*      breakpoint: 2500,*/}
        {/*      settings: this.props.doctors.length > 5 ? {*/}
        {/*        slidesToShow: 4,*/}
        {/*        centerPadding: '30px',*/}
        {/*        slidesToScroll: 4,*/}
        {/*      } : 'unslick',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      breakpoint: 1919,*/}
        {/*      settings: this.props.doctors.length > 4 ? {*/}
        {/*        slidesToShow: 4,*/}
        {/*        centerPadding: '30px',*/}
        {/*        slidesToScroll: 4,*/}
        {/*      } : 'unslick',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      breakpoint: 1450,*/}
        {/*      settings: this.props.doctors.length > 3 ? {*/}
        {/*        slidesToShow: 3,*/}
        {/*        centerPadding: '30px',*/}
        {/*        slidesToScroll: 3,*/}
        {/*      } : 'unslick',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      breakpoint: 1099,*/}
        {/*      settings: this.props.doctors.length >= 3 ? {*/}
        {/*        slidesToShow: 3,*/}
        {/*        centerPadding: '40px',*/}
        {/*        slidesToScroll: 3,*/}
        {/*      } : 'unslick',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      breakpoint: 1023,*/}
        {/*      settings: this.props.doctors.length >= 3 ? {*/}
        {/*        slidesToShow: 2,*/}
        {/*        centerPadding: '50px',*/}
        {/*        slidesToScroll: 2,*/}
        {/*        autoplay: true,*/}
        {/*        autoplaySpeed: 2000,*/}
        {/*      } : 'unslick',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      breakpoint: 767,*/}
        {/*      settings: this.props.doctors.length > 1 ? {*/}
        {/*        slidesToShow: 1,*/}
        {/*        centerPadding: '60px',*/}
        {/*        slidesToScroll: 1,*/}
        {/*        autoplay: true,*/}
        {/*        autoplaySpeed: 2000,*/}
        {/*      } : 'unslick',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      breakpoint: 450,*/}
        {/*      settings: this.props.doctors.length > 1 ? {*/}
        {/*        slidesToShow: 1,*/}
        {/*        centerPadding: '20px',*/}
        {/*        slidesToScroll: 1,*/}
        {/*        autoplay: true,*/}
        {/*        autoplaySpeed: 2000,*/}
        {/*      } : 'unslick',*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*  className="service-slide d-flex"*/}
        {/*>*/}
        {/*  {*/}
        {/*    this.props.doctors.map((doctor) => {*/}
        {/*      return (*/}
        {/*        <div className="slider-item" key={doctor.id}>*/}
        {/*          <DoctorCard doctor={doctor} />*/}
        {/*        </div>*/}
        {/*      );*/}
        {/*    })*/}
        {/*  }*/}
        {/*</Slider>*/}

        <div className="">
          <div className="row">
            {this.props.doctors.map((doctor) => (
              <div
                className="col-12 col-md-6 col-lg-3 mb-2"
                key={doctor.id}
              >
                <DoctorCard doctor={doctor}/>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export { DoctorsSlider };
