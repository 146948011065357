import {ReactNode, Suspense} from "react";

type Props = {
  children: ReactNode,
}

export function Loading() {
  return (
      <>
        Loading...
      </>
  )
}

export function SuspenseWrapper({ children }: Props) {
  return (
    <Suspense fallback={<Loading />}>
      {children}
    </Suspense>
  );
}
