import { Component, ReactElement, ContextType } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
  I18Context,
  TI18Context,
} from './I18Context';

type Props = {
  children: ReactElement,
} & WithTranslation;

class I18Provider extends Component<Props, TI18Context> {
  context!: ContextType<typeof I18Context>;
  static contextType = I18Context;

  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false,
    };

    this.onInitialized = this.onInitialized.bind(this);
  }

  componentDidMount() {
    this.props.i18n.on('initialized', this.onInitialized);
  }

  componentWillUnmount() {
    this.props.i18n.off('initialized', this.onInitialized);
  }

  onInitialized(): void {
    this.setState({
      isInitialized: true,
    });
  }

  render() {
    return (
      <I18Context.Provider value={this.state}>
        {this.props.children}
      </I18Context.Provider>
    );
  }
}

const WithTrans = withTranslation()(I18Provider);

export { WithTrans as I18Provider };
