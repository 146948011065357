import ReactGA from "react-ga";
import {Speciality, Treatment} from "../API/interfaces";
import {
  doctors_ar,
  doctors_en,
  doctors_uz,
  hospitals_ar,
  hospitals_en,
  hospitals_uz,
  specialities_ar,
  specialities_en,
  specialities_uz,
  treatments_ar,
  treatments_en,
  treatments_uz
} from "./constants";

export function getParam<K = string>(
  obj: { props: any },
  name: string,
  strict = true,
  defaultValue: any = null,
): K {
  let val;
  console.log('obj', obj)
  if (obj.props && obj.props.match && obj.props.match.params && obj.props.match.params[name]) {
    val = obj.props.match.params[name];
  } else {
    val = defaultValue;
  }
  if (!val && strict) throw new Error('INVALID_PARAM');
  return val;
}

export function getQueryParams<T = string>(
  searchQuery: string,
  param: string,
): T | null {
  const params = new URLSearchParams(searchQuery);
  return params.get(param) as T | null;
}

const emailRegex = () => new RegExp(
  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)' +
  '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|' +
  '(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))',
);

const phoneRegex = () => new RegExp(/^\d*\.?\d*$/);

export const emailValidate = (value) => (
  value && emailRegex().test(value)
);

export const phoneValidate = (value) => (
  phoneRegex().test(value)
);

export function extractStringFromHTML(str: string): string {
  const span = document.createElement('span');
  span.innerHTML = str;
  const strTemp = span.textContent || span.innerText;
  span.remove();
  return strTemp;
}

export function getPath(location, req): string {
  if (!!location) {
    return location.pathname + location.search;
  }

  return req.originalUrl;
}

export function initializeGA(location, req): void {
  const path = getPath(location, req);
  console.log('path', path)
  ReactGA.pageview(path);
}

export function initializeGAUsingWindow(): void {
  ReactGA.initialize('UA-135979522-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

export function replaceLanguageFromPath(path: string, lan: string): string {
  return path.substring(0, 1) + lan + path.substring(3);
}

export function getDirection(lang: string): string {
  if (lang === 'ar') {
    return 'rtl';
  }
  return "ltr";
}

export function getDate(date: string): string {
  const dateObj = new Date(date);
  return dateObj.toLocaleString().split(',')[0];
}

export function getFeaturedHospitals(lang: string): {
  id: number,
  name: string,
  city: string,
  image: string,
}[] {
  switch (lang) {
    case 'ar': {
      return hospitals_ar;
    }
    case 'uz': {
      return hospitals_uz;
    }
    default: {
      return hospitals_en;
    }
  }
}

export function getFeaturedDoctors(lang: string): {
  id: number,
  name: string,
  image: string,
  hospitalName: string,
  speciality: string,
}[] {
  switch (lang) {
    case 'ar': {
      return doctors_ar;
    }
    case 'uz': {
      return doctors_uz;
    }
    default: {
      return doctors_en;
    }
  }
}

export function getFeaturedSpecialities(lang: string): Speciality[] {
  switch (lang) {
    case 'ar': {
      return specialities_ar as unknown as Speciality[];
    }
    case 'uz': {
      return specialities_uz as unknown as Speciality[];
    }
    default: {
      return specialities_en as unknown as Speciality[];
    }
  }
}

export function getFeaturedTreatments(lang: string): Treatment[] {
  switch (lang) {
    case 'ar': {
      return treatments_ar as unknown as Treatment[];
    }
    case 'uz': {
      return treatments_uz as unknown as Treatment[];
    }
    default: {
      return treatments_en as unknown as Treatment[];
    }
  }
}

export function loadCSS(href: string, integrity?: string): void {
  let cssNode = window.document.createElement('link');
  cssNode.setAttribute("rel", "stylesheet");
  cssNode.setAttribute("type", "text/css");
  cssNode.setAttribute("href", href);
  if (integrity) {
    cssNode.setAttribute("integrity", integrity);
  }
  console.log("panchnama", document.getElementsByTagName("head"))
  document.getElementsByTagName("head")[0].appendChild(cssNode);
}
