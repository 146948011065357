import get from 'lodash/get';

import { api } from '../axiosConfig';
import { specialitiesURL } from '../utilities/urls';
import { injectPageNumber, Paginated } from '../utilities/tools';
import { SpecialityId } from '../interfaces';

import { Speciality } from './interfaces';

export class SpecialitiesAPI {
  static specialities(
    page: number,
    limit: number,
  ): Promise<Paginated<Speciality>[] | never> {
    return api.get(`${specialitiesURL}/`, {
      params: {
        page,
        limit,
      },
    })
      .then((response) => {
        const data = get(response, ['data', 'results'], null);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return data as Speciality[];
      })
      .then(injectPageNumber(page, limit));
  }

  static speciality(specialityId: SpecialityId): Promise<Speciality | never> {
    return api.get(`${specialitiesURL}/${specialityId}/`)
      .then((response) => {
        const data = get(response, ['data'], null);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return data;
      });
  }
}