import {Component, ContextType, Suspense} from 'react';
import {Alert, Button} from 'react-bootstrap';
import {withTranslation, WithTranslation} from 'react-i18next';

import './PaginationWithTranslation.scss';

import {PageStatus} from '../../enums';
import {Show} from '../Show';

import {DEFAULT_LIMIT, PaginationContext} from './PaginationContext';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

type Item = Record<string, any>;

type Props = {
  i18Initialized: boolean,
  items: Item[],
  count: number,

  onLoad: (page: number, limit: number) => Promise<{ data: Item[], count: number }>,
  onSet: (items: Item[]) => void,
} & WithTranslation;

type State = {
  status: PageStatus,
  error: string | null,
  previousY: number,
  count: number,

  firstPage: number | null,
  firstEllipse: boolean,
  middlePages: number[],
  lastPage: number | null,
  lastEllipse: boolean,
};

class PaginationWithTranslation extends Component<Props, State> {
  static contextType = PaginationContext;
  context!: ContextType<typeof PaginationContext>;

  constructor(props) {
    super(props);

    this.state = {
      status: PageStatus.None,
      error: null,
      previousY: 0,
      count: this.props.count,

      firstPage: null,
      middlePages: [],
      lastPage: null,
      firstEllipse: false,
      lastEllipse: false,
    };

    this.fetch = this.fetch.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    this.context.emitter.on('PAGINATION', this.fetch);

    this.props.i18n.on('languageChanged', this.reset);

    this.context.setPage(1, true);

    const pages = this.calculatePages(this.state.count);
    this.setState({
      firstPage: pages.firstPage,
      lastPage: pages.lastPage,
      middlePages: pages.middlePages,
      firstEllipse: pages.firstEllipse,
      lastEllipse: pages.lastEllipse,
    });
  }

  componentWillUnmount() {
    this.context.reset(true);
    this.context.emitter.off('PAGINATION', this.fetch);
    this.props.i18n.off('languageChanged', this.reset);
  }

  async fetch(): Promise<void> {
    try {
      this.setState({status: PageStatus.Loading});
      const item = await this.props.onLoad(this.context.page, this.context.limit);
      this.props.onSet(item.data);
      window.scrollTo(0, 0)
      const pages = this.calculatePages(item.count);
      this.setState({
        count: item.count,
        firstPage: pages.firstPage,
        lastPage: pages.lastPage,
        middlePages: pages.middlePages,
        firstEllipse: pages.firstEllipse,
        lastEllipse: pages.lastEllipse,
        status: PageStatus.Loaded,
      });
    } catch (err: any) {
      this.setState({status: PageStatus.Error, error: err.message});
    }
  }

  calculatePages(count: number): {
    firstPage: number | null,
    firstEllipse: boolean,
    middlePages: number[],
    lastPage: number | null,
    lastEllipse: boolean,
  } {
    const totalPages = Math.ceil(count / DEFAULT_LIMIT);
    const page = this.context.page;
    const middlePages: number[] = [];
    if (page === 1) {
      middlePages.push(1);
    } else {
      middlePages.push(page - 1)
      middlePages.push(page)
    }

    const len = totalPages - page > 2 ? 2 : totalPages - page;

    for (let i = 0; i < len; i++) {
      middlePages.push(page + i + 1)
    }

    const firstEllipse = page - 2 > 1;
    const lastEllipse = page + 3 < totalPages;
    const firstPage = middlePages.includes(1) ? null : 1;
    const lastPage = middlePages.includes(totalPages) ? null : totalPages;

    return {
      firstPage,
      lastPage,
      middlePages,
      firstEllipse,
      lastEllipse,
    }
  }

  onPage(page: number): void {
    const totalPages = Math.ceil(this.state.count / DEFAULT_LIMIT);

    if (page < 1 || page > totalPages) {
      return;
    }

    this.context.setPage(page);
  }

  reset(): void {
    if (!this.props.i18Initialized) return;
    this.context.reset();
  }

  render() {
    return (
        <>
          <div className="pagination-pages">
            <Button
                className="btn-primary light previous-page"
                onClick={() => {
                  this.onPage(this.context.page - 1);
                }}
            >
              <Suspense>
                <FontAwesomeIcon icon={solid('angle-left')}/>
              </Suspense>
            </Button>
            <Show when={!!this.state.firstPage}>
              <Button
                  className={this.context.page === 1 ? 'btn-primary' : 'btn-primary light'}
                  onClick={() => {
                    this.onPage(1);
                  }}
              >
                {1}
              </Button>
            </Show>

            <Show when={this.state.firstEllipse}>
              <Button className="btn-primary light">
                ..
              </Button>
            </Show>

            {
              this.state.middlePages.map((page) => (
                  <Button
                      className={this.context.page === page ? 'btn-primary' : 'btn-primary light'}
                      onClick={() => {
                        this.onPage(page);
                      }}
                      key={page}
                  >
                    {page}
                  </Button>
              ))
            }

            <Show when={this.state.lastEllipse}>
              <Button className="btn-primary light">
                ..
              </Button>
            </Show>

            <Show when={!!this.state.lastPage}>
              <Button
                  className={this.context.page === this.state.lastPage ? 'btn-primary' : 'btn-primary light'}
                  onClick={() => {
                    this.onPage(this.state.lastPage || 0);
                  }}
              >
                {this.state.lastPage}
              </Button>
            </Show>

            <Button
                className="btn-primary light next-page"
                onClick={() => {
                  this.onPage(this.context.page + 1);
                }}
            >
              <Suspense>
                <FontAwesomeIcon icon={solid('angle-right')}/>
              </Suspense>
            </Button>
          </div>

          <Alert show={this.state.status === PageStatus.Error} variant="danger">
            {this.state.error}
          </Alert>
        </>
    );
  }
}

const WithTrans = withTranslation()(PaginationWithTranslation);

export {WithTrans as PaginationWithTranslation};
