import { Component, Suspense } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WithTranslation, withTranslation } from 'react-i18next';
import { brands, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import logo from 'images/logo7.png';

import './Header.scss';

type Props = RouteComponentProps & WithTranslation;

type State = {
  isMenuLinksVisible: boolean,
  currentActiveSubmenu: string | null,
};

class Header extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isMenuLinksVisible: false,
      currentActiveSubmenu: null,
    };
  }

  openMenu(): void {
    this.setState({ isMenuLinksVisible: true });
  }

  closeMenu(): void {
    this.setState({ isMenuLinksVisible: false });
  }

  menuClassNames(): string {
    return this.state.isMenuLinksVisible
      ? 'menu-links navbar-collapse collapse justify-content-end show'
      : 'menu-links navbar-collapse collapse justify-content-end';
  }

  subMenuClassNames(menuName: string): string {
    return this.state.currentActiveSubmenu === menuName
      ? 'open active list-unstyled' : 'list-unstyled';
  }

  onLinkClick(to: string): void {
    this.setState({ isMenuLinksVisible: false }, () => {
      this.props.history.push(`/${this.props.i18n.language}${to}`);
    });
  }

  render() {
    return (
      <>
        <header className="header header-transparent rs-nav">
          <Sticky enabled className="sticky-header navbar-expand-lg">
            <div className="menu-bar clearfix">
              <div className="container-fluid clearfix">
                <div
                  className="menu-logo logo-dark"
                  style={{
                    marginLeft: '-10px',
                  }}
                >
                  <Link to={`/${this.props.i18n.language}`}>
                    <div className="header-title">
                      <img src={logo} alt="Alfplus.com"/>
                    </div>
                  </Link>
                </div>

                <button
                  className={
                    'navbar-toggler collapsed menuicon justify-content-end'
                  }
                  type="button"
                  onClick={() => {
                    this.openMenu();
                  }}
                  data-bs-toggle="collapse"
                  data-bs-target="#menuDropdown"
                  aria-controls="menuDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span/>
                  <span/>
                  <span/>
                </button>

                <div className="secondary-menu">
                  <ul>
                    <li className="num-bx list-unstyled">
                      <a href="tel:+919990085860" className="mb-1">
                        <FontAwesomeIcon
                          icon={solid('phone')}
                          className="me-1"
                        />
                          +91-9990085860
                      </a>
                      <a href="mailto:care@alfplus.com">
                        <FontAwesomeIcon
                          icon={solid('envelope')}
                          className="me-1"
                        />
                          care@alfplus.com
                      </a>
                    </li>
                    <li className="btn-area list-unstyled">
                      <Link
                        to={`/${this.props.i18n.language}/quotes/create`}
                        className="btn btn-primary shadow"
                      >
                        <Suspense>
                          <FontAwesomeIcon icon={solid('envelope')}/>
                        </Suspense>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div
                  className={this.menuClassNames()}
                  id="menuDropdown"
                >
                  <div className="menu-logo">
                    <button
                      className="btn bg-transparent text-white border-0 fw-bolder h4"
                      onClick={() => {
                        this.onLinkClick('/');
                      }}
                    >
                        Alfplus.com
                    </button>
                  </div>

                  <ul className="nav navbar-nav">
                    <li
                      onClick={() => {
                        this.setState({ currentActiveSubmenu: 'browse' });
                      }}
                      className={this.subMenuClassNames('browse')}
                    >
                      <div>
                        {this.props.t('Browse')}
                        <FontAwesomeIcon icon={solid('plus')} className="plus-menu-icon"/>
                      </div>

                      <ul className="sub-menu">
                        <li className="add-menu-left list-unstyled">
                          <ul>
                            <li className="list-unstyled">
                              <button
                                className="btn bg-transparent text-warning border-0 w-100 text-start"
                                onClick={() => {
                                  this.onLinkClick('/hospitals');
                                }}
                              >
                                <span>
                                  {this.props.t('Hospitals')}
                                </span>
                              </button>
                            </li>
                            <li className="list-unstyled">
                              <button
                                className="btn bg-transparent text-warning border-0 w-100 text-start"
                                onClick={() => {
                                  this.onLinkClick('/doctors');
                                }}
                              >
                                <span>
                                  {this.props.t('Doctors')}
                                </span>
                              </button>
                            </li>
                            <li className="list-unstyled">
                              <button
                                className="btn bg-transparent text-warning border-0 w-100 text-start"
                                onClick={() => {
                                  this.onLinkClick('/cities-in-india');
                                }}
                              >
                                <span>
                                  {this.props.t('Cities in India')}
                                </span>
                              </button>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li
                      onClick={() => {
                        this.setState({ currentActiveSubmenu: 'services' });
                      }}
                      className={this.subMenuClassNames('services')}
                    >
                      <div>
                        {this.props.t('Services')}
                        <FontAwesomeIcon icon={solid('plus')} className="plus-menu-icon"/>
                      </div>

                      <ul className="sub-menu">
                        <li className="add-menu-left list-unstyled">
                          <ul>
                            <li className="list-unstyled">
                              <button
                                className="btn bg-transparent text-warning border-0 w-100 text-start"
                                onClick={() => {
                                  this.onLinkClick('/our-services');
                                }}
                              >
                                <span>
                                  {this.props.t('Our Services')}
                                </span>
                              </button>
                            </li>
                            <li className="list-unstyled">
                              <button
                                className="btn bg-transparent text-warning border-0 w-100 text-start"
                                onClick={() => {
                                  this.onLinkClick('/quotes/create');
                                }}
                              >
                                <span>
                                  {this.props.t('Free Consultation')}
                                </span>
                              </button>
                            </li>
                            <li className="list-unstyled">
                              <button
                                className="btn bg-transparent text-warning border-0 w-100 text-start"
                                onClick={() => {
                                  this.onLinkClick('/costs');
                                }}
                              >
                                <span>
                                  {this.props.t('Get Costs')}
                                </span>
                              </button>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li
                      onClick={() => {
                        this.setState({ currentActiveSubmenu: 'blog' });
                      }}
                      className={this.subMenuClassNames('blog')}
                    >
                      <div>
                        {this.props.t('Know Us')}
                        <FontAwesomeIcon icon={solid('plus')} className="plus-menu-icon"/>
                      </div>

                      <ul className="sub-menu left">
                        <li className="list-unstyled">
                          <button
                            className="btn bg-transparent text-warning border-0 w-100 text-start"
                            onClick={() => {
                              this.onLinkClick('/about-us');
                            }}
                          >
                            <span>
                              {this.props.t('About Us')}
                            </span>
                          </button>
                        </li>
                        <li className="list-unstyled">
                          <button
                            className="btn bg-transparent text-warning border-0 w-100 text-start"
                            onClick={() => {
                              this.onLinkClick('/team');
                            }}
                          >
                            <span>
                              {this.props.t('Team')}
                            </span>
                          </button>
                        </li>
                        <li className="list-unstyled">
                          <button
                            className="btn bg-transparent text-warning border-0 w-100 text-start"
                            onClick={() => {
                              this.onLinkClick('/mission');
                            }}
                          >
                            <span>
                              {this.props.t('Mission')}
                            </span>
                          </button>
                        </li>
                        <li className="list-unstyled">
                          <button
                            className="btn bg-transparent text-warning border-0 w-100 text-start"
                            onClick={() => {
                              this.onLinkClick('/contact-us');
                            }}
                          >
                            <span>
                              {this.props.t('Contact')}
                            </span>
                          </button>
                        </li>
                      </ul>
                    </li>

                    <li
                      onClick={() => {
                        this.setState({ currentActiveSubmenu: 'information' });
                      }}
                      className={this.subMenuClassNames('information')}
                    >
                      <div>
                        {this.props.t('header.menu.information_link')}
                        <FontAwesomeIcon icon={solid('plus')} className="plus-menu-icon"/>
                      </div>

                      <ul className="sub-menu left">
                        <li className="list-unstyled">
                          <button
                            className="btn bg-transparent text-warning border-0 w-100 text-start"
                            onClick={() => {
                              this.onLinkClick('/blogs');
                            }}
                          >
                            <span>
                              {this.props.t('header.menu.blogs_link')}
                            </span>
                          </button>
                        </li>
                        <li className="list-unstyled">
                          <button
                            className="btn bg-transparent text-warning border-0 w-100 text-start"
                            onClick={() => {
                              this.onLinkClick('/visa');
                            }}
                          >
                            <span>
                              {this.props.t('header.menu.visa_link')}
                            </span>
                          </button>
                        </li>
                      </ul>
                    </li>

                    <li className="d-lg-none list-unstyled">
                      <div>
                        <button
                          className="btn bg-transparent text-white p-0"
                          onClick={() => {
                            this.onLinkClick('/quotes/create');
                          }}
                        >
                          <Suspense>
                            <FontAwesomeIcon icon={solid('envelope')}/>
                          </Suspense>
                          <span className="ms-2">
                            {this.props.t('Free Consultation')}
                          </span>
                        </button>
                      </div>
                    </li>
                  </ul>

                  <ul className="social-media">
                    <li className="list-unstyled">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/"
                        className="btn btn-primary"
                      >
                        <Suspense>
                          <FontAwesomeIcon icon={brands('facebook-f')}/>
                        </Suspense>
                      </a>
                    </li>
                    <li className="list-unstyled">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/"
                        className="btn btn-primary"
                      >
                        <Suspense>
                          <FontAwesomeIcon icon={brands('linkedin-in')}/>
                        </Suspense>
                      </a>
                    </li>
                    <li className="list-unstyled">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://twitter.com/"
                        className="btn btn-primary"
                      >
                        <Suspense>
                          <FontAwesomeIcon icon={brands('twitter')}/>
                        </Suspense>
                      </a>
                    </li>
                  </ul>

                  <div className="menu-close">
                    <button
                      onClick={() => {
                        this.closeMenu();
                      }}
                      className="btn bg-transparent text-white border-0 p-0"
                      id="menuClose"
                      aria-label="menuClose"
                    >
                      <Suspense>
                        <FontAwesomeIcon icon={solid('times')}/>
                      </Suspense>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Sticky>
        </header>
      </>
    );
  }
}

const WithRouter = withRouter(Header);
const WithTrans = withTranslation()(WithRouter);

export {
  WithTrans as Header,
};

export default WithTrans;
