import {ReactNode} from "react";

export function Show(props: {
  when: boolean,
  children: ReactNode,
}) {
  if (!props.when) {
    return null;
  }
  return (
    <>
      {props.children}
    </>
  );
}