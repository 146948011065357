import { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { PageHeader } from '../../shared';

import { QuoteWithEntity } from "./QuoteWithEntity";

type Props = WithTranslation;
type State = Record<string, never>;

class Create extends PureComponent<Props, State> {
  render() {
    return (
      <>
        <Helmet>
          <html lang={this.props.i18n.language} />
          <title>
            {this.props.t('quoteTitle')}
          </title>
          <meta
              name="description"
              content={this.props.t('quoteDescription')}
          />
        </Helmet>

        <div className="page-content bg-white">
          <PageHeader title={this.props.t('Enquiry')} />

          <section className="section-area section-sp1">
            <QuoteWithEntity />
          </section>
        </div>
      </>
    );
  }
}

const WithTrans = withTranslation()(Create);

export { WithTrans as Create };

export default WithTrans;