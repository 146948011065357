import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Spinner } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import './CostsSearch.scss';

import { Show } from '../Show';
import { Treatment } from '../../API/interfaces';
import { PageStatus } from '../../enums';
import { TreatmentsAPI } from '../../API/TreatmentsAPI';

type State = {
  status: PageStatus,
  text: string | null,
  treatments: Treatment[],
  error: string | null,
};

class CostsSearch extends Component<WithTranslation, State> {
  debounce: NodeJS.Timer | null = null;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      text: null,
      treatments: [],
      error: null,
    };
  }

  onChange(text: string): void {
    this.setState({ text }, () => {
      if ((this.state.text?.length || 0) > 3) {
        if (!!this.debounce) {
          clearTimeout(this.debounce)
        }
        this.debounce = setTimeout(() => {
          this.onSearch().then();
        }, 400)
      }
    });
  }

  async onSearch(): Promise<void> {
    try {
      this.setState({ status: PageStatus.Loading });
      const treatments = await TreatmentsAPI.treatments({
        page: 1,
        limit: 1000,
        name: this.state.text || undefined,
      });
      this.setState({ treatments, status: PageStatus.Loaded });
    } catch (err: any) {
      this.setState({ error: err.message, status: PageStatus.Error });
    }
  }

  isButtonDisabled(): boolean {
    return !this.state.text
        || this.state.text.length <= 3
        || this.state.status === PageStatus.Loading;
  }

  areItemsEmpty(): boolean {
    return this.state.treatments.length === 0
        && this.state.status === PageStatus.None;
  }

  areResultsEmpty(): boolean {
    return this.state.treatments.length === 0
        && this.state.status === PageStatus.Loaded;
  }

  render() {
    return (
      <>
        <Helmet>
          <html lang={this.props.i18n.language} />
          <title>
            {this.props.t('costsSearchTitle')}
          </title>
          <meta
              name="description"
              content={this.props.t('costsSearchDescription')}
          />
        </Helmet>

        <div className="costs-search-bar">
          <div className="form-container">
            <h1>
              {this.props.t('Search in 1000+ treatments')}
            </h1>

            <div className="form-wrapper">
              <form>
                <input
                  type="text"
                  value={this.state.text || ''}
                  onChange={(e) => {
                    this.onChange(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                  className="form-control"
                  placeholder={this.props.t('Search treatments')}
                />
              </form>
            </div>
            <div className="text-warning text-13">
              {this.props.t('Type 4 or more letter to search')}
            </div>
          </div>

          <div className="search-container container mt-2">
            <div className="w-100 d-flex justify-content-center mt-3">
              <h3>{this.props.t('Search Results')}</h3>
            </div>

            <Show when={this.state.status === PageStatus.Loading}>
              <div className="w-100 d-flex justify-content-center">
                <Spinner animation="border" />
              </div>
            </Show>

            <Show when={this.areItemsEmpty()}>
              <div className="w-100 d-flex justify-content-center">
                {this.props.t('Results will be shown here')}
              </div>
            </Show>

            <Show when={this.areResultsEmpty()}>
              <div className="w-100 d-flex justify-content-center">
                {this.props.t('No treatments found')}
              </div>
            </Show>

            <Alert
              show={this.state.status === PageStatus.Error}
              variant="danger"
            >
              {this.state.error}
            </Alert>

            <Show when={this.state.treatments?.length > 0}>
              <h5>{this.props.t('Treatments')}</h5>

              <div className="d-flex flex-wrap align-items-start">
                {
                  this.state.treatments.map((treatment) => (
                    <span className="result-card" key={treatment.id}>
                      <Link to={`/${this.props.i18n.language}/cost-of-treatments/${treatment.id}`}>
                        {treatment.name}
                        <Show when={!!treatment.cost}>
                          {' '}
                          -
                          {' '}
                          ${treatment.cost}
                        </Show>
                      </Link>
                    </span>
                  ))
                }
              </div>
            </Show>
          </div>
        </div>
      </>
    );
  }
}

const WithTrans = withTranslation()(CostsSearch);

export { WithTrans as CostsSearch };
