/* eslint-disable max-len */

export const baseStaticURL = process.env.RAZZLE_STATIC_BASE_URL;
export const baseAPIURL = process.env.RAZZLE_BASE_URL_API;

export const hospitalsURL = '/api/v1/hospitals';
export const doctorsURL = '/api/v1/doctors';
export const citiesURL = '/api/v1/cities';
export const blogsURL = '/api/v1/blogs';
export const visasURL = '/api/v1/visas';
export const specialitiesURL = '/api/v1/specialities';
export const treatmentsURL = '/api/v1/treatments';
export const testimonialsURL = '/api/v1/testimonials';
export const quotesURL = '/api/v1/quotes';
export const featuredURL = '/api/v1/featured';
