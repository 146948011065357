import get from 'lodash/get';

import { api } from '../axiosConfig';
import { quotesURL } from '../utilities/urls';
import {DoctorId, HospitalId, TreatmentId} from "../interfaces";

export class QuotesAPI {
  static create(
    values: {
      patient_name: string,
      user_name: string,
      phone_code: string,
      phone: string,
      email: string,
      country: string,
      message: string,
    },
  ): Promise<any> {
    return api.post(`${quotesURL}/create`, values)
      .then((response) => {
        const data = get(response, ['data', 'data'], null);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return data;
      });
  }

  static createWithEntity(
    values: {
      patient_name: string,
      user_name: string,
      phone_code: string,
      phone: string,
      email: string,
      country: string,
      message: string,
      hospital: HospitalId | null,
      doctor: DoctorId | null,
      treatment: TreatmentId | null,
    },
  ): Promise<any> {
    return api.post(`${quotesURL}/create-with-entity`, values)
      .then((response) => {
        const data = get(response, ['data', 'data'], null);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return data;
      });
  }
}