import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component, createRef, Suspense } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Alert, Spinner } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import './SearchBar.scss';

import { Show } from '../Show';
import { Doctor, Hospital, Treatment } from '../../API/interfaces';
import { PageStatus } from '../../enums';
import { HospitalsAPI } from '../../API/HospitalsAPI';
import { TreatmentsAPI } from '../../API/TreatmentsAPI';
import { DoctorsAPI } from '../../API/DoctorsAPI';

type Props = RouteComponentProps & WithTranslation;

type State = {
  status: PageStatus,
  showResults: boolean,
  text: string | null,
  hospitals: Hospital[],
  doctors: Doctor[],
  treatments: Treatment[],
  error: string | null,
};

class SearchBar extends Component<Props, State> {
  wrapperRef = createRef<HTMLDivElement>();
  debounce: NodeJS.Timer | null = null;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      showResults: false,
      text: null,
      hospitals: [],
      doctors: [],
      treatments: [],
      error: null,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
      this.closeSearchForm();
    }
  }

  onChange(text: string): void {
    this.setState({ text }, () => {
      if ((this.state.text?.length || 0) > 3) {
        if (!!this.debounce) {
          clearTimeout(this.debounce);
        }
        this.debounce = setTimeout(() => {
          this.onSearch().then();
        }, 400);
      }
    });
  }

  async onSearch(): Promise<void> {
    try {
      if ((this.state.text?.length || 0) <= 3) return;

      this.setState({ status: PageStatus.Loading });
      const hospitals = await HospitalsAPI.hospitals({
        page: 1,
        limit: 1000,
        name: this.state.text || undefined,
      });
      const treatments = await TreatmentsAPI.treatments({
        page: 1,
        limit: 1000,
        name: this.state.text || undefined,
      });
      const doctors = await DoctorsAPI.doctors({
        page: 1,
        limit: 1000,
        name: this.state.text || undefined,
      });
      this.setState({
        hospitals,
        treatments,
        doctors,
        status: PageStatus.Loaded,
      });
    } catch (err: any) {
      this.setState({ error: err.message, status: PageStatus.Error });
    }
  }

  closeSearchForm(callback?: () => void): void {
    this.setState({
      showResults: false,
      text: null,
      treatments: [],
      hospitals: [],
      doctors: [],
      error: null,
      status: PageStatus.None,
    }, () => {
      document.body.style.overflowY = 'auto';
      if (callback) {
        callback();
      }
    });
  }

  isButtonDisabled(): boolean {
    return !this.state.text
        || this.state.text.length <= 3
        || this.state.status === PageStatus.Loading;
  }

  areItemsEmpty(): boolean {
    return this.state.treatments.length === 0
        && this.state.doctors.length === 0
        && this.state.hospitals.length === 0
        && this.state.status === PageStatus.None;
  }

  render() {
    return (
      <>
        <div className="search-bar mb-2" ref={this.wrapperRef}>
          <div className="form-container">
            <form className="position-relative">
              <input
                type="text"
                value={this.state.text || ''}
                onChange={(event) => {
                  this.onChange(event.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
                onClick={() => {
                  this.setState({ showResults: true });
                }}
                className="form-control"
                placeholder={this.props.t('Search Treatments, Doctors, Hospitals')}
              />

              <button
                className="btn btn-main-search m-0"
                disabled={this.isButtonDisabled()}
                onClick={() => {
                  this.onSearch().then();
                }}
                aria-label="Search"
              >
                <Suspense>
                  <FontAwesomeIcon icon={solid('search')}/>
                </Suspense>
              </button>
            </form>
          </div>

          <Show when={this.state.showResults}>
            <div className="search-container">
              <Show when={this.state.status === PageStatus.Loading}>
                <div className="w-100 d-flex justify-content-center p-2">
                  <Spinner animation="border"/>
                </div>
              </Show>

              <Show when={this.areItemsEmpty()}>
                <div className="w-100 d-flex justify-content-center p-2">
                  {this.props.t('Type three or more character to begin search.')}
                </div>
              </Show>

              <Alert
                show={this.state.status === PageStatus.Error}
                variant="danger"
              >
                {this.state.error}
              </Alert>

              <div className="w-100">
                <Show when={this.state.treatments?.length > 0}>
                  <div className="w-100">
                    <div className="w-100 bg-gray p-2">
                      <h6>
                        {this.props.t('Treatments')}
                      </h6>
                    </div>

                    {
                      this.state.treatments.map((treatment) => (
                        <button
                          className="btn bg-transparent w-100 text-start"
                          onClick={() => {
                            this.closeSearchForm(
                              () => {
                                this.props.history.push(
                                  `/${this.props.i18n.language}/treatments/${treatment.id}`,
                                );
                              },
                            );
                          }}
                          aria-label={treatment.name}
                        >
                          {treatment.name}
                        </button>
                      ))
                    }
                  </div>
                </Show>

                <Show when={this.state.doctors?.length > 0}>
                  <div className="w-100">
                    <div className="w-100 bg-gray p-2">
                      <h6>
                        {this.props.t('Doctors')}
                      </h6>
                    </div>

                    {
                      this.state.doctors.map((doctor) => (
                        <button
                          className="btn bg-transparent w-100 text-start"
                          onClick={() => {
                            this.closeSearchForm(
                              () => {
                                this.props.history.push(
                                  `/${this.props.i18n.language}/doctors/${doctor.id}`,
                                );
                              },
                            );
                          }}
                          aria-label={doctor.name}
                        >
                          {doctor.name}
                        </button>
                      ))
                    }
                  </div>
                </Show>

                <Show when={this.state.hospitals?.length > 0}>
                  <div className="w-100">
                    <div className="w-100 bg-gray p-2">
                      <h6>
                        {this.props.t('Hospitals')}
                      </h6>
                    </div>
                    {
                      this.state.hospitals.map((hospital) => (
                        <button
                          className="btn bg-transparent w-100 text-start"
                          onClick={() => {
                            this.closeSearchForm(
                              () => {
                                this.props.history.push(
                                  `/${this.props.i18n.language}/hospitals/${hospital.id}`,
                                );
                              },
                            );
                          }}
                          aria-label={`${hospital.name} - ${hospital.city.city}`}
                        >
                          {hospital.name} - {hospital.city.city},
                          {' '}
                          {this.props.t('India')}
                        </button>
                      ))
                    }
                  </div>
                </Show>
              </div>
            </div>
          </Show>
        </div>
      </>
    );
  }
}

const SearchBarWithRouter = withRouter(SearchBar);

const SearchBarWithTrans = withTranslation()(SearchBarWithRouter);

export { SearchBarWithTrans as SearchBar };

export default SearchBarWithTrans;
