import { Component } from 'react';

type Props = {
  title: string,
};

type State = {
  title: string | null,
};

class DocumentTitle extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      title: null,
    };
  }

  componentDidMount() {
    this.setState({ title: document.title });
    document.title = this.props.title;
  }

  componentWillUnmount() {
    document.title = this.state.title || '';
  }

  render() {
    return null;
  }
}

export { DocumentTitle };
