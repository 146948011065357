import axios from 'axios';

import { baseAPIURL } from './utilities/urls';
import { defaultTimeOutErr } from './lang';
import { CustomError } from './utilities/errors';
import i18n from './i18n';

// @todo: To use env for "timeout".
const api = axios.create({
  baseURL: baseAPIURL,
  timeout: 30_000,
  timeoutErrorMessage: defaultTimeOutErr,
});

// Language
api.interceptors.request.use((config) => {
  const configCopy = { ...config };
  if (!!configCopy.headers) {
    configCopy.headers['Accept-Language'] = i18n.language;
  }
  return configCopy;
});

// Do not remove debug information.
api.interceptors.response.use((res) => res, (error) => {
  console.debug('Error code', error.code);
  throw error;
});

// Error translation.
api.interceptors.response.use((res) => res, (error) => {
  let err = new CustomError('Something went wrong!!', null);
  console.error('data-error-m', error.request);

  if (error.response && error.response.status) {
    err = new CustomError(error.response.statusText, error.response.status);
  }
  throw err;
});

export { api };
