import { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { PageHeader } from '../../shared';

import { TermsOfUseSub } from './TermsOfUseSub';
import { DisclaimerSub } from './DisclaimerSub';
import { CopyrightsSub } from './CopyrightsSub';

type Props = WithTranslation;
type State = Record<never, never>;

class TermsOfUse extends PureComponent<Props, State> {
  render() {
    return (
      <>
        <Helmet>
          <html lang={this.props.i18n.language} />
          <title>
            Alfplus Terms Of Use | Alfplus
          </title>
          <meta
              name="description"
              content="The legal owner of this website is only Alfplus.com. Therefore, it has the sole authority to carry out any modification, addition, or deletion in any content of this website, which will be effective from the immediate date."
          />
        </Helmet>

        <div className="page-content bg-white terms-of-use-wrap">
          <PageHeader title={this.props.t('Terms Of Use')} />

          <section className="terms-of-use-wrap">
            <div className="container">
              <div>
                <TermsOfUseSub />

                <hr />

                <DisclaimerSub />

                <hr />

                <CopyrightsSub />
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const WithTrans = withTranslation()(TermsOfUse);

export { WithTrans as TermsOfUse };

export default WithTrans;
