
export const defaultTimeOutErr = 'Looks like the server is taking too long to '
  + 'respond, this can be caused by either poor connectivity or an error '
  + 'with our servers. Please try again in a while.';

export const lang = {
  unknownError: 'Something went wrong!!',
};

export function errorMap(errNoIn: string, defaultMessage: string): string {
  return lang[errNoIn] || defaultMessage;
}
