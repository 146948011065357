import { Suspense } from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { After, ensureReady } from '@jaredpalmer/after';
import { useSSR } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import routes from './routes';
import { persistor, store } from './reducers';
import { PaginationProvider } from './shared/Pagination';
import { BodyLayout } from './layout/BodyLayout';
import { I18Provider } from './shared/I18Context';
import { Loading } from './shared/SuspenseWrapper';

function Base(
  props: {
    data: any,
  },
) {
  useSSR((window as any).initialI18nStore, (window as any).initialLanguage);

  return (
    <Suspense fallback={<Loading/>}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <PaginationProvider>
            <I18Provider>
              <BrowserRouter>
                <BodyLayout>
                  <After
                    data={props.data}
                    routes={routes}
                    transitionBehavior="blocking"
                  />
                </BodyLayout>
              </BrowserRouter>
            </I18Provider>
          </PaginationProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}

ensureReady(routes).then(data => {
  return hydrate(
    <Base data={data}/>,
    document.getElementById('root'),
  );
},
);

if (module.hot) {
  module.hot.accept();
}
