export const DEFAULT_LIMIT = 10;
export const DEFAULT_PAGE = 1;

export type Paginated<T> = T & { num: number };

export function injectPageNumber(
  pageIn = DEFAULT_PAGE,
  limitIn = DEFAULT_LIMIT,
) {
  const limit = Number.isNaN(limitIn) || !limitIn ? DEFAULT_LIMIT : limitIn;
  const page = Number.isNaN(pageIn) || !pageIn ? DEFAULT_PAGE : pageIn;
  return function injectPageNumberFun<T>(items: T[]): Paginated<T>[] {
    return items.map((item, index) => {
      return { ...item, num: (page - 1) * limit + index + 1 };
    });
  };
}

export function injectPageNumberWithCount(
  pageIn = DEFAULT_PAGE,
  limitIn = DEFAULT_LIMIT,
) {
  const limit = Number.isNaN(limitIn) || !limitIn ? DEFAULT_LIMIT : limitIn;
  const page = Number.isNaN(pageIn) || !pageIn ? DEFAULT_PAGE : pageIn;
  return function injectPageNumberFun<T>(item: { data: T[], count: number }): {
    data: Paginated<T>[],
    count: number,
  } {
    const data = item.data.map((item, index) => {
      return { ...item, num: (page - 1) * limit + index + 1 };
    });
    return {
      data,
      count: item.count,
    }
  };
}

export type ImageObserverCallback = () => void;

export function intersectionObserverForImages(): ImageObserverCallback {
  let lazyImages = document.querySelectorAll("img.lazy");

  if ("IntersectionObserver" in window) {
    let lazyImageObserver = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          let lazyImage: any = entry.target;
          lazyImage.src = lazyImage.dataset.src;
          lazyImage.classList.remove("lazy");
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    lazyImages.forEach(function (lazyImage) {
      lazyImageObserver.observe(lazyImage);
    });

    return () => {
      lazyImages.forEach((images) => {
        lazyImageObserver.unobserve(images);
      })
    }
  }

  return () => null;
}
