import { PureComponent, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Doctor } from '../../API/interfaces';
import { LazyImageSuspense, Show } from '../../shared';
import doctorLogo from '../../images/doctor-logo.png';

import './DoctorCardWithDetails.scss';

type Props = {
  doctor: Doctor,
} & WithTranslation;
type State = Record<string, never>;

class DoctorCardWithDetails extends PureComponent<Props, State> {
  getWhatsAppMessage(): string {
    return this.props.doctor
      ? `Hello, I want to contact, regarding ${this.props.doctor.name}-${this.props.doctor.hospital_name.name} https://www.alfplus.com/${this.props.i18n.language}/doctors/${this.props.doctor.id}, Thanks`
      : 'Hello, I have a query';
  }

  render() {
    const position = this.props.doctor.position;
    const hospital = this.props.doctor.hospital_name.name || 'hospital';
    const experience = this.props.doctor.experience;

    return (
      <div className="doctor-card-with-details">
        <Link
          to={`/${this.props.i18n.language}/doctors/${this.props.doctor.id}`}
          className=""
        >
          <div className="doctor-card-with-details-media">
            <Show when={!this.props.doctor.image}>
              <LazyImageSuspense
                src={doctorLogo}
                alt={this.props.doctor.name}
                className=""
              />
            </Show>

            <Show when={!!this.props.doctor.image}>
              <LazyImageSuspense
                src={this.props.doctor.image || ''}
                alt={this.props.doctor.name}
                className=""
              />
            </Show>
          </div>
        </Link>

        <div className="doctor-card-with-details-info">
          <Link
            to={`/${this.props.i18n.language}/doctors/${this.props.doctor.id}`}
            className=""
          >
            <h5 className="doctor-card-with-details-title line-clamp-2">
              {this.props.doctor.name}
            </h5>
          </Link>

          <p className="doctor-card-with-details-description line-clamp-2">
            {this.props.t(
              '{{position}} at {{hospital}} with {{experience}}+ years of experience',
              {
                position,
                hospital,
                experience,
              })
            }
          </p>

          <p className="doctor-card-with-details-specialities line-clamp-2">
            <span className="fw-bolder">
              {this.props.t('Specialities')}:
              {' '}
            </span>
            {this.props.doctor.specialities}
          </p>

          <Link
            to={`/${this.props.i18n.language}/doctors/${this.props.doctor.id}`}
            className="btn btn-sm btn-primary light"
          >
            {this.props.t('View Details')}
          </Link>

          <a
            href={`whatsapp://send?phone=+919990085860&text=${this.getWhatsAppMessage()}`}
            className="btn btn-sm bg-transparent border-0 p-0 whatsapp-btn ms-3"
          >
            <Suspense>
              <FontAwesomeIcon
                icon={brands('whatsapp')}
                className="fa-3x fa-whatsapp-color"
              />
            </Suspense>
          </a>
        </div>
      </div>
    );
  }
}

const WithTrans = withTranslation()(DoctorCardWithDetails);

export { WithTrans as DoctorCardWithDetails };
