import {lazy, PureComponent} from 'react';
import {Carousel} from 'react-bootstrap';
import {WithTranslation, withTranslation} from 'react-i18next';

import {LazyImageSuspense} from "./LazyImageSuspense";

type Props = WithTranslation;
type State = Record<string, never>;

const SearchBar = lazy(() => import('./Search/SearchBar'));

class MainBanner extends PureComponent<Props, State> {
  render() {
    return (
        <>
          <div className="main-banner">
            <div className="container inner-content">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-6 col-sm-7">
                  <SearchBar/>

                  <h1 className="mt-3">
                    {this.props.t('For quality healthcare at best price with' +
                        ' free logistics and languages assistance across India')}
                  </h1>
                </div>
                <div className="col-lg-5 col-md-6 col-sm-5">
                  <Carousel className="hospitals-carousel" indicators={false}>
                    <Carousel.Item>
                      <LazyImageSuspense
                          className="d-block w-100 lazy"
                          src="https://res.cloudinary.com/alfplus-com/image/upload/v1581100339/Alfplus.com/Hospics/ResizedImage/qrg.jpg"
                          alt="QRG Hospital"
                      />
                      <Carousel.Caption>
                        <h5>
                          {this.props.t('QRG Hospital')}
                        </h5>
                        <p>
                          {this.props.t('Faridabad')}
                        </p>
                      </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                      <LazyImageSuspense
                          className="d-block w-100 lazy"
                          src="https://res.cloudinary.com/alfplus-com/image/upload/v1581100335/Alfplus.com/Hospics/ResizedImage/Nanavati_Super_Speciality_Hospital_Mumbai.jpg"
                          alt="Nanavati Super Speciality Hospital"
                      />

                      <Carousel.Caption>
                        <h5>
                          {this.props.t('Nanavati Super Speciality Hospital')}
                        </h5>
                        <p>
                          {this.props.t('Mumbai')}
                        </p>
                      </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                      <LazyImageSuspense
                          className="d-block w-100 lazy"
                          src="https://res.cloudinary.com/alfplus-com/image/upload/v1549703948/Alfplus.com/Hospics/Fortis_HospitalsFortis_Hospital_Bannerghatta_Bangalore.jpg"
                          alt="Fortis Hospital"
                      />

                      <Carousel.Caption>
                        <h5>
                          {this.props.t('Fortis Hospital')}
                        </h5>
                        <p>
                          {this.props.t('Bangalore')}
                        </p>
                      </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                      <LazyImageSuspense
                          className="d-block w-100 lazy"
                          src="https://res.cloudinary.com/alfplus-com/image/upload/v1551283766/Alfplus.com/Hospics/manipal_hospitals_dwarka_n.jpg"
                          alt="Manipal Hospital"
                      />

                      <Carousel.Caption>
                        <h5>
                          {this.props.t('Manipal Hospital')}
                        </h5>
                        <p>
                          {this.props.t('New Delhi')}
                        </p>
                      </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                      <LazyImageSuspense
                          className="d-block w-100 lazy"
                          src="https://res.cloudinary.com/alfplus-com/image/upload/v1615017285/Alfplus.com/Hospics/zyd.jpg"
                          alt="Zydus Hospital"
                      />

                      <Carousel.Caption>
                        <h5>
                          {this.props.t('Zydus Hospital')}
                        </h5>
                        <p>
                          {this.props.t('Ahmedabad')}
                        </p>
                      </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                      <LazyImageSuspense
                          className="d-block w-100 lazy"
                          src="https://res.cloudinary.com/alfplus-com/image/upload/v1549703949/Alfplus.com/Hospics/Max_Super_Speciality_Hospital_Saket_New_Delhi.jpg"
                          alt="Max Hospital"
                      />

                      <Carousel.Caption>
                        <h5>
                          {this.props.t('Max Hospital')}
                        </h5>
                        <p>
                          {this.props.t('Saket Delhi')}
                        </p>
                      </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                      <LazyImageSuspense
                          className="d-block w-100 lazy"
                          src="https://res.cloudinary.com/alfplus-com/image/upload/v1549703947/Alfplus.com/Hospics/Lilavati_Hospital_and_Research_Center_Chennai_Madras.jpg"
                          alt="Lilavati Hospital"
                      />

                      <Carousel.Caption>
                        <h5>
                          {this.props.t('Lilavati Hospital')}
                        </h5>
                        <p>
                          {this.props.t('Mumbai')}
                        </p>
                      </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                      <LazyImageSuspense
                          className="d-block w-100 lazy"
                          src="https://res.cloudinary.com/alfplus-com/image/upload/v1581100315/Alfplus.com/Hospics/ResizedImage/Continental_Hospitals_Hyderabad.jpg"
                          alt="Continental Hospital"
                      />

                      <Carousel.Caption>
                        <h5>
                          {this.props.t('Continental Hospital')}
                        </h5>
                        <p>
                          {this.props.t('Hyderabad')}
                        </p>
                      </Carousel.Caption>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>

        </>
    );
  }
}

const MainBannerWithTrans = withTranslation()(MainBanner);

export {MainBannerWithTrans as MainBanner};