import { PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { PageHeader } from '../../shared';

type Props = WithTranslation;
type State = Record<never, never>;

class ContactUs extends PureComponent<Props, State> {
  render() {
    return (
      <>
        <Helmet>
          <html lang={this.props.i18n.language} />
          <title>
            Contact Us | Alfplus
          </title>
          <meta
              name="description"
              content="C-35, 3rd Floor, Street no.-7, Noor Nagar, Okhla, New Delhi, India-110025"
          />
        </Helmet>

        <div className="page-content bg-white our-mission">
          <PageHeader title={this.props.t('Contact Us')} />

          <section className="our-mission-section">
            <div className="container">
              <div className="text-md-center">
                <h1 className="doubleborder">
                  {this.props.t('Contact Us')}
                </h1>

                <hr />

                <ul className="list-unstyled mb-0">
                  <li className="d-flex justify-content-center">
                    <i className="fas fa-map-marker-alt fa-2x me-2"></i>
                    <h5>
                      <strong>
                        C-35, 3rd Floor, Street no.-7, Noor Nagar,
                        <br />Okhla, New Delhi,
                        <br />India-110025
                      </strong>
                    </h5>
                  </li>

                  <li className="d-flex my-3 justify-content-md-center">
                    <i className="fas fa-phone fa-2x fa-flip-horizontal me-2" />
                    <h5>
                      <strong>
                        <a href="tel:+919990085860">+91-9990085860</a>
                      </strong>
                    </h5>
                  </li>

                  <li className="d-flex justify-content-md-center">
                    <i className="fas fa-envelope fa-2x me-2" />
                    <h5>
                      <strong>
                        <a href="mailto:care@alfplus.com">
                          care@alfplus.com
                        </a>
                      </strong>
                    </h5>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const WithTrans = withTranslation()(ContactUs);

export { WithTrans as ContactUs };

export default WithTrans;
