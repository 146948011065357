import { Component, ContextType } from 'react';

import { HospitalShort } from '../../API/interfaces';
import { HospitalsAPI } from '../../API/HospitalsAPI';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
} from '../../shared/Pagination/PaginationContext';
import { FiltersProvider } from '../../shared/Filters';
import { I18Context } from '../../shared/I18Context';
import { Quote } from '../Quotes';

import { Hospitals } from './Hospitals';

type Props = {
  hospitals: HospitalShort[],
  count: number,
};

type State = Record<string, never>;

class HospitalsWrapper extends Component<Props, State> {
  context!: ContextType<typeof I18Context>;
  static contextType = I18Context;

  static async getInitialProps(): Promise<any> {
    try {
      const data = await HospitalsAPI.hospitalsShortWithCount({
        page: DEFAULT_PAGE,
        limit: DEFAULT_LIMIT,
      });
      return {
        hospitals: data.data,
        count: data.count,
      };
    } catch (err: any) {
      console.error(err);
    }
  }

  render() {
    return (
      <>
        <FiltersProvider>
          <Hospitals
            i18Initialized={this.context.isInitialized}
            hospitals={this.props.hospitals || []}
            count={this.props.count || 0}
          />
        </FiltersProvider>

        <Quote />
      </>
    );
  }
}

export { HospitalsWrapper };

export default HospitalsWrapper;
