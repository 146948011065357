import { PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Languages } from "../shared/Languages";

import './FooterStickyContact.scss';

type Props = WithTranslation;
type State = Record<string, never>;

class FooterStickyContact extends PureComponent<Props, State> {
  render(){
    return (
      <>
        <nav className="footer-bottom-sticky">
          <div className="nav-item">
            <a
                href={`/${this.props.i18n.language}/quotes/create`}
                className="text-white footer-contact-link"
            >
              {this.props.t('Send Enquiry')}
            </a>
          </div>

          <div className="nav-item nav-item-lang-footer">
            <Languages />
          </div>
        </nav>
      </>
    );
  }
}

const WithTrans = withTranslation()(FooterStickyContact);

export { WithTrans as FooterStickyContact };

export default WithTrans;
