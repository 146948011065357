import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Suspense} from "react";
import {WithTranslation, withTranslation} from "react-i18next";

type Props = {
  onClick: () => void,
} & WithTranslation;

function BookAppointmentLink(props: Props) {
  return <button
      type="button"
      className="btn btn-danger-custom text-16 fw-bold text-white"
      onClick={props.onClick}
  >
    <Suspense>
      <FontAwesomeIcon
          icon={solid("mail-bulk")}
          className="me-2"
      />
    </Suspense>
    {props.t('Book Appointment')}
  </button>;
}

const WithTrans = withTranslation()(BookAppointmentLink);

export {WithTrans as BookAppointmentLink}