import { PureComponent, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { WithTranslation, withTranslation } from 'react-i18next';

import { LazyImageSuspense } from './LazyImageSuspense';

import './AboutUs.scss';

type Props = WithTranslation;
type State = Record<string, never>;

class AboutUs extends PureComponent<Props, State> {
  render() {
    return (
      <>
        <section className="section-sp1 about-area about-us">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-3">
                <div className="about-thumb-area">
                  <div className="row mb-3">
                    <div className="col-6">
                      <LazyImageSuspense
                        className="about-thumb1"
                        src="https://res.cloudinary.com/alfplus-com/image/upload/v1677778237/Alfplus.com/Hospics/ResizedImage/BLK_Delhi-Thumbnail.jpg"
                        alt="BLK_Delhi"
                      />
                    </div>
                    <div className="col-6">
                      <LazyImageSuspense
                        className="about-thumb2"
                        src="https://res.cloudinary.com/alfplus-com/image/upload/v1677778238/Alfplus.com/Hospics/ResizedImage/Continental_Hospitals_Hyderabad-Thumbnail.jpg"
                        alt="Continental_Hospitals_Hyderabad"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <LazyImageSuspense
                        className="about-thumb3"
                        src="https://res.cloudinary.com/alfplus-com/image/upload/v1677778238/Alfplus.com/Hospics/ResizedImage/Fortis_HospitalsFortis_Hospital_Bannerghatta_Bangalore-Thumbnail.jpg"
                        alt="Fortis_Hospital_Bannerghatta_Bangalore"
                      />
                    </div>
                    <div className="col-6">
                      <LazyImageSuspense
                        className="about-thumb4"
                        src="https://res.cloudinary.com/alfplus-com/image/upload/v1677778238/Alfplus.com/Hospics/ResizedImage/Lilavati_Hospital_and_Research_Center_Chennai_Madras-Thumbnail.jpg"
                        alt="Lilavati Hospital And Research Center Chennai"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="heading-bx">
                  <h6 className="title-ext text-primary">
                    {this.props.t('About Us')}
                  </h6>
                  <h2 className="title">
                    {this.props.t('Quality, Transparency and Responsibility')}
                  </h2>
                  <p>
                    {this.props.t('AboutUsDescription')}
                  </p>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-6 col-sm-6 mb-4 mb-sm-3">
                    <div className="feature-container feature-bx1 feature1">
                      <div className="icon-md">
                        <span className="icon-cell text-primary">
                          <Suspense>
                            <FontAwesomeIcon icon={solid('envelope-open')}/>
                          </Suspense>
                        </span>
                      </div>
                      <div className="icon-content">
                        <div className="ttr-title h6">
                          {this.props.t('Send your details and preferences to us')}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-6 mb-4 mb-sm-3">
                    <div className="feature-container feature-bx1 feature2">
                      <div className="icon-md">
                        <span className="icon-cell text-primary">
                          <Suspense>
                            <FontAwesomeIcon icon={solid('calculator')}/>
                          </Suspense>
                        </span>
                      </div>
                      <div className="icon-content">
                        <div className="ttr-title h6">
                          {this.props.t('Receive quotation(s) within 48 hours')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 mb-4 mb-sm-3">
                    <div className="feature-container feature-bx1 feature3">
                      <div className="icon-md">
                        <span className="icon-cell text-primary">
                          <Suspense>
                            <FontAwesomeIcon icon={solid('handshake')}/>
                          </Suspense>
                        </span>
                      </div>
                      <div className="icon-content">
                        <div className="ttr-title h6">
                          {this.props.t('Get received by us in India')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 mb-4 mb-sm-3">
                    <div className="feature-container feature-bx1 feature4">
                      <div className="icon-md">
                        <span className="icon-cell text-primary">
                          <Suspense>
                            <FontAwesomeIcon icon={solid('user-md')}/>
                          </Suspense>
                        </span>
                      </div>
                      <div className="icon-content">
                        <div className="ttr-title h6">
                          {this.props.t('Get treated and fly back')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Link
                  to={`/${this.props.i18n.language}/quotes/create`}
                  className="btn btn-primary btn-lg shadow"
                >
                  {this.props.t('Start Planning')}
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const WithTrans = withTranslation()(AboutUs);

export { WithTrans as AboutUs };