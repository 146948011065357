import { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import './Team.scss';

import { PageHeader } from '../../shared';

type Props = WithTranslation;
type State = Record<string, never>;

class Team extends PureComponent<Props, State> {
  render(){
    return (
      <>
        <Helmet>
          <html lang={this.props.i18n.language} />
          <title>
            Meet our Team | Alfplus
          </title>
          <meta
              name="description"
              content="Meet our team,Dr Kishore Das(Medical Advisor), Irfan khan(CEO) and Mohammad Zaid(CTO)"
          />
        </Helmet>

        <div className="page-content bg-white team">
          <PageHeader title={this.props.t('Our Team')} />

          <section className="section-area section-sp3 team-wraper">
            <div className="container">
              <div className="heading-bx text-center">
                <h1 className="title">
                  {this.props.t('Meet Our Team')}
                </h1>
              </div>

              <div className="row justify-content-center">
                <div className="col-lg-6 mb-3">
                  <div
                    className="feature-container feature-bx2 feature1 team-card"
                  >
                    <div className="team-card-media">
                      <span className="icon-cell">
                        <img
                          alt="Irfan Khan"
                          className="cover"
                          src="https://res.cloudinary.com/alfplus-com/image/upload/v1614507578/Alfplus.com/team/WhatsApp_Image_2021-02-28_at_3.37.47_PM.jpg"
                        />
                      </span>
                    </div>

                    <div className="team-card-description">
                      <div className="ttr-title">
                        Irfan Khan
                      </div>

                      <p className="description">
                        Co-founder & CEO
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 mb-3">
                  <div
                    className="feature-container feature-bx2 feature1 team-card"
                  >
                    <div className="team-card-media">
                      <span className="icon-cell">
                        <img
                          alt="Mohammad Zaid"
                          className="contain"
                          src="https://res.cloudinary.com/alfplus-com/image/upload/v1614507777/Alfplus.com/team/Webp.net-resizeimage-removebg-preview.jpg"
                        />
                      </span>
                    </div>

                    <div className="team-card-description">
                      <div className="ttr-title">
                        Mohammad Zaid
                      </div>

                      <p className="description">
                        Co-founder & CTO
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const WithTrans = withTranslation()(Team);

export { WithTrans as Team };

export default WithTrans;
