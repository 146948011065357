import {Component, ContextType} from 'react';
import {Alert, Spinner} from 'react-bootstrap';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';

import {PageHeader, Show} from '../../shared';
import {PageStatus} from '../../enums';
import {Doctor, DoctorShort, Speciality} from '../../API/interfaces';
import {getParam} from '../../utilities/helpers';
import {DoctorsAPI} from '../../API/DoctorsAPI';
import {DoctorCard} from '../Doctors';
import {I18Context} from '../../shared/I18Context';
import {Quote} from "../Quotes";
import {SpecialityId} from "../../interfaces";
import {SpecialitiesAPI} from "../../API/SpecialitiesAPI";

type Props = {
  doctors: DoctorShort[],
  speciality: Speciality | null,
} & RouteComponentProps<{
  specialityId: SpecialityId,
}> & WithTranslation;

type State = {
  status: PageStatus,
  doctors: DoctorShort[],
  speciality: Speciality | null,
  error: string | null,
};

class DoctorsWithSpeciality extends Component<Props, State> {
  static contextType = I18Context;
  context!: ContextType<typeof I18Context>;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      speciality: this.props.speciality,
      doctors: this.props.doctors || [],
      error: null,
    };

    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    this.props.i18n.on('languageChanged', this.fetch);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.speciality?.id !== prevProps.speciality?.id) {
      this.setState({
        speciality: this.props.speciality,
        doctors: this.props.doctors,
      });
    }
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.fetch);
  }

  fetch(): void {
    if (!this.context.isInitialized) return;

    Promise.resolve()
        .then(() => {
          this.setState({status: PageStatus.Loading});
        })
        .then(() => {
          const specialityId = getParam<SpecialityId>(this, 'specialityId');
          return SpecialitiesAPI.speciality(specialityId);
        })
        .then((speciality) => {
          return Promise.all([
            speciality,
            DoctorsAPI.doctors({
              page: 1,
              limit: 15,
              speciality: speciality.name,
            }),
          ]);
        })
        .then(([speciality, doctors]) => {
          this.setState({
            speciality,
            doctors,
            status: PageStatus.Loaded,
          });
        })
        .catch((err) => {
          this.setState({status: PageStatus.Error, error: err.message});
        });
  }

  render() {
    const speciality = this.state.speciality?.name;

    return (
        <>
          <Helmet>
            <html lang={this.props.i18n.language}/>
            <title>
              {this.props.t(
                  'bestDoctorsTitle2',
                  {speciality},
              )}
            </title>
            <meta
                name="description"
                content={this.props.t(
                    'bestDoctorsDescription2',
                    {speciality},
                )}
            />
          </Helmet>

          <div className="page-content bg-white best-hospitals-and-doctors">
            <PageHeader title={this.props.t('Best doctors in India')}/>

            <Show when={this.state.status === PageStatus.Loading}>
              <div className="d-flex justify-content-center mt-5">
                <Spinner animation="border"/>
              </div>
            </Show>

            <Alert show={this.state.status === PageStatus.Error}>
              {this.state.error}
            </Alert>

            <section className="section-area section-sp1 hospitals-list">
              <div className="container">
                <Show when={this.state.doctors.length > 0}>

                  <div className="mt-5 doctors-list">
                    <h1 className="ms-2">
                      {this.props.t(
                          'Best doctors for {{speciality}} in India',
                          {speciality},
                      )}
                    </h1>

                    <div className="row">
                      {
                        this.state.doctors.map((doctor) => (
                            <div className="col-12 col-md-4 col-lg-3 mb-3">
                              <DoctorCard
                                  doctor={doctor as Doctor}
                                  key={doctor.id}
                              />
                            </div>
                        ))
                      }
                    </div>
                  </div>
                </Show>
              </div>

              <Quote/>
            </section>
          </div>
        </>
    );
  }
}

const WithRouter = withRouter(DoctorsWithSpeciality);

const WithTrans = withTranslation()(WithRouter);

(WithTrans as any).getInitialProps = async function ({match}): Promise<any> {
  try {
    const speciality = await SpecialitiesAPI.speciality(match.params.specialityId);
    const doctors = await DoctorsAPI.doctorsShort({
      page: 1,
      limit: 15,
      speciality: speciality.name,
    });
    return {
      speciality,
      doctors,
    };

  } catch (err: any) {
    console.error(err);
  }
};

export {WithTrans as DoctorsWithSpeciality};

export default WithTrans;
