import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {WithTranslation, withTranslation} from 'react-i18next';

import {Testimonial} from '../API/interfaces';

import {Show} from './Show';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {Suspense} from "react";

function TestimonialCard(
    props: {
      testimonial: Testimonial,
      short: boolean,
    } & WithTranslation,
) {
  return (
      <div className="testimonial-bx position-relative">
        <div className="testimonial-country">
          {props.testimonial.name}
        </div>

        <div className="testimonial-user-wrapper">
          <div className="testimonial-user text-black">
            <Suspense>
              <FontAwesomeIcon icon={solid('user')} className="w-100 h-100"/>
            </Suspense>
          </div>
        </div>

        <div className="testimonial-content">
          <p className={props.short ? 'line-clamp-3' : ''}>
            {props.testimonial.Message}
          </p>
        </div>

        <div className="client-info">
          <h6 className="name">
            From {props.testimonial.country}
          </h6>

          <Show when={props.short}>
            <Link
                to={`/${props.i18n.language}/testimonials/${props.testimonial.id}`}
                className="btn btn-sm btn-outline-primary"
            >
              {props.t('View')}
            </Link>
          </Show>
        </div>
        <div className="quote-icon">
          <i className="fas fa-quote-left"/>
        </div>
      </div>
  );
}

TestimonialCard.defaultProps = {
  short: false,
};

const WithTrans = withTranslation()(TestimonialCard);

export {WithTrans as TestimonialCard};
