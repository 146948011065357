import get from 'lodash/get';

import {api} from '../axiosConfig';
import {citiesURL} from '../utilities/urls';
import {injectPageNumber, Paginated} from '../utilities/tools';

import {City, CityShort} from './interfaces';
import {CityId} from "../interfaces";

export class CitiesAPI {
  static cities(
    page: number,
    limit: number,
  ): Promise<Paginated<City>[] | never> {
    return api.get(`${citiesURL}/`, {
      params: {
        page,
        limit,
      },
    })
      .then((response) => {
        const data = get(response, ['data', 'results'], null);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return data as City[];
      })
      .then(injectPageNumber(page, limit));
  }

  static citiesShort(
    page: number,
    limit: number,
  ): Promise<Paginated<CityShort>[] | never> {
    return api.get(`${citiesURL}-short/`, {
      params: {
        page,
        limit,
      },
    })
      .then((response) => {
        const data = get(response, ['data', 'results'], null);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return data as CityShort[];
      })
      .then(injectPageNumber(page, limit));
  }

  static cityShort(cityId: CityId): Promise<CityShort | never> {
    return api.get(`${citiesURL}-short/${cityId}/`)
      .then((response) => {
        const data = get(response, ['data'], null);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return data;
      });
  }
}