import { reducer as formReducer } from 'redux-form';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';

const rootPersistConfig = {
  key: 'root',
  storage,
  version: 1,
};

const rootReducer = combineReducers({
  form: formReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

// eslint-disable-next-line max-len
const composeEnhances = compose;

const store = createStore(
  persistedReducer,
  composeEnhances(applyMiddleware(thunk)),
);
const persistor = persistStore(store);

export {
  store,
  persistor,
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
