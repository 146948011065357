import {createContext} from "react";

export type TI18Context = {
  isInitialized: boolean
};

const I18Context = createContext<TI18Context>(
  {
    isInitialized: false,
  },
);

export {
  I18Context,
};
