import get from 'lodash/get';

import { api } from '../axiosConfig';
import { blogsURL } from '../utilities/urls';
import {
  injectPageNumber,
  injectPageNumberWithCount,
  Paginated,
} from '../utilities/tools';
import { BlogId, DoctorId, HospitalId, SpecialityId } from '../interfaces';

import { Blog } from './interfaces';

export class BlogsAPI {
  static blogs(
    params: {
      page: number,
      limit: number,
      hospitalId?: HospitalId,
      doctorId?: DoctorId | null,
      specialityId?: SpecialityId | null,
    },
  ): Promise<Paginated<Blog>[] | never> {
    return api.get(`${blogsURL}/blogs/`, {
      params: {
        page: params.page,
        limit: params.limit,
        hospital_id: params?.hospitalId,
        doctor_id: params?.doctorId,
        speciality_id: params?.specialityId,
      },
    })
      .then((response) => {
        const data = get(response, ['data', 'results'], null);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return data as Blog[];
      })
      .then(injectPageNumber(params.page, params.limit));
  }

  static blogsWithCount(
    params: {
      page: number,
      limit: number,
      hospitalId?: HospitalId,
      doctorId?: DoctorId | null,
      specialityId?: SpecialityId | null,
    },
  ): Promise<{
      data: Paginated<Blog>[],
      count: number,
    } | never> {
    return api.get(`${blogsURL}/blogs/`, {
      params: {
        page: params.page,
        limit: params.limit,
        hospital_id: params?.hospitalId,
        doctor_id: params?.doctorId,
        speciality_id: params?.specialityId,
      },
    })
      .then((response) => {
        const data = get(response, ['data', 'results'], null);
        const count = get(response, ['data', 'count'], 0);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return {
          data: data as Blog[],
          count,
        };
      })
      .then(injectPageNumberWithCount(params.page, params.limit));
  }

  static blog(
    blogId: BlogId,
  ): Promise<Blog | never> {
    return api.get(`${blogsURL}/blogs/${blogId}/`)
      .then((response) => {
        const data = get(response, ['data'], null);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return data as Blog;
      });
  }
}
