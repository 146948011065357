import { ContextType, PureComponent, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { withTranslation, WithTranslation } from 'react-i18next';

import { I18Context } from '../shared/I18Context';
import {
  BestDoctorsSpecialities,
  BestHospitalsSpecialities,
} from '../modules/Common';

import facebook from 'images/social/facebook.png';
import twitter from 'images/social/twitter.png';
import linkedin from 'images/social/linkedin.png';

import './Footer.scss';

type Props = WithTranslation;

class Footer extends PureComponent<Props> {
  static contextType = I18Context;
  context!: ContextType<typeof I18Context>;

  render() {
    return (
      <>
        <footer
          className="footer pb-5"
          style={{ background: 'rgb(86 90 207 / 10%)' }}
        >
          <div className="footer-top">
            <div className="container">
              <Suspense>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <BestHospitalsSpecialities/>
                  </div>
                  <div className="col-12 col-lg-6">
                    <BestDoctorsSpecialities/>
                  </div>
                </div>
              </Suspense>

              <hr/>

              <div className="row g-0 g-md-1 g-lg-3">
                <div className="col-lg-3 col-6">
                  <div className="widget footer_widget">
                    <h5>{this.props.t('Content')}</h5>
                    <ul className="list-unstyled">
                      <li className="list-unstyled">
                        <Link to={`/${this.props.i18n.language}/doctors`}>
                          <span>{this.props.t('Doctors')}</span>
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link to={`/${this.props.i18n.language}/hospitals`}>
                          <span>{this.props.t('Hospitals')}</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/${this.props.i18n.language}/cities-in-india`}>
                          <span>{this.props.t('Cities in India')}</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/${this.props.i18n.language}/our-services`}>
                          <span>{this.props.t('Our Services')}</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-6">
                  <div className="widget footer_widget">
                    <h5>{this.props.t('Know Us')}</h5>

                    <ul className="list-unstyled">
                      <li>
                        <Link to={`/${this.props.i18n.language}/about-us`}>
                          <span>{this.props.t('About Us')}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={`/${this.props.i18n.language}/contact-us`}>
                          <span>{this.props.t('Contact')}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={`/${this.props.i18n.language}/mission`}>
                          <span>{this.props.t('Mission')}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={`/${this.props.i18n.language}/team`}>
                          <span>{this.props.t('Team')}</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/${this.props.i18n.language}/partner-with-us`}>
                          <span>{this.props.t('Partner With Us')}</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-6">
                  <div className="widget footer_widget">
                    <h5>{this.props.t('Legal')}</h5>
                    <ul className="list-unstyled">
                      <li>
                        <Link to={`/${this.props.i18n.language}/disclaimer`}>
                          <span>{this.props.t('Disclaimer')}</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/${this.props.i18n.language}/terms-of-use`}>
                          <span>{this.props.t('Terms Of Use')}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={`/${this.props.i18n.language}/copyrights`}>
                          <span>{this.props.t('Copyrights')}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={`/${this.props.i18n.language}/legalities`}>
                          <span>{this.props.t('Legalities')}</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/${this.props.i18n.language}/testimonials`}>
                          <span>{this.props.t('Testimonials')}</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-6">
                  <div className="widget footer_widget">
                    <h5>{this.props.t('Contact Us')}</h5>
                    <div>
                      <a className="text-13" href="tel:+919990085860">
                        <Suspense>
                          <FontAwesomeIcon icon={solid('phone')}/>
                        </Suspense>
                        <span className="ms-2">
                          +91-9990085860
                        </span>
                      </a>
                    </div>

                    <div>
                      <a
                        className="text-13"
                        href="mailto:care@alfplus.com"
                      >
                        <Suspense>
                          <FontAwesomeIcon icon={solid('envelope')}/>
                        </Suspense>
                        <span className="ms-2">
                          care@alfplus.com
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="footer-bottom">
              <div className="row">
                {/* eslint-disable-next-line max-len */}
                <div
                  className="col-md-6 footer-social-link text-md-end text-center mb-md-0 mb-2"
                >
                  <ul className="list-unstyled">
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/AlfPlusMedicals">
                        <img
                          src={facebook}
                          alt="facebook"
                          width={20}
                          height={20}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://twitter.com/alfplusindia">
                        <img
                          src={twitter}
                          alt="twitter"
                          width={20}
                          height={20}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/company/alfplusmedicals"
                      >
                        <img
                          src={linkedin}
                          alt="linkedin"
                          width={20}
                          height={20}
                        />
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-md-6 text-md-start text-center">
                  <p className="copyright-text">
                    {this.props.t('Copyright © 2023')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

const WithTrans = withTranslation()(Footer);

export { WithTrans as Footer };

export default WithTrans;