import {Component, ContextType, ReactNode, Suspense} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {PageStatus} from '../../enums';
import {City, Speciality} from '../../API/interfaces';
import {Show} from '../Show';

import {FiltersContext} from './FiltersContext';
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

type Props = {
  children: ReactNode,

  onChange: (
      filters: {
        city: City | null,
        speciality: Speciality | null,
      }
  ) => Promise<void>,
} & WithTranslation;

type State = {
  status: PageStatus,
  cities: City[],
  error: string | null,
};

class Filters extends Component<Props, State> {
  static contextType = FiltersContext;
  context!: ContextType<typeof FiltersContext>;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      cities: [],
      error: null,
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.context.emitter.on('FILTER', this.onChange);
  }

  componentWillUnmount() {
    this.context.emitter.off('FILTER', this.onChange);
  }

  close(): void {
    this.setState({
      error: null,
      status: PageStatus.None,
    }, () => {
      this.context.onToggle();
    });
  }

  onChange(
      filters: {
        city: City | null,
        speciality: Speciality | null,
      },
  ): void {
    Promise.resolve()
        .then(() => {
          this.setState({status: PageStatus.Loading});
        })
        .then(() => {
          return this.props.onChange(filters);
        })
        .then(() => {
          this.setState({status: PageStatus.Loaded});
        })
        .catch((err) => {
          this.setState({status: PageStatus.Error, error: err.message});
        });
  }

  render() {
    return (
        <>
          <Show when={this.context.show}>
            <div className="filters-wrapper d-flex d-lg-none">
              <div className="filters-part-1">
                <div
                    className="d-flex align-items-center justify-content-between">
                  <div className="h4">
                    {this.props.t('Filters')}
                  </div>

                  <button
                      onClick={() => {
                        this.close();
                      }}
                      className="btn bg-transparent p-2 mt-n2"
                  >
                    <Suspense>
                      <FontAwesomeIcon icon={solid('times')} size="lg"/>
                    </Suspense>
                  </button>
                </div>

                <hr/>

                <div className="text-13">
                  <Show when={this.state.status === PageStatus.Loading}>
                    <div
                        style={{
                          opacity: '0.7',
                          background: 'white',
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                        }}
                    />
                  </Show>

                  <div className="d-block d-lg-none pb-100">
                    {this.props.children}
                  </div>

                  <div className="position-sticky bottom-0 w-100">
                    <button
                        onClick={() => {
                          this.context.onToggle();
                        }}
                        className="btn btn-sm btn-primary w-100 mb-2"
                    >
                      <Suspense>
                        <FontAwesomeIcon
                            icon={solid('check')}
                            className="me-2"
                        />
                      </Suspense>
                      {this.props.t('Show Results')}
                    </button>

                    <button
                        onClick={() => {
                          this.context.onReset();
                        }}
                        className="btn btn-sm btn-danger text-white w-100"
                    >
                      <Suspense>
                        <FontAwesomeIcon
                            icon={solid('times')}
                            className="me-2"
                        />
                      </Suspense>
                      {this.props.t('Clear All')}
                    </button>
                  </div>
                </div>
              </div>

              <div
                  className="filters-part-2"
                  onClick={() => {
                    this.context.onToggle();
                  }}
              />
            </div>
          </Show>

          <div className="d-none d-lg-block">
            <div className="h4">
              {this.props.t('Filters')}
            </div>

            <div className="widget text-13">
              <Show when={this.state.status === PageStatus.Loading}>
                <div
                    style={{
                      opacity: '0.7',
                      background: 'white',
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                    }}
                />
              </Show>

              <div className="filters-wrapper">
                {this.props.children}
              </div>
            </div>
          </div>
        </>
    );
  }
}

const WithTrans = withTranslation()(Filters);

export {WithTrans as Filters};
