import { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { FeaturedSpeciality } from '../API/interfaces';
import { getFeaturedSpecialities } from '../utilities/helpers';

import { SpecialityLinkCard } from './SpecialityLinkCard';

type Props = {
  specialities: FeaturedSpeciality[],
} & WithTranslation;

type State = Record<string, never>;

class SpecialitiesSlider extends PureComponent<Props, State> {
  render() {
    return (
      <>
        <section className="section-sp1 service-wraper2">
          <div className="container">
            <div className="heading-bx text-center">
              <h6 className="title-ext text-primary">
                {this.props.t('Top Specialities')}
              </h6>
              <h2 className="title">
                {this.props.t('Get best treatment with us')}
              </h2>
            </div>

            <div className="row justify-content-center">
              {
                getFeaturedSpecialities(
                  this.props.i18n.language,
                ).map((speciality) => (
                  // eslint-disable-next-line max-len
                  <div
                    key={speciality.id}
                    className="col-xl-2 col-lg-3 col-xs-4 col-sm-4 col-4 mb-2"
                  >
                    <SpecialityLinkCard speciality={speciality}/>
                  </div>
                ))
              }
            </div>

            {/*<div className="text-center w-100">*/}
            {/*  <Link*/}
            {/*    to={`/${this.props.i18n.language}/specialities`}*/}
            {/*    className="btn btn-primary shadow"*/}
            {/*  >*/}
            {/*    {this.props.t('View All')}*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </div>
        </section>
      </>
    );
  }
}

const WithTrans = withTranslation()(SpecialitiesSlider);

export { WithTrans as SpecialitiesSlider };
