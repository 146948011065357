import { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { PageHeader, Show } from '../../shared';
import { Language } from '../../enums';

type Props = WithTranslation;
type State = Record<never, never>;

class Mission extends PureComponent<Props, State> {
  render() {
    return (
      <>
        <Helmet>
          <html lang={this.props.i18n.language} />
          <title>
            Alfplus Vision Mission | Alfplus
          </title>
          <meta
              name="description"
              content="To achieve the highest standard in medical tourism and healthcare industry to facilitate you in the best hospitals equipped with the most advanced technologies in the world and provide you options more than one as per your budget and priorities."
          />
        </Helmet>

        <div className="page-content bg-white our-mission">
          <PageHeader title={this.props.t('Our Mission')} />

          <section className="our-mission-section">
            <div className="container">
              <div>
                <h1>
                  {this.props.t('Our Mission')}
                </h1>

                <Show when={this.props.i18n.language === Language.en}>
                  <p>
                    To achieve the highest standard in medical tourism and
                    healthcare industry to facilitate you in the best
                    hospitals equipped with
                    the most advanced technologies in the world and provide you
                    options more than one as per your budget and priorities.
                  </p>
                </Show>

                <Show when={this.props.i18n.language === Language.ar}>
                  <p>
                    لتحقيق أعلى مستوى في السياحة العلاجية و
                    صناعة الرعاية الصحية لتسهيلك في الأفضل
                    المستشفيات المجهزة
                    أحدث التقنيات في العالم ونوفرها لك
                    خيارات أكثر من واحد حسب ميزانيتك وأولوياتك.
                  </p>
                </Show>

                <Show when={this.props.i18n.language === Language.uz}>
                  <p>
                    Tibbiy turizmda eng yuqori standartga erishish va
                    sog&apos;liqni saqlash sanoati sizga eng yaxshi
                    yordam berish uchun kasalxonalar bilan jihozlangan
                    dunyodagi eng ilg&apos;or texnologiyalar va sizga
                    taqdim etadi byudjetingiz va ustuvorliklaringizga
                    ko&apos;ra bir nechta variantlar.
                  </p>
                </Show>

                <h2 className="mt-3">
                  {this.props.t('Vision')}
                </h2>

                <Show when={this.props.i18n.language === Language.en}>
                  <p>
                    To win your trust to become world’s most trusted and
                    leading medical tourism company.
                  </p>
                </Show>

                <Show when={this.props.i18n.language === Language.ar}>
                  <p>
                    لكسب ثقتك لتصبح الأكثر ثقة وريادة في العالم
                    شركة سياحة طبية.
                  </p>
                </Show>

                <Show when={this.props.i18n.language === Language.uz}>
                  <p>
                    Dunyodagi eng ishonchli va yetakchi bo&apos;lish
                    uchun ishonchingizni qozonish tibbiy turizm kompaniyasi.
                  </p>
                </Show>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const WithTrans = withTranslation()(Mission);

export { WithTrans as Mission };

export default WithTrans;
