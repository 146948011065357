import { Component, ContextType } from 'react';
import { Spinner } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';

import { PageStatus } from '../../enums';
import { City } from '../../API/interfaces';
import { CitiesAPI } from '../../API/CitiesAPI';
import { Show } from '../Show';

import { FiltersContext } from './FiltersContext';

type Props = {
  i18Initialized: boolean,
} & WithTranslation;

type State = {
  status: PageStatus,
  cities: City[],
  error: string | null,
};

class FilterByCities extends Component<Props, State> {
  context!: ContextType<typeof FiltersContext>;
  static contextType = FiltersContext;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      cities: [],
      error: null,
    };
    this.fetchCities = this.fetchCities.bind(this);
  }

  componentDidMount() {
    this.fetchCitiesInitial();

    this.props.i18n.on('languageChanged', this.fetchCities);
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.fetchCities);
  }

  fetchCitiesInitial() {
    Promise.resolve()
      .then(() => {
        this.setState({ status: PageStatus.Loading });
      })
      .then(() => {
        return CitiesAPI.cities(1, 1000);
      })
      .then((cities) => {
        this.updateFilter(cities);
        this.setState({ cities, status: PageStatus.Loaded });
      })
      .catch((err) => {
        this.setState({ status: PageStatus.Error, error: err.message });
      });
  }

  fetchCities() {
    if (!this.props.i18Initialized) return;

    Promise.resolve()
      .then(() => {
        this.setState({ status: PageStatus.Loading });
      })
      .then(() => {
        return CitiesAPI.cities(1, 1000);
      })
      .then((cities) => {
        this.updateFilter(cities);
        this.setState({ cities, status: PageStatus.Loaded });
      })
      .catch((err) => {
        this.setState({ status: PageStatus.Error, error: err.message });
      });
  }

  updateFilter(cities: City[]): void {
    if (!!this.context.city) {
      const city = cities.find((cityIn) => {
        return this.context.city?.id === cityIn.id;
      });
      this.context.onCity(city || null, true);
    }
  }

  render() {
    return (
      <>
        <Show when={this.state.status === PageStatus.Loading}>
          <Spinner animation="border" />
        </Show>

        <Show
          when={!this.state.error && this.state.status === PageStatus.Loaded}
        >
          <div className="h6">
            {this.props.t('By Cities')}
          </div>
          {
            this.state.cities.map((city) => (
              <div className="form-check" key={city.id}>
                <input
                  type="checkbox"
                  key={city.id}
                  className="form-check-input"
                  onChange={(event) => {
                    if (event.target.checked) {
                      this.context.onCity(city);
                    } else {
                      this.context.onCity(null);
                    }
                  }}
                  checked={this.context.city?.id === city.id}
                  id={`${city.city}-${city.id}`}
                />
                <label
                  className="form-check-label"
                  htmlFor={`${city.city}-${city.id}`}
                >
                  {city.city.split(' - ')[0]}
                </label>
              </div>
            ))
          }
        </Show>
      </>
    );
  }
}

const WithTrans = withTranslation()(FilterByCities);

export { WithTrans as FilterByCities };
