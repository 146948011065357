export enum PageStatus {
  None = 'None',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

export enum Language {
  en = 'en',
  ar = 'ar',
  uz = 'uz',
}
