export class CustomError extends Error {
  message: string;
  code: number | null;

  constructor(message: string, code: number | null) {
    super(message);
    this.message = message;
    this.code = code;
  }
}
