import { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import './PageHeader.scss';
import { SearchBar } from './Search';

type Props = {
  title: string,
} & WithTranslation;

type State = Record<string, never>;

class PageHeader extends PureComponent<Props, State> {
  render() {
    return (
      <div className="banner-wraper">
        <div>
          <div className="container">
            <div className="page-banner-entry text-center">
              <div className="d-flex w-100 justify-content-center">
                <SearchBar/>
              </div>

              {/*<nav aria-label="breadcrumb" className="breadcrumb-row">*/}
              {/*  <ul className="breadcrumb">*/}
              {/*    <li className="breadcrumb-item">*/}
              {/*      <Link to={`/${this.props.i18n.language}`}>*/}
              {/*        <svg*/}
              {/*          xmlns="http://www.w3.org/2000/svg"*/}
              {/*          width="22"*/}
              {/*          height="22"*/}
              {/*          viewBox="0 0 24 24"*/}
              {/*          fill="none"*/}
              {/*          stroke="currentColor"*/}
              {/*          strokeWidth="2"*/}
              {/*          strokeLinecap="round"*/}
              {/*          strokeLinejoin="round"*/}
              {/*          className="feather feather-home"*/}
              {/*        >*/}
              {/*          <path*/}
              {/*            d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"*/}
              {/*          >*/}
              {/*          </path>*/}
              {/*          <polyline points="9 22 9 12 15 12 15 22" />*/}
              {/*        </svg> {this.props.t('Home')}*/}
              {/*      </Link>*/}
              {/*    </li>*/}
              {/*    <li*/}
              {/*      className="breadcrumb-item active"*/}
              {/*      aria-current="page"*/}
              {/*    >*/}
              {/*      {this.props.title}*/}
              {/*    </li>*/}
              {/*  </ul>*/}
              {/*</nav>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WithTrans = withTranslation()(PageHeader);

export { WithTrans as PageHeader };
