import { PureComponent, ContextType } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import {I18Context} from "../../shared/I18Context";
import {getFeaturedSpecialities} from "../../utilities/helpers";

/* eslint-disable @typescript-eslint/object-curly-spacing */

type Props = WithTranslation;
class BestDoctorsSpecialities extends PureComponent<Props> {
  context!: ContextType<typeof I18Context>;
  static contextType = I18Context;
  render() {
    return (
        <>
          <h6>
            {this.props.t('Best doctors in India')}
          </h6>

          <ul className="list-unstyled">
            {
              getFeaturedSpecialities(this.props.i18n.language).map((speciality) => (
                  <li key={`best-doctors-${speciality.id}`}>
                    <Link to={`/${this.props.i18n.language}/best-doctors/specialities/${speciality.id}`}>
                      {
                        this.props.t(
                            'Best doctors for {{speciality}} in India',
                            { speciality: speciality?.name }
                        )
                      }
                    </Link>
                  </li>
              ))
            }
          </ul>
        </>
    );
  }
}

const WithTrans = withTranslation()(BestDoctorsSpecialities);

export { WithTrans as BestDoctorsSpecialities };

export default WithTrans;
