import {PureComponent, Suspense} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {WithTranslation, withTranslation} from 'react-i18next';

import {FeaturedTreatment} from '../API/interfaces';
import {getFeaturedTreatments} from "../utilities/helpers";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

import './TopProcedures.scss';

type Props = {
  treatments: FeaturedTreatment[],
} & WithTranslation;

type State = Record<string, never>;

class TopProcedures extends PureComponent<Props, State> {
  render() {
    return (
        <>
          <section
              className="section-area section-sp3 team-wraper top-procedures"
          >
            <div className="container">
              <div className="heading-bx text-center">
                <h6 className="title-ext text-primary">
                  {this.props.t('Top Procedures')}
                </h6>
                <h2 className="title">
                  {this.props.t('Procedures for which most of the patients come to India')}
                </h2>
              </div>

              <ul className="service-menu row list-unstyled">
                {
                  getFeaturedTreatments(this.props.i18n.language).map((treatment) => (
                      <li
                          key={treatment.id}
                          className="active col-lg-4 col-sm-6 col-12"
                      >
                        <Link
                            to={`/${this.props.i18n.language}/cost-of-treatments/${treatment.id}`}>
                          <span>{treatment.name}</span>
                          <span className="price">
                            <Suspense>
                          <FontAwesomeIcon
                              icon={solid('dollar-sign')}
                              className="me-1"
                          />
                            </Suspense>
                            {treatment.cost}
                        </span>
                        </Link>
                      </li>
                  ))
                }
              </ul>

              <div className="text-center w-100 mt-3">
                <Link
                    to={`/${this.props.i18n.language}/costs`}
                    className="btn btn-primary shadow"
                >
                  {this.props.t('View All')}
                </Link>
              </div>
            </div>
          </section>
        </>
    );
  }
}

const WithTrans = withTranslation()(TopProcedures);

export {WithTrans as TopProcedures};
