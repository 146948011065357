import { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { PageHeader, Show } from '../../shared';
import { Language } from '../../enums';

type Props = WithTranslation;
type State = Record<never, never>;

class AboutUs extends PureComponent<Props, State> {
  render() {
    return (
      <>
        <Helmet>
          <html lang={this.props.i18n.language} />
          <title>
            {this.props.t('aboutUsTitle')}
          </title>
          <meta
              name="description"
              content={this.props.t('aboutUsDescription')}
          />
        </Helmet>

        <div className="page-content bg-white best-hospitals-and-doctors">
          <PageHeader title={this.props.t('About Us')} />

          <section className="about-us">
            <div className="container">
              <Show when={this.props.i18n.language === Language.en}>
                <div>
                  <h1>About Us</h1>

                  <ul className="ps-3">
                    <li className="mb-3">
                    Alfplus Medicals stands for Quality, Transparency,
                    Responsibility, Professionalism, Negotiation with hospitals
                    for you and Customer satisfaction in more than 12 cities
                    throughout India like New Delhi, Gurgaon, Faridabad,
                    Noida, Mumbai, Kerala, Chennai and Hyderabad.
                    </li>
                    <li className="mb-3">
                    Alfplus Medicals has served patients in India from more than
                    47 countries like USA, Canada, UK, Kingdom of
                    Saudi Arabia, United Arab Emirates, Iraq, Kuwait, Yemen,
                    Oman, Qatar, Bahrain, Egypt, Morocco, Kenya, Mali,
                    Mauritania, Algeria, Sierra Leone, Sudan, Congo,
                    South Africa, Uganda, Somalia, Ethiopia, Tanzania,
                    Afghanistan, Uzbekistan, Kazakhstan, Turkmenistan,
                    Bangladesh, and Fiji.
                    </li>
                    <li className="mb-3">
                    Alfplus Medicals deals with every case very seriously and swiftly
                    that’s why when you contact us, we provide
                    you verified quotes and treatment details as soon as
                    possible after consulting our medical specialists from
                    more than one source so that you can have more options as
                    per your priorities and budget.
                    </li>
                    <li className="mb-3">
                    Alfplus Medicals is one of the largest platform for medical
                    travelers where you get 1500+ specialists and
                    surgeons from 90+ multi-specialty hospitals across India and
                    where you can find about your procedures,
                    conditions, diseases, symptoms, tests and treatments with
                    the list of our experienced doctors and hospitals
                    equipped with advanced technologies.
                    </li>
                    <li className="mb-3">
                    Alfplus Medicals and its multilingual team assists patients from
                    their own countries in terms of facilitating
                    for medical Visa to travel to India, updating about airline
                    fares and arrangements for stay in India in a
                    very transparent manner. The team will be helping every
                    patient with daily travel, language, food issues,
                    shopping and outing matters, and will be taking care of
                    every one 24/7.
                    </li>
                  </ul>
                </div>
              </Show>

              <Show when={this.props.i18n.language === Language.ar}>
                <div>
                  <h1>{this.props.t('About Us')}</h1>

                  <ul className="ps-3">
                    <li className="mb-3">
                      يرمز موقع الفبلس الطبية إلى الجودة والشفافية والمسؤولية والاحتراف والتفاوض
                      مع المستشفيات من أجلك ورضا العملاء في أكثر من 12 مدينة في جميع أنحاء
                      الهند مثل نيودلهي وجورجاون وفريد أباد ونويدا ومومباي وكيرالا وتشيناي وحيدر أباد.
                    </li>
                    <li className="mb-3">
                      لقد خدم موقع الفبلس الطبية المرضى في الهند من أكثر
                      من 47 دولة مثل الولايات المتحدة الأمريكية وكندا والمملكة
                      المتحدة والمملكة العربية السعودية والإمارات العربية المتحدة
                      والعراق والكويت واليمن وسلطنة عمان وقطر والبحرين ومصر
                      والمغرب وكينيا ومالي وموريتانيا والجزائر وسيراليون والسودان
                      والكونغو وجنوب إفريقيا وأوغندا والصومال وإثيوبيا وتنزانيا
                      وأفغانستان وأوزبكستان وكازاخستان وتركمانستان وبنغلاديش وفيجي.
                    </li>
                    <li className="mb-3">
                      يتعامل الفبلس الطبية مع كل حالة بجدية وسرعة
                      لهذا السبب عندما تتصل بنا ، نقدم لك
                      لقد تحققت من عروض الأسعار وتفاصيل العلاج في أقرب وقت
                      ممكن بعد استشارة المتخصصين الطبيين لدينا من
                      أكثر من مصدر واحد بحيث يمكنك الحصول على المزيد من الخيارات مثل
                      حسب أولوياتك وميزانيتك.
                    </li>
                    <li className="mb-3">
                      يتعامل موقع الفبلس الطبية مع كل حالة بجدية وسرعة
                      لهذا السبب عندما تتصل بنا ، نقدم لك
                      لقد تحققت من عروض الأسعار وتفاصيل العلاج في أقرب وقت
                      ممكن بعد استشارة المتخصصين الطبيين لدينا من
                      أكثر من مصدر واحد بحيث يمكنك الحصول على المزيد من الخيارات مثل
                      حسب أولوياتك وميزانيتك.
                    </li>
                    <li className="mb-3">
                      يساعد موقع الفبلس الطبية وفريقه متعدد اللغات المرضى من
                      بلدانهم من حيث التيسير
                      للحصول على تأشيرة طبية للسفر إلى الهند ، وتحديث معلومات شركة الطيران
                      أسعار وترتيبات الإقامة في الهند في أ
                      بطريقة شفافة للغاية. سيساعد الفريق الجميع
                      المريض مع السفر اليومي ، واللغة ، ومشاكل الغذاء ،
                      التسوق والنزهة مهمان ، وسيعتني بهما
                      كل واحد 24/7.
                    </li>
                  </ul>
                </div>
              </Show>

              <Show when={this.props.i18n.language === Language.uz}>
                <div>
                  <h1>
                    {this.props.t('About Us')}
                  </h1>

                  <ul className="ps-3">
                    <li className="mb-3">
                      Alfplus Medicals sifat, shaffoflik,
                      Mas&apos;uliyat, Kasbiylik, Kasalxonalar bilan muzokaralar olib borish
                      12 dan ortiq shaharlarda siz va mijozlar ehtiyojini qondirish uchun
                      Hindiston bo&apos;ylab Yangi Dehli, Gurgaon, Faridobod,
                      Noida, Mumbay, Kerala, Chennay va Haydarobod.
                    </li>
                    <li className="mb-3">
                      Alfplus Medicals Hindistonda ko&apos;proq bemorlarga xizmat ko&apos;rsatdi
                      AQSh, Kanada, Buyuk Britaniya, Qirollik kabi 47 mamlakat
                      Saudiya Arabistoni, Birlashgan Arab Amirliklari, Iroq, Quvayt, Yaman,
                      Ummon, Qatar, Bahrayn, Misr, Marokash, Keniya, Mali,
                      Mavritaniya, Jazoir, Syerra-Leone, Sudan, Kongo,
                      Janubiy Afrika, Uganda, Somali, Efiopiya, Tanzaniya,
                      Afgʻoniston, Oʻzbekiston, Qozogʻiston, Turkmaniston,
                      Bangladesh va Fiji.
                    </li>
                    <li className="mb-3">
                      Alfplus Medicals har bir ishni juda jiddiy va tezkorlik bilan hal qiladi
                      shuning uchun biz bilan bog&apos;lansangiz, biz taqdim etamiz
                      Siz kotirovkalar va davolanish tafsilotlarini darhol tasdiqladingiz
                      dan tibbiy mutaxassislarimiz bilan maslahatlashganidan keyin mumkin
                      sifatida ko&apos;proq imkoniyatlarga ega bo&apos;lishingiz uchun bir nechta manba
                      ustuvorliklaringiz va byudjetingizga qarab.
                    </li>
                    <li className="mb-3">
                      Alfplus Medicals tibbiyot uchun eng katta platformalardan biridir
                      1500 dan ortiq mutaxassislarni oladigan sayohatchilar va
                      Hindiston bo&apos;ylab 90+ ko&apos;p ixtisoslashgan shifoxonalardan jarrohlar va
                      protseduralaringiz haqida qayerdan topishingiz mumkin,
                      holatlar, kasalliklar, alomatlar, testlar va davolash usullari
                      tajribali shifokorlarimiz va shifoxonalarimiz ro&apos;yxati
                      ilg&apos;or texnologiyalar bilan jihozlangan.
                    </li>
                    <li className="mb-3">
                      Alfplus Medicals va uning ko&apos;p tilli jamoasi bemorlarga yordam beradi
                      osonlashtirish nuqtai nazaridan o&apos;z mamlakatlari
                      Hindistonga sayohat qilish uchun tibbiy viza uchun aviakompaniya haqida ma&apos;lumot
                      tariflar va Hindistonda qolish uchun kelishuvlar
                      juda shaffof tarzda. Jamoa har biriga yordam beradi
                      kundalik sayohat, til, oziq-ovqat muammolari bilan og&apos;rigan bemor,
                      xarid qilish va sayr qilish masalalari va g&apos;amxo&apos;rlik qiladi
                      har biri 24/7.
                    </li>
                  </ul>
                </div>
              </Show>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const WithTrans = withTranslation()(AboutUs);

export { WithTrans as AboutUs };

export default WithTrans;
