import { Component } from 'react';
import Select from 'react-select';

const components = {
  DropdownIndicator: null,
};

const colourStyles = {
  menu: styles => ({ ...styles, zIndex: '9999999' }),
};

export type SelectizerValue = {
  label: string,
  value: string | number,
};

type Props = {
  value: SelectizerValue | null,
  options: SelectizerValue[],

  placeholder?: string,
  isDisabled?: boolean,
  isLoading?: boolean,

  onChange: (value: SelectizerValue | null) => void,
  onClear?: () => void,
};

type State = {
  inputValue: string,
};

export class Selectizer extends Component<Props, State> {
  static defaultProps = {
    placeholder: '',
    isDisabled: false,
    isLoading: false,
    allowCreateNewOption: true,

    onClear: () => null,
  };

  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
    };
  }

  handleChange(value: SelectizerValue | null, triggeredAction) {
    if (triggeredAction.action === 'clear' && !!this.props.onClear) {
      this.props.onClear();
    }
    this.props.onChange(value);
  }

  handleInputChange(inputValue) {
    this.setState({ inputValue });
  }

  render() {
    return (
      <Select
        isClearable
        isDisabled={this.props.isDisabled}
        isLoading={this.props.isLoading}

        components={components}
        inputValue={this.state.inputValue}
        placeholder={this.props.placeholder}
        value={this.props.value}
        options={this.props.options}
        styles={colourStyles}

        onChange={(value, action) => this.handleChange(value, action)}
        onInputChange={(e) => this.handleInputChange(e)}
      />
    );
  }
}
