import get from 'lodash/get';

import { api } from '../axiosConfig';
import { hospitalsURL } from '../utilities/urls';
import {
  injectPageNumber,
  injectPageNumberWithCount,
  Paginated
} from '../utilities/tools';
import { HospitalId, SpecialityId } from '../interfaces';

import {Hospital, HospitalShort} from './interfaces';

export class HospitalsAPI {
  static hospitals(
    params: {
      page: number,
      limit: number,
      city?: string | null,
      name?: string,
      speciality?: string | null,
      specialityId?: SpecialityId,
    },
  ): Promise<Paginated<Hospital>[] | never> {
    return api.get(`${hospitalsURL}/`, {
      params: {
        page: params.page,
        limit: params.limit,
        city: params.city,
        speciality: params.speciality,
        specialityId: params.specialityId,
        name: params.name,
      },
    })
      .then((response) => {
        const data = get(response, ['data', 'results'], null);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return data as Hospital[];
      })
      .then(injectPageNumber(params.page, params.limit));
  }

  static hospitalsShort(
    params: {
      page: number,
      limit: number,
      city?: string | null,
      cityId?: string | null,
      name?: string,
      speciality?: string | null,
      specialityId?: SpecialityId,
    },
  ): Promise<Paginated<HospitalShort>[] | never> {
    return api.get(`${hospitalsURL}-short/`, {
      params: {
        page: params.page,
        limit: params.limit,
        city: params.city,
        cityId: params.cityId,
        speciality: params.speciality,
        specialityId: params.specialityId,
        name: params.name,
      },
    })
      .then((response) => {
        const data = get(response, ['data', 'results'], null);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return data as Hospital[];
      })
      .then(injectPageNumber(params.page, params.limit));
  }

  static hospitalsWithCount(
    params: {
      page: number,
      limit: number,
      city?: string | null,
      name?: string,
      speciality?: string | null,
      specialityId?: SpecialityId,
    },
  ): Promise<{
    data: Paginated<Hospital>[],
    count: number,
  } | never> {
    return api.get(`${hospitalsURL}/`, {
      params: {
        page: params.page,
        limit: params.limit,
        city: params.city,
        speciality: params.speciality,
        specialityId: params.specialityId,
        name: params.name,
      },
    })
      .then((response) => {
        const data = get(response, ['data', 'results'], null);
        const count = get(response, ['data', 'count'], 0);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return {
          data: data as Hospital[],
          count,
        };
      })
      .then(injectPageNumberWithCount(params.page, params.limit));
  }

  static hospitalsShortWithCount(
    params: {
      page: number,
      limit: number,
      city?: string | null,
      name?: string,
      speciality?: string | null,
      specialityId?: SpecialityId,
    },
  ): Promise<{
    data: Paginated<HospitalShort>[],
    count: number,
  } | never> {
    return api.get(`${hospitalsURL}-short/`, {
      params: {
        page: params.page,
        limit: params.limit,
        city: params.city,
        speciality: params.speciality,
        specialityId: params.specialityId,
        name: params.name,
      },
    })
      .then((response) => {
        const data = get(response, ['data', 'results'], null);
        const count = get(response, ['data', 'count'], 0);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return {
          data: data as Hospital[],
          count,
        };
      })
      .then(injectPageNumberWithCount(params.page, params.limit));
  }

  static hospital(hospitalId: HospitalId): Promise<Hospital | never> {
    return api.get(`${hospitalsURL}/${hospitalId}/`)
      .then((response) => {
        const data = get(response, ['data'], null);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return data;
      });
  }
}