import {PureComponent, Suspense} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {WithTranslation, withTranslation} from 'react-i18next';
import {LazyImageSuspense} from "./LazyImageSuspense";

import './AboutUs.scss';

type Props = WithTranslation;
type State = Record<string, never>;

class AboutUsShort extends PureComponent<Props, State> {
  render() {
    return (
        <>
          <section className="section-sp1 about-area about-us">
            <div className="row mb-3">
              <div className="col-lg-6 col-sm-6 mb-4 mb-sm-3">
                <div className="feature-container feature-bx1 feature1">
                  <div className="icon-md">
                          <span className="icon-cell text-primary">
                            <Suspense>
                              <FontAwesomeIcon icon={solid('envelope-open')}/>
                            </Suspense>
                          </span>
                  </div>
                  <div className="icon-content">
                    <h4 className="ttr-title">
                      {this.props.t('Send your details and preferences to us')}
                    </h4>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-sm-6 mb-4 mb-sm-3">
                <div className="feature-container feature-bx1 feature2">
                  <div className="icon-md">
                        <span className="icon-cell text-primary">
                          <Suspense>
                          <FontAwesomeIcon icon={solid('calculator')}/>
                          </Suspense>
                          </span>
                  </div>
                  <div className="icon-content">
                    <h4 className="ttr-title">
                      {this.props.t('Receive quotation(s) within 48 hours')}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 mb-4 mb-sm-3">
                <div className="feature-container feature-bx1 feature3">
                  <div className="icon-md">
                        <span className="icon-cell text-primary">
                          <Suspense>
                          <FontAwesomeIcon icon={solid('handshake')}/>
                          </Suspense>
                        </span>
                  </div>
                  <div className="icon-content">
                    <h4 className="ttr-title">
                      {this.props.t('Get received by us in India')}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 mb-4 mb-sm-3">
                <div className="feature-container feature-bx1 feature4">
                  <div className="icon-md">
                        <span className="icon-cell text-primary">
                          <Suspense>
                          <FontAwesomeIcon icon={solid('user-md')}/>
                          </Suspense>
                        </span>
                  </div>
                  <div className="icon-content">
                    <h4 className="ttr-title">
                      {this.props.t('Get treated and fly back')}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    );
  }
}

const WithTrans = withTranslation()(AboutUsShort);

export {WithTrans as AboutUsShort};