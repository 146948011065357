import {Component, ContextType, Suspense} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {WithTranslation, withTranslation} from 'react-i18next';

import './SearchByTreatmentCity.scss';

import {PageStatus} from '../../enums';
import {City, Treatment} from '../../API/interfaces';
import {TreatmentsAPI} from '../../API/TreatmentsAPI';
import {CitiesAPI} from '../../API/CitiesAPI';
import {Selectizer, SelectizerValue} from '../Selectizer';
import {Show} from '../Show';
import {extractStringFromHTML} from '../../utilities/helpers';
import {I18Context} from '../I18Context';
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

const DEFAULT_CITY = {label: 'All Cities', value: 'All'};
const DEFAULT_TREATMENT = {label: 'All Treatments', value: 'All'};

type Props = RouteComponentProps & WithTranslation;

type State = {
  status: PageStatus,
  treatments: Treatment[],
  treatment: SelectizerValue | null,
  cities: City[],
  city: SelectizerValue | null,
  error: string | null,
};

class SearchByTreatmentCity extends Component<Props, State> {
  static contextType = I18Context;
  context!: ContextType<typeof I18Context>;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      treatments: [],
      treatment: null,
      cities: [],
      city: {
        label: this.props.t(DEFAULT_CITY.label),
        value: DEFAULT_CITY.value,
      },
      error: null,
    };

    this.fetchItems = this.fetchItems.bind(this);
  }

  componentDidMount() {
    this.onMount();

    this.props.i18n.on('languageChanged', this.fetchItems);
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.fetchItems);
  }

  fetchItems(): void {
    if (!this.context.isInitialized) return;

    Promise.resolve()
        .then(() => {
          this.setState({
            status: PageStatus.Loading,
            city: {
              label: this.props.t(DEFAULT_CITY.label),
              value: DEFAULT_CITY.value,
            },
          });
        })
        .then(() => {
          return Promise.all([
            TreatmentsAPI.treatments({page: 1, limit: 1000}),
            CitiesAPI.cities(1, 1000),
          ]);
        })
        .then(([treatments, cities]) => {
          this.setState({
            cities,
            treatments,
            status: PageStatus.Loaded,
          });
        })
        .catch((err) => {
          this.setState({status: PageStatus.Error, error: err.message});
        });
  }

  onMount(): void {
    Promise.resolve()
        .then(() => {
          this.setState({
            status: PageStatus.Loading,
            city: {
              label: this.props.t(DEFAULT_CITY.label),
              value: DEFAULT_CITY.value,
            },
          });
        })
        .then(() => {
          return Promise.all([
            TreatmentsAPI.treatments({page: 1, limit: 1000}),
            CitiesAPI.cities(1, 1000),
          ]);
        })
        .then(([treatments, cities]) => {
          this.setState({
            cities,
            treatments,
            status: PageStatus.Loaded,
          });
        })
        .catch((err) => {
          this.setState({status: PageStatus.Error, error: err.message});
        });
  }

  treatmentsValues(): SelectizerValue[] {
    const treatments: SelectizerValue[] = [{
      label: this.props.t(DEFAULT_TREATMENT.label),
      value: DEFAULT_TREATMENT.value,
    }];
    for (let i = 0; i < this.state.treatments.length; i++) {
      const treatment = this.state.treatments[i];
      treatments.push({
        label: extractStringFromHTML(treatment.name),
        value: treatment.id,
      });
    }
    return treatments;
  }

  citiesValues(): SelectizerValue[] {
    const cities: SelectizerValue[] = [{
      label: this.props.t(DEFAULT_CITY.label),
      value: DEFAULT_CITY.value,
    }];
    for (let i = 0; i < this.state.cities.length; i++) {
      const city = this.state.cities[i];
      cities.push({
        label: extractStringFromHTML(city.city),
        value: city.id,
      });
    }
    return cities;
  }

  getLink(): string {
    return this.state.treatment?.value !== 'All' && !!this.state.treatment
        ? `/${this.props.i18n.language}/treatments/${this.state.treatment?.value}/cities/${this.state.city?.value}`
        : `/${this.props.i18n.language}/best-hospitals-and-doctors/cities/${this.state.city?.value}`;
  }

  isSelectizerDisabled(): boolean {
    return this.state.status === PageStatus.None
        || this.state.status === PageStatus.Error
        || this.state.treatments.length === 0
        || this.state.cities.length === 0;
  }

  isSearchButtonDisabled(): boolean {
    return this.state.status === PageStatus.None
        || this.state.status === PageStatus.Error
        || !this.state.treatment
        || !this.state.city;
  }

  render() {
    return (
        <div className="container search-by-treatment-city-shared">
          <div className="h2 mb-3">
            {this.props.t('Search by Treatments and Cities')}
          </div>

          <div className="row">
            <div className="col-md-6 mb-2">
              <Selectizer
                  onChange={(treatment) => {
                    this.setState({treatment});
                  }}
                  isDisabled={this.isSelectizerDisabled()}
                  isLoading={this.state.status === PageStatus.Loading}
                  options={this.treatmentsValues()}
                  value={this.state.treatment}
                  placeholder={this.props.t('Select treatment')}
              />
            </div>

            <div className="col-md-4 mb-2">
              <Selectizer
                  onChange={(city) => {
                    this.setState({city});
                  }}
                  isDisabled={this.isSelectizerDisabled()}
                  isLoading={this.state.status === PageStatus.Loading}
                  options={this.citiesValues()}
                  value={this.state.city}
                  placeholder={this.props.t('Select city')}
              />
            </div>

            <div className="col-md-2">
              <Show when={!this.state.error}>
                <button
                    disabled={this.isSearchButtonDisabled()}
                    className="btn btn-primary"
                    onClick={() => {
                      this.props.history.push(this.getLink());
                    }}
                >
                  <Suspense>
                    <FontAwesomeIcon icon={solid('search')} className="me-1"/>
                  </Suspense>
                  {this.props.t('Search')}
                </button>
              </Show>

              <Show when={!!this.state.error}>
                <button
                    className="btn btn-danger text-white"
                    onClick={() => {
                      this.fetchItems();
                    }}
                >
                  <Suspense>
                    <FontAwesomeIcon icon={solid('redo')} className="me-1"/>
                  </Suspense>
                  {this.props.t('Reload')}
                </button>
              </Show>
            </div>
          </div>
        </div>
    );
  }
}

const WithRouter = withRouter(SearchByTreatmentCity);

const WithTrans = withTranslation()(WithRouter);

export {WithTrans as SearchByTreatmentCity};

export default WithTrans;
