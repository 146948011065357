import { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { PageHeader } from '../../shared';

import { DisclaimerSub } from './DisclaimerSub';
import { CopyrightsSub } from './CopyrightsSub';
import { TermsOfUseSub } from './TermsOfUseSub';

type Props = WithTranslation;
type State = Record<never, never>;

class Disclaimer extends PureComponent<Props, State> {
  render() {
    return (
      <>
        <Helmet>
          <html lang={this.props.i18n.language} />
          <title>
            Alfplus Disclaimer | Alfplus
          </title>
          <meta
              name="description"
              content="Alfplus.com is wholly indulged in medical tourism. Therefore, all the services and products that the company offers are only for making the consumers aware. It is very important to educate the users about the medical services that may affect their health in many ways."
          />
        </Helmet>

        <div className="page-content bg-white disclaimer">
          <PageHeader title={this.props.t('Disclaimer')} />

          <section className="disclaimer-wrap">
            <div className="container">
              <div>
                <DisclaimerSub />

                <hr />

                <TermsOfUseSub />

                <hr />

                <CopyrightsSub />
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const WithTrans = withTranslation()(Disclaimer);

export { WithTrans as Disclaimer };

export default WithTrans;
