import { Component, ContextType } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { HospitalId } from '../../interfaces';
import { PageStatus } from '../../enums';
import { Doctor } from '../../API/interfaces';
import { DoctorsAPI } from '../../API/DoctorsAPI';
import { I18Context } from '../../shared/I18Context';

import { DoctorCardShortV2 } from './DoctorCardShortV2';

type Props = {
  hospitalId?: HospitalId
} & WithTranslation;

type State = {
  status: PageStatus,
  doctors: Doctor[],
  error: string | null,
};

class DoctorsListShort extends Component<Props, State> {
  static contextType = I18Context;
  context!: ContextType<typeof I18Context>;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      doctors: [],
      error: null,
    };

    this.fetchDoctors = this.fetchDoctors.bind(this);
  }

  componentDidMount() {
    this.fetchDoctorsInitial();

    this.props.i18n.on('languageChanged', this.fetchDoctors);
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.fetchDoctors);
  }

  fetchDoctorsInitial(): void {
    Promise.resolve()
      .then(() => {
        this.setState({ status: PageStatus.Loading });
      })
      .then(() => {
        return DoctorsAPI.doctors(
          {
            page: 1,
            limit: 1000,
            hospital: this.props.hospitalId,
          },
        );
      })
      .then((doctors) => {
        this.setState({ doctors, status: PageStatus.Loaded });
      })
      .catch((err) => {
        this.setState({ status: PageStatus.Error, error: err.message });
      });
  }

  fetchDoctors(): void {
    if (!this.context.isInitialized) return;

    Promise.resolve()
      .then(() => {
        this.setState({ status: PageStatus.Loading });
      })
      .then(() => {
        return DoctorsAPI.doctors(
          {
            page: 1,
            limit: 1000,
            hospital: this.props.hospitalId,
          },
        );
      })
      .then((doctors) => {
        this.setState({ doctors, status: PageStatus.Loaded });
      })
      .catch((err) => {
        this.setState({ status: PageStatus.Error, error: err.message });
      });
  }

  render() {
    return (
      <div className="row">
        {
          this.state.doctors.map((doctor) => (
            <div
              className="col-6 col-md-4 col-lg-3 mb-3"
              key={doctor.id}
            >
              <DoctorCardShortV2 doctor={{
                name: doctor.name,
                id: doctor.id as unknown as number,
                image: doctor.image as string,
                hospitalName: doctor.hospital_name.name,
                speciality: '',
              }}/>
            </div>
          ))
        }
      </div>
    );
  }
}

const WithTrans = withTranslation()(DoctorsListShort);

export { WithTrans as DoctorsListShort };
