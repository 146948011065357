export const hospitals_en = [
  {
    "id": 14,
    "name": "Jaypee Hospital",
    "city": "Noida - Greater Noida",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703945/Alfplus.com/Hospics/Jaypee_Hospital_Greater_Noida.jpg"
  },
  {
    "id": 95,
    "name": "Aakash Hospital",
    "city": "New Delhi - Delhi",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1613819899/Alfplus.com/Hospics/ResizedImage/akaash.jpg"
  },
  {
    "id": 12,
    "name": "Indraprastha Apollo Hospital",
    "city": "New Delhi - Delhi",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703943/Alfplus.com/Hospics/Indraprastha_Apollo_Hospital_New_Delhi.jpg"
  },
  {
    "id": 70,
    "name": "SevenHills Hospital",
    "city": "Mumbai",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703951/Alfplus.com/Hospics/SevenHills_Hospital_Mumbai.jpg"
  },
  {
    "id": 3,
    "name": "BLK-Max Super Specialty Hospital",
    "city": "New Delhi - Delhi",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703945/Alfplus.com/Hospics/BLK_Delhi.jpg"
  },
  {
    "id": 5,
    "name": "Dharamshila Cancer Hospital and Research Center",
    "city": "New Delhi - Delhi",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703947/Alfplus.com/Hospics/Dharamshila_Hospital_and_Research_center_New_Delhi.jpg"
  },
  {
    "id": 30,
    "name": "Metro Heart Institue and Multi Speciality Hospital",
    "city": "Faridabad",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1581100331/Alfplus.com/Hospics/ResizedImage/Metro_Heart_Institute_and_multi_speciality_hospital_Faridabad.jpg"
  },
  {
    "id": 91,
    "name": "Amrita Hospitals (AIMSRC)",
    "city": "Kochi - Kerala",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1615878604/Alfplus.com/Hospics/download_3.jpg"
  },
  {
    "id": 32,
    "name": "Artemis hospital",
    "city": "Gurgaon - Gurugram",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703943/Alfplus.com/Hospics/Artemis_Gurgaon.jpg"
  },
  {
    "id": 37,
    "name": "Medanta – The Medicity",
    "city": "Gurgaon - Gurugram",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703949/Alfplus.com/Hospics/Medanta_the_medicity_Gurgaon.jpg"
  }
];

export const hospitals_ar = [
  {
    "id": 14,
    "name": "مستشفى جايبي",
    "city": "نويدا - نويدا الكبرى",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703945/Alfplus.com/Hospics/Jaypee_Hospital_Greater_Noida.jpg"
  },
  {
    "id": 95,
    "name": "مستشفى عكاش",
    "city": "نيودلهي - دلهي",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1613819899/Alfplus.com/Hospics/ResizedImage/akaash.jpg"
  },
  {
    "id": 12,
    "name": "مستشفى إندرابراثا أبولو",
    "city": "نيودلهي - دلهي",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703943/Alfplus.com/Hospics/Indraprastha_Apollo_Hospital_New_Delhi.jpg"
  },
  {
    "id": 70,
    "name": "مستشفى سيفينهيلز",
    "city": "مومباي",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703951/Alfplus.com/Hospics/SevenHills_Hospital_Mumbai.jpg"
  },
  {
    "id": 3,
    "name": "مستشفى BLK-Max Super التخصصي",
    "city": "نيودلهي - دلهي",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703945/Alfplus.com/Hospics/BLK_Delhi.jpg"
  },
  {
    "id": 5,
    "name": "مستشفى ومركز أبحاث دارامشيلا للسرطان",
    "city": "نيودلهي - دلهي",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703947/Alfplus.com/Hospics/Dharamshila_Hospital_and_Research_center_New_Delhi.jpg"
  },
  {
    "id": 30,
    "name": "معهد مترو للقلب والمستشفى متعدد التخصصات",
    "city": "فريداباد",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1581100331/Alfplus.com/Hospics/ResizedImage/Metro_Heart_Institute_and_multi_speciality_hospital_Faridabad.jpg"
  },
  {
    "id": 91,
    "name": "مستشفيات أمريتا (AIMSRC)",
    "city": "كوتشي - كيرالا",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1615878604/Alfplus.com/Hospics/download_3.jpg"
  },
  {
    "id": 32,
    "name": "مستشفى أرتميس",
    "city": "جورجاون - جورجاون",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703943/Alfplus.com/Hospics/Artemis_Gurgaon.jpg"
  },
  {
    "id": 37,
    "name": "ميدانتا - الدواء",
    "city": "جورجاون - جورجاون",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703949/Alfplus.com/Hospics/Medanta_the_medicity_Gurgaon.jpg"
  }
];

export const hospitals_uz = [
  {
    "id": 14,
    "name": "Jaypee kasalxonasi",
    "city": "Noida - Buyuk Noida",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703945/Alfplus.com/Hospics/Jaypee_Hospital_Greater_Noida.jpg"
  },
  {
    "id": 95,
    "name": "Aakash kasalxonasi",
    "city": "Nyu-Dehli - Dehli",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1613819899/Alfplus.com/Hospics/ResizedImage/akaash.jpg"
  },
  {
    "id": 12,
    "name": "Indraprastha Apollon kasalxonasi",
    "city": "Nyu-Dehli - Dehli",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703943/Alfplus.com/Hospics/Indraprastha_Apollo_Hospital_New_Delhi.jpg"
  },
  {
    "id": 70,
    "name": "SevenHills kasalxonasi",
    "city": "Mumbay",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703951/Alfplus.com/Hospics/SevenHills_Hospital_Mumbai.jpg"
  },
  {
    "id": 3,
    "name": "BLK-Max Super maxsus shifoxonasi",
    "city": "Nyu-Dehli - Dehli",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703945/Alfplus.com/Hospics/BLK_Delhi.jpg"
  },
  {
    "id": 5,
    "name": "Dharamshila saraton kasalxonasi va tadqiqot markazi",
    "city": "Nyu-Dehli - Dehli",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703947/Alfplus.com/Hospics/Dharamshila_Hospital_and_Research_center_New_Delhi.jpg"
  },
  {
    "id": 30,
    "name": "Metro yurak instituti va ko'p ixtisoslashgan shifoxona",
    "city": "Faridobod",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1581100331/Alfplus.com/Hospics/ResizedImage/Metro_Heart_Institute_and_multi_speciality_hospital_Faridabad.jpg"
  },
  {
    "id": 91,
    "name": "Amrita kasalxonalari (AIMSRC)",
    "city": "Kochi - Kerala",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1615878604/Alfplus.com/Hospics/download_3.jpg"
  },
  {
    "id": 32,
    "name": "Artemis kasalxonasi",
    "city": "Gurgaon - Gurugram",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703943/Alfplus.com/Hospics/Artemis_Gurgaon.jpg"
  },
  {
    "id": 37,
    "name": "Medanta - Tibbiyot",
    "city": "Gurgaon - Gurugram",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1549703949/Alfplus.com/Hospics/Medanta_the_medicity_Gurgaon.jpg"
  }
];

export const doctors_en = [
  {
    "id": 1902,
    "name": "Prof. Dr. Mohamed Rela",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301128/Alfplus.com/doc_pics/dr_mohammed_rela.jpg",
    "speciality": "Gastroenterology - Surgical",
    "hospitalName": "Dr. Rela Institute and Medical Center"
  },
  {
    "id": 1297,
    "name": "DR. HARIT CHATURVEDI",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301128/Alfplus.com/doc_pics/dr_harit_chaturvedi.jpg",
    "speciality": "Surgical Oncology",
    "hospitalName": "Max Super Specialty Hospital Saket"
  },
  {
    "id": 2565,
    "name": "Dr. Aditya Gupta",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301128/Alfplus.com/doc_pics/dr._aditya_gupta.jpg",
    "speciality": "Brain Tumor Surgery",
    "hospitalName": "Artemis hospital"
  },
  {
    "id": 1221,
    "name": "Dr. Naresh Trehan",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301130/Alfplus.com/doc_pics/NARESH-TREHAN.jpg",
    "speciality": "Cardiac Surgery",
    "hospitalName": "Medanta – The Medicity"
  },
  {
    "id": 3265,
    "name": "Dr Meeraji Rao Dandangi",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301129/Alfplus.com/doc_pics/dr-meeraji-rao-.jpg",
    "speciality": "Cardiology Treatment",
    "hospitalName": "Continental hospital"
  },
  {
    "id": 2247,
    "name": "Dr. Rana Patir",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301130/Alfplus.com/doc_pics/rana_patir.jpg",
    "speciality": "Neurosurgery",
    "hospitalName": "Fortis Flt"
  },
  {
    "id": 2261,
    "name": "Dr. Ashok Seth",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301128/Alfplus.com/doc_pics/dr_ashok_seth.jpg",
    "speciality": "Cardiac Surgery",
    "hospitalName": "Fortis Escorts Heart Institute"
  }
];

export const doctors_uz = [
  {
    "id": 1902,
    "name": "Prof. Dr. Muhammad Rela",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301128/Alfplus.com/doc_pics/dr_mohammed_rela.jpg",
    "speciality": "Gastroenterologiya - Jarrohlik",
    "hospitalName": "Doktor Rela instituti va tibbiyot markazi"
  },
  {
    "id": 1297,
    "name": "DR. XARIT CHATURVEDI",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301128/Alfplus.com/doc_pics/dr_harit_chaturvedi.jpg",
    "speciality": "Jarrohlik onkologiyasi",
    "hospitalName": "Maks super maxsus kasalxona Saket"
  },
  {
    "id": 2565,
    "name": "Dr. Aditya Gupta",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301128/Alfplus.com/doc_pics/dr._aditya_gupta.jpg",
    "speciality": "Miya shishi jarrohligi",
    "hospitalName": "Artemis kasalxonasi"
  },
  {
    "id": 1221,
    "name": "Dr. Naresh Trehan",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301130/Alfplus.com/doc_pics/NARESH-TREHAN.jpg",
    "speciality": "Yurak jarrohligi",
    "hospitalName": "Medanta - Tibbiyot"
  },
  {
    "id": 3265,
    "name": "Doktor Meeraji Rao Dandangi",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301129/Alfplus.com/doc_pics/dr-meeraji-rao-.jpg",
    "speciality": "Kardiologiyani davolash",
    "hospitalName": "Kontinental kasalxona"
  },
  {
    "id": 2247,
    "name": "Dr. Ra'no Patir",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301130/Alfplus.com/doc_pics/rana_patir.jpg",
    "speciality": "Neyroxirurgiya",
    "hospitalName": "Fortis Flt"
  },
  {
    "id": 2261,
    "name": "Doktor Ashok Set",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301128/Alfplus.com/doc_pics/dr_ashok_seth.jpg",
    "speciality": "Yurak jarrohligi",
    "hospitalName": "Fortis Escorts yurak instituti"
  }
];

export const doctors_ar = [
  {
    "id": 1902,
    "name": "الأستاذ الدكتور محمد ريلا",
    "hospitalName": "معهد د. ريلا والمركز الطبي",
    "speciality": "أمراض الجهاز الهضمي - الجراحة ",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301128/Alfplus.com/doc_pics/dr_mohammed_rela.jpg"
  },
  {
    "id": 1297,
    "name": "الدكتور. هاريت تشاتورفيدي",
    "hospitalName": "مستشفى ماكس سوبر التخصصي ساكت",
    "speciality": "جراحة الأورام ",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301128/Alfplus.com/doc_pics/dr_harit_chaturvedi.jpg"
  },
  {
    "id": 2565,
    "name": "دكتور. أديتيا جوبتا",
    "hospitalName": "مستشفى أرتميس",
    "speciality": "جراحة أورام الدماغ ",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301128/Alfplus.com/doc_pics/dr._aditya_gupta.jpg"
  },
  {
    "id": 1221,
    "name": "دكتور. ناريش تريهان",
    "hospitalName": "ميدانتا - الدواء",
    "speciality": "جراحة القلب والصدر وزراعة القلب والأوعية الدموية.",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301130/Alfplus.com/doc_pics/NARESH-TREHAN.jpg"
  },
  {
    "id": 3265,
    "name": "الدكتور ميراجي راو داندانجي",
    "hospitalName": "مستشفى كونتيننتال",
    "speciality": "علاج أمراض القلب وجراحة القلب",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301129/Alfplus.com/doc_pics/dr-meeraji-rao-.jpg"
  },
  {
    "id": 2247,
    "name": "دكتور. رنا باتير",
    "hospitalName": "فورتيس Flt",
    "speciality": "جراحة المخ والأعصاب والعمود الفقري",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301130/Alfplus.com/doc_pics/rana_patir.jpg"
  },
  {
    "id": 2261,
    "name": "الدكتور أشوك سيث",
    "hospitalName": "معهد فورتيس إسكورتس للقلب",
    "speciality": "جراحة القلب ",
    "image": "https://res.cloudinary.com/alfplus-com/image/upload/v1552301128/Alfplus.com/doc_pics/dr_ashok_seth.jpg"
  }
];

export const specialities_en = [
  {
    "id": 14,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1550951054/Alfplus.com/icons/reproduction.png",
    "name": "Fertility treatment"
  },
  {
    "id": 7,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1550995688/Alfplus.com/icons/heart.png",
    "name": "Cardiac surgery"
  },
  {
    "id": 13,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1552198004/Alfplus.com/icons/eye.png",
    "name": "Eye surgery"
  },
  {
    "id": 28,
    "thumbnail": null,
    "name": "Vascular surgery"
  },
  {
    "id": 32,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1583393605/Alfplus.com/icons/neurosurgery1.png",
    "name": "Neurosurgery"
  },
  {
    "id": 24,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1550951054/Alfplus.com/icons/orthopedics.png",
    "name": "Orthopedic surgery"
  },
  {
    "id": 31,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1550951054/Alfplus.com/icons/urology.png",
    "name": "Urology treatment"
  },
  {
    "id": 6,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1552196872/Alfplus.com/icons/petri-dish.png",
    "name": "Cancer treatment"
  },
  {
    "id": 29,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1550951054/Alfplus.com/icons/lips.png",
    "name": "Cosmetic surgery"
  },
  {
    "id": 8,
    "thumbnail": null,
    "name": "Dental care"
  }
];

export const specialities_uz = [
  {
    "id": 14,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1550951054/Alfplus.com/icons/reproduction.png",
    "name": "Fertillikni davolash"
  },
  {
    "id": 7,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1550995688/Alfplus.com/icons/heart.png",
    "name": "Yurak jarrohligi"
  },
  {
    "id": 13,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1552198004/Alfplus.com/icons/eye.png",
    "name": "Ko'z jarrohligi"
  },
  {
    "id": 28,
    "thumbnail": null,
    "name": "Qon tomir jarrohligi"
  },
  {
    "id": 32,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1583393605/Alfplus.com/icons/neurosurgery1.png",
    "name": "Neyroxirurgiya"
  },
  {
    "id": 24,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1550951054/Alfplus.com/icons/orthopedics.png",
    "name": "Ortopedik jarrohlik"
  },
  {
    "id": 31,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1550951054/Alfplus.com/icons/urology.png",
    "name": "Urologiya bilan davolash"
  },
  {
    "id": 6,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1552196872/Alfplus.com/icons/petri-dish.png",
    "name": "Saraton kasalligini davolash"
  },
  {
    "id": 29,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1550951054/Alfplus.com/icons/lips.png",
    "name": "Kosmetik jarrohlik"
  },
  {
    "id": 8,
    "thumbnail": null,
    "name": "Tish parvarishi"
  }
];

export const specialities_ar = [
  {
    "id": 14,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1550951054/Alfplus.com/icons/reproduction.png",
    "name": "علاج العقم"
  },
  {
    "id": 7,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1550995688/Alfplus.com/icons/heart.png",
    "name": "جراحة القلب"
  },
  {
    "id": 13,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1552198004/Alfplus.com/icons/eye.png",
    "name": "جراحة العيون"
  },
  {
    "id": 28,
    "thumbnail": null,
    "name": "جراحة الأوعية الدموية"
  },
  {
    "id": 32,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1583393605/Alfplus.com/icons/neurosurgery1.png",
    "name": "جراحة الاعصاب"
  },
  {
    "id": 24,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1550951054/Alfplus.com/icons/orthopedics.png",
    "name": "جراحة العظام"
  },
  {
    "id": 31,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1550951054/Alfplus.com/icons/urology.png",
    "name": "علاج المسالك البولية"
  },
  {
    "id": 6,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1552196872/Alfplus.com/icons/petri-dish.png",
    "name": "معالجة السرطان"
  },
  {
    "id": 29,
    "thumbnail": "https://res.cloudinary.com/alfplus-com/image/upload/v1550951054/Alfplus.com/icons/lips.png",
    "name": "الجراحة التجميلية"
  },
  {
    "id": 8,
    "thumbnail": null,
    "name": "العناية بالأسنان"
  }
];

export const treatments_en = [
  {
    "id": 131,
    "cost": "5400",
    "name": "Cervical Cancer"
  },
  {
    "id": 174,
    "cost": "16000",
    "name": "Cochlear Implantation"
  },
  {
    "id": 317,
    "cost": "3600",
    "name": "In Vitro Fertilization(IVF)"
  },
  {
    "id": 59,
    "cost": "5750",
    "name": "Aortic Valve Replacement-Single"
  },
  {
    "id": 217,
    "cost": "5000",
    "name": "Elbow Replacement"
  },
  {
    "id": 373,
    "cost": "3500",
    "name": "Pacemaker Implantation Single"
  },
  {
    "id": 47,
    "cost": "7350",
    "name": "Ankle Arthroscopy"
  },
  {
    "id": 75,
    "cost": "5500",
    "name": "Artificial Spine Lumbar Disc Replacement"
  },
  {
    "id": 110,
    "cost": "18000",
    "name": "Bone Marrow Transplant-BMT"
  },
  {
    "id": 184,
    "cost": "4000",
    "name": "Coronary Angioplasty"
  },
  {
    "id": 88,
    "cost": "6300",
    "name": "Gastric Bypass Surgery"
  },
  {
    "id": 195,
    "cost": "6000",
    "name": "CyberKnife"
  },
  {
    "id": 322,
    "cost": "3900",
    "name": "Knee Arthroscopy Surgery"
  },
  {
    "id": 324,
    "cost": "5000",
    "name": "Knee Replacement Surgery"
  },
  {
    "id": 511,
    "cost": "8000",
    "name": "Shoulder Replacement Surgery"
  },
  {
    "id": 204,
    "cost": "5000",
    "name": "Atrial Septal Defect Closure-ASD"
  },
  {
    "id": 303,
    "cost": "3500",
    "name": "Hernia Repair Surgery"
  },
  {
    "id": 321,
    "cost": "11500",
    "name": "Kidney Transplant"
  },
  {
    "id": 359,
    "cost": "30000",
    "name": "Liver Transplant"
  },
  {
    "id": 439,
    "cost": "6000",
    "name": "Hip Replacement."
  },
  {
    "id": 273,
    "cost": "5000",
    "name": "Bypass Surgery-CABG"
  },
  {
    "id": 256,
    "cost": "5500",
    "name": "Gastric Banding Surgery"
  },
  {
    "id": 280,
    "cost": "55000",
    "name": "Heart Transplant"
  },
  {
    "id": 654,
    "cost": "6800",
    "name": "Spinal Cord Surgery"
  },
  {
    "id": 114,
    "cost": "5500",
    "name": "Brain Tumor Surgery"
  },
  {
    "id": 117,
    "cost": "2500",
    "name": "Breast Augmentation"
  }
];

export const treatments_uz = [
  {
    "id": 131,
    "cost": "5400",
    "name": "Bachadon bo'yni saratoni"
  },
  {
    "id": 174,
    "cost": "16000",
    "name": "Koxlear implantatsiya"
  },
  {
    "id": 317,
    "cost": "3600",
    "name": "In vitro urug'lantirish (IVF)"
  },
  {
    "id": 59,
    "cost": "5750",
    "name": "Aorta qopqog'ini almashtirish - bitta"
  },
  {
    "id": 217,
    "cost": "5000",
    "name": "Tirsakni almashtirish"
  },
  {
    "id": 373,
    "cost": "3500",
    "name": "Yagona yurak stimulyatori implantatsiyasi"
  },
  {
    "id": 47,
    "cost": "7350",
    "name": "Oyoq Bilagi zo'r artroskopiya"
  },
  {
    "id": 75,
    "cost": "5500",
    "name": "Sun'iy orqa miya lomber diskini almashtirish"
  },
  {
    "id": 110,
    "cost": "18000",
    "name": "Suyak iligi transplantatsiyasi - BMT"
  },
  {
    "id": 184,
    "cost": "4000",
    "name": "Koronar angioplastika"
  },
  {
    "id": 88,
    "cost": "6300",
    "name": "Gastrik bypass jarrohligi"
  },
  {
    "id": 195,
    "cost": "6000",
    "name": "CyberPnife"
  },
  {
    "id": 322,
    "cost": "3900",
    "name": "Tizza artroskopiyasi jarrohligi"
  },
  {
    "id": 324,
    "cost": "5000",
    "name": "Tizza almashtirish jarrohligi"
  },
  {
    "id": 511,
    "cost": "8000",
    "name": "Yelka almashtirish jarrohligi"
  },
  {
    "id": 204,
    "cost": "5000",
    "name": "Atriyal septal nuqsonning yopilishi-ASD"
  },
  {
    "id": 303,
    "cost": "3500",
    "name": "Herniyani tuzatish jarrohligi"
  },
  {
    "id": 321,
    "cost": "11500",
    "name": "Buyrak transplantatsiyasi"
  },
  {
    "id": 359,
    "cost": "30000",
    "name": "Jigar transplantatsiyasi"
  },
  {
    "id": 439,
    "cost": "6000",
    "name": "Kalçani almashtirish."
  },
  {
    "id": 273,
    "cost": "5000",
    "name": "Bypass jarrohlik - CABG"
  },
  {
    "id": 256,
    "cost": "5500",
    "name": "Oshqozon bandi jarrohligi"
  },
  {
    "id": 280,
    "cost": "55000",
    "name": "Yurak transplantatsiyasi"
  },
  {
    "id": 654,
    "cost": "6800",
    "name": "Orqa miya jarrohligi"
  },
  {
    "id": 114,
    "cost": "5500",
    "name": "Miya shishi jarrohligi"
  },
  {
    "id": 117,
    "cost": "2500",
    "name": "Ko'krakni kattalashtirish"
  }
];

export const treatments_ar = [
  {
    "id": 131,
    "cost": "5400",
    "name": "سرطان عنق الرحم"
  },
  {
    "id": 174,
    "cost": "16000",
    "name": "زراعة قوقعة الأذن"
  },
  {
    "id": 317,
    "cost": "3600",
    "name": "أطفال الأنابيب (IVF)"
  },
  {
    "id": 59,
    "cost": "5750",
    "name": "استبدال الصمام الأبهري - أحادي"
  },
  {
    "id": 217,
    "cost": "5000",
    "name": "استبدال الكوع"
  },
  {
    "id": 373,
    "cost": "3500",
    "name": "زرع منظم ضربات القلب مرة واحدة"
  },
  {
    "id": 47,
    "cost": "7350",
    "name": "تنظير مفصل الكاحل"
  },
  {
    "id": 75,
    "cost": "5500",
    "name": "استبدال القرص الفقري القطني الاصطناعي"
  },
  {
    "id": 110,
    "cost": "18000",
    "name": "زرع نخاع العظام- BMT"
  },
  {
    "id": 184,
    "cost": "4000",
    "name": "قسطرة الشريان التاجي"
  },
  {
    "id": 88,
    "cost": "6300",
    "name": "جراحة المجازة المعدية"
  },
  {
    "id": 195,
    "cost": "6000",
    "name": "CyberKnife"
  },
  {
    "id": 322,
    "cost": "3900",
    "name": "جراحة تنظير الركبة"
  },
  {
    "id": 324,
    "cost": "5000",
    "name": "جراحة استبدال الركبة"
  },
  {
    "id": 511,
    "cost": "8000",
    "name": "جراحة استبدال الكتف"
  },
  {
    "id": 204,
    "cost": "5000",
    "name": "إغلاق عيب الحاجز الأذيني- ASD"
  },
  {
    "id": 303,
    "cost": "3500",
    "name": "جراحة علاج الفتق"
  },
  {
    "id": 321,
    "cost": "11500",
    "name": "زرع الكلى"
  },
  {
    "id": 359,
    "cost": "30000",
    "name": "زراعة الكبد بالنقل"
  },
  {
    "id": 439,
    "cost": "6000",
    "name": "إستبدال الورك."
  },
  {
    "id": 273,
    "cost": "5000",
    "name": "جراحة تجاوز مسار الشريان التاجي"
  },
  {
    "id": 256,
    "cost": "5500",
    "name": "جراحة ربط المعدة"
  },
  {
    "id": 280,
    "cost": "55000",
    "name": "زرع قلب"
  },
  {
    "id": 654,
    "cost": "6800",
    "name": "جراحة الحبل الشوكي"
  },
  {
    "id": 114,
    "cost": "5500",
    "name": "جراحة ورم الدماغ"
  },
  {
    "id": 117,
    "cost": "2500",
    "name": "تكبير الثدي"
  }
];

export const testimonials_en = [
  {
    "id": 8,
    "treatment": "Liver Transplant at Artemis Hospital",
    "name": "Hussain Al-Hasnavi",
    "Message": "\"I want to thank whole AlfPlus' team and especially brother Irfan who guided us to the best Liver Transplant team at Artemis Hospital Gurgaon\"",
    "country": "Iraq"
  },
  {
    "id": 7,
    "treatment": "Knee Replacement from Artemis Hospital",
    "name": "Hazim Al-Harbi",
    "Message": "\"During my search on the internet for best Orthopedic doctor in Inida, i came across AlfPlus.com and when i chatted with team i was more happy for it's satisfying services that i got from them, because they chose for me only best options. Thank you\"",
    "country": "Kingdom of Saudia"
  },
  {
    "id": 10,
    "treatment": "Esophagus Surgery",
    "name": "Kawathrine Hussain",
    "Message": "Of course, Dr. Vishal Khurana at Metro hospital Faridabad, NCR, has been very kind to us all the way. He diagnosed the disease just after listening to us and advised some tests to be done so that the treatment or the surgery could be finalized with no delay. Then he performed the surgery very well Alhamdulillah and the whole processes went well by the grace of God. Finally, we appreciate AlfPlus team's efforts that they have chosen whatever good was for us, without their help and support, it could be difficult.",
    "country": "Iraq"
  },
  {
    "id": 9,
    "treatment": "Liver cirrhosis",
    "name": "Turki Shamakhi Lafta",
    "Message": "We thank God after our successful treatment for liver cirrhosis under Dr. Giriraj Singh Bora at Artemis Hospital in Gurugram NCR. The services offered by the hospital were absolutely excellent, and we genuinely got relief after meeting Dr. Bora when he encouraged us and provided treatment details in a very lucid manner. Now, we can not forget AlfPlus Medicals in this beautiful journey that started with their help only. We understand that travelling abroad is a difficult process but AlfPlus made this as easy as it could be. We are hugely indebted to the AlfPlus.",
    "country": "Iraq"
  },
  {
    "id": 6,
    "treatment": "Hip Replacement from Artemis Hospital",
    "name": "Yaomuthan",
    "Message": "“AlfPlus.com has highly professional team who worked for me day and night. Whenever I texted them or called them, they replied me very promptly within whiles, I am very satisfied with the way this platform is serving the needy patients. I am very thankful to the whole team for this best assistance ever in any foreign country and for choosing experienced doctor and best hospital”",
    "country": "Turkmenistan"
  },
  {
    "id": 5,
    "treatment": "Stem Cell Therapy for Spine at IBS Hospital",
    "name": "David",
    "Message": "“ Highly satisfying and speedy services that we got from IBS Hospital New Delhi, That coud be possible because of AlfPlus’ team who guided me in more than one way. A full time facilitator, daily follow-ups and simplifying every thing for me as patient was something needed that I got from AlfPlus.com ”",
    "country": "USA"
  },
  {
    "id": 3,
    "treatment": "Prostate Cancer at Metro Hospital NCR",
    "name": "Adebowale",
    "Message": "“ My son was Googling about prostate cancer treatment where he went through AlfPlus and he talked about the problem that I was suffering from and got very satisfying informations about the procedure, hospitals and doctors from the team and in very short time we started planning to see the doctor at Metro Hospital Faridabad NCR. We are really surprised to see the world class facilities in hospital in India and that is at reasonable price. We are highly pleased to choose AlfPlus.com ”",
    "country": "Nigeria"
  },
  {
    "id": 2,
    "treatment": "Liver Transplant at BLK Hospital Delhi",
    "name": "Abdullah Harim",
    "Message": "“I never thought that I would add a member to my family but when I got in touch with Nitin from AlfPlus.com, this happened and when he assisted in getting Visa, booking my ticket, receiving me at Delhi airport and making all arrangements during my stay in India made me feel at home. I am very impressed by the efforts of AlfPlus.com, and would recommend for others as well”",
    "country": "Kenya"
  }
];
