import { Component, ContextType, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WithTranslation, withTranslation } from 'react-i18next';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Show } from '../Show';
import { SpecialityId, TreatmentId } from '../../interfaces';
import { PageStatus } from '../../enums';
import { Treatment } from '../../API/interfaces';
import { I18Context } from '../I18Context';
import { TreatmentsAPI } from '../../API/TreatmentsAPI';

type Props = {
  specialityId: SpecialityId,
  currentId?: TreatmentId | null,
} & WithTranslation;

type State = {
  status: PageStatus,
  treatments: Treatment[],
  error: string | null,
};

class SimilarTreatments extends Component<Props, State> {
  context!: ContextType<typeof I18Context>;
  static contextType = I18Context;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      treatments: [],
      error: null,
    };

    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    this.fetchInitial();

    this.props.i18n.on('languageChanged', this.fetch);
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.fetch);
  }

  fetchInitial(): void {
    if (!this.props.specialityId) return;

    Promise.resolve()
      .then(() => {
        this.setState({ status: PageStatus.Loading });
      })
      .then(() => {
        return TreatmentsAPI.treatments({
          page: 1,
          limit: 11,
          specialityId: this.props.specialityId,
        });
      })
      .then((treatmentsRes) => {
        const treatments = treatmentsRes.filter((item) => {
          return item.id !== this.props.currentId && item.cost !== '0' && !!item.cost;
        });
        this.setState({ treatments, status: PageStatus.Loaded });
      })
      .catch((err) => {
        this.setState({ status: PageStatus.Error, error: err.message });
      });
  }

  fetch(): void {
    if (!this.context.isInitialized) return;
    if (!this.props.specialityId) return;

    Promise.resolve()
      .then(() => {
        this.setState({ status: PageStatus.Loading });
      })
      .then(() => {
        return TreatmentsAPI.treatments({
          page: 1,
          limit: 11,
          specialityId: this.props.specialityId,
        });
      })
      .then((treatmentsRes) => {
        const treatments = treatmentsRes.filter((item) => {
          return item.id !== this.props.currentId && item.cost !== '0' && !!item.cost;
        });
        this.setState({ treatments, status: PageStatus.Loaded });
      })
      .catch((err) => {
        this.setState({ status: PageStatus.Error, error: err.message });
      });
  }

  render() {
    return (
      <>
        <Show when={!!this.state.treatments.length}>
          <h3>
            {this.props.t('Similar Treatments')}
          </h3>

          <div className="service-menu row">
            {
              this.state.treatments.map((treatment) => (
                <div
                  key={treatment.id}
                  className="active col-lg-4 col-sm-6 col-12"
                >
                  <Link to={`/${this.props.i18n.language}/treatments/${treatment.id}`}>
                    <span className="me-2">{treatment.name} -</span>
                    <span className="price">
                      <Suspense>
                        <FontAwesomeIcon
                          icon={solid('dollar-sign')}
                          className="me-1"
                        />
                      </Suspense>
                      {treatment.cost}
                    </span>
                  </Link>
                </div>
              ))
            }
          </div>
        </Show>
      </>
    );
  }
}

const WithTrans = withTranslation()(SimilarTreatments);

export { WithTrans as SimilarTreatments };
