import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';

import './TestimonialSlider.scss';

import {Testimonial} from '../API/interfaces';
import {TestimonialCard} from './TestimonialCard';
import {testimonials_en} from "../utilities/constants";
import {Carousel} from "react-bootstrap";

type Props = {
  testimonials: Testimonial[],
} & WithTranslation;

type State = Record<string, never>;

class TestimonialSlider extends Component<Props, State> {
  render() {
    return (
        <>
          <section className="section-area section-sp3 testimonial-slider">
            <div className="container">
              <div className="heading-bx text-center">
                <h6 className="title-ext text-primary">
                  {this.props.t('Testimonials')}
                </h6>
              </div>

              <div className="">
                <Carousel className="hospitals-carousel" indicators={false}>
                  {
                    (testimonials_en as unknown as Testimonial[]).map((item) => (
                        <Carousel.Item>
                          <div className="col-12">
                            <TestimonialCard testimonial={item} short />
                          </div>
                        </Carousel.Item>
                    ))
                  }
                </Carousel>
              </div>
            </div>
          </section>
        </>
    );
  }
}

const WithTrans = withTranslation()(TestimonialSlider);

export {WithTrans as TestimonialSlider};

export default WithTrans;
