import { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import './DoctorCardShortV2.scss';

import doctorLogo from '../../images/doctor-logo.png';
import { LazyImageSuspense, Show } from '../../shared';

type Props = {
  doctor: {
    id: number,
    name: string,
    image: string,
    hospitalName: string,
    speciality: string,
  },
} & WithTranslation;

type State = Record<string, never>;

class DoctorCardShortV2 extends PureComponent<Props, State> {
  render() {
    return (
      <div className="doctor-card-short-v2">
        <div className="doctor-card-short-media">
          <Link
            to={`/${this.props.i18n.language}/doctors/${this.props.doctor.id}`}>
            <Show when={!this.props.doctor.image}>
              <LazyImageSuspense
                src={doctorLogo}
                alt={this.props.doctor.name}
                className=""
              />
            </Show>

            <Show when={!!this.props.doctor.image}>
              <LazyImageSuspense
                src={this.props.doctor.image || ''}
                alt={this.props.doctor.name}
                className=""
              />
            </Show>
          </Link>
        </div>

        <div className="doctor-card-short-title">
          <Link
            to={`/${this.props.i18n.language}/doctors/${this.props.doctor.id}`}>
            <div className="doctor-card-short-title-link">
              {this.props.doctor.name}
            </div>
          </Link>
        </div>

        <p className="doctor-card-short-hospital">
          {this.props.doctor.speciality}
        </p>
      </div>
    );
  }
}

const WithTrans = withTranslation()(DoctorCardShortV2);

export { WithTrans as DoctorCardShortV2 };
