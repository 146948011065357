import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Component, Suspense} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Alert, Spinner} from 'react-bootstrap';

import './MainSearch.scss';

import {Show} from '../Show';
import {Doctor, Hospital, Treatment} from '../../API/interfaces';
import {PageStatus} from '../../enums';
import {HospitalsAPI} from '../../API/HospitalsAPI';
import {TreatmentsAPI} from '../../API/TreatmentsAPI';
import {DoctorsAPI} from '../../API/DoctorsAPI';
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

type Props = RouteComponentProps;

type State = {
  status: PageStatus,
  isSearchFormVisible: boolean,
  text: string | null,
  hospitals: Hospital[],
  doctors: Doctor[],
  treatments: Treatment[],
  error: string | null,
};

class MainSearch extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      isSearchFormVisible: false,
      text: null,
      hospitals: [],
      doctors: [],
      treatments: [],
      error: null,
    };
  }

  async onSearch(): Promise<void> {
    try {
      this.setState({status: PageStatus.Loading});
      const hospitals = await HospitalsAPI.hospitals({
        page: 1,
        limit: 1000,
        name: this.state.text || undefined,
      });
      const treatments = await TreatmentsAPI.treatments({
        page: 1,
        limit: 1000,
        name: this.state.text || undefined,
      });
      const doctors = await DoctorsAPI.doctors({
        page: 1,
        limit: 1000,
        name: this.state.text || undefined,
      });
      this.setState({
        hospitals,
        treatments,
        doctors,
        status: PageStatus.Loaded,
      });
    } catch (err: any) {
      this.setState({error: err.message, status: PageStatus.Error});
    }
  }

  openSearchForm(): void {
    this.setState({isSearchFormVisible: true}, () => {
      document.body.style.overflowY = 'hidden';
    });
  }

  closeSearchForm(callback?: () => void): void {
    this.setState({
      isSearchFormVisible: false,
      text: null,
      treatments: [],
      hospitals: [],
      doctors: [],
      error: null,
      status: PageStatus.None,
    }, () => {
      document.body.style.overflowY = 'auto';
      if (callback) {
        callback();
      }
    });
  }

  isButtonDisabled(): boolean {
    return !this.state.text
        || this.state.text.length <= 3
        || this.state.status === PageStatus.Loading;
  }

  areItemsEmpty(): boolean {
    return this.state.treatments.length === 0
        && this.state.doctors.length === 0
        && this.state.hospitals.length === 0
        && this.state.status === PageStatus.None;
  }

  render() {
    return (
        <>
          <button
              id="quikSearchBtn"
              type="button"
              className="btn-link"
              onClick={() => {
                this.openSearchForm();
              }}
          >
            <i className="las la-search"/>
          </button>

          <Show when={this.state.isSearchFormVisible}>
            <div className="main-search-bar">
              <div className="form-container">
                <h1>Search for doctors, hospitals and treatments</h1>
                <div className="form-wrapper">
                  <form>
                    <input
                        type="text"
                        value={this.state.text || ''}
                        onChange={(e) => {
                          this.setState({text: e.target.value});
                        }}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                          }
                        }}
                        className="form-control"
                        placeholder="Type to search"
                    />
                  </form>

                  <button
                      className="btn btn-primary btn-sm btn-main-search ms-2"
                      disabled={this.isButtonDisabled()}
                      onClick={() => {
                        this.onSearch().then();
                      }}
                  >
                    <Suspense>
                      <FontAwesomeIcon icon={solid('search')} className="me-1"/>
                    </Suspense>
                    Search
                  </button>

                  <button
                      className="btn btn-danger close-button p-2 text-white"
                      onClick={() => {
                        this.closeSearchForm();
                      }}
                  >
                    <i className="ti-close"/>
                  </button>
                </div>
                <div className="text-warning text-13">
                  Type 4 or more letter to search
                </div>
              </div>

              <div className="search-container container">
                <div className="w-100 d-flex justify-content-center mt-3">
                  <h3>Search Results</h3>
                </div>

                <Show when={this.state.status === PageStatus.Loading}>
                  <div className="w-100 d-flex justify-content-center">
                    <Spinner animation="border"/>
                  </div>
                </Show>

                <Show when={this.areItemsEmpty()}>
                  <div className="w-100 d-flex justify-content-center">
                    Results will be shown here.
                  </div>
                </Show>

                <Alert
                    show={this.state.status === PageStatus.Error}
                    variant="danger"
                >
                  {this.state.error}
                </Alert>

                <div className="row">
                  <Show when={this.state.hospitals?.length > 0}>
                    <div className="col-md-4">
                      <h4>Hospitals</h4>
                      <ul className="d-flex flex-column align-items-start">
                        {
                          this.state.hospitals.map((hospital) => (
                              <li className="mb-2" key={hospital.id}>
                                <button
                                    className="btn bg-transparent p-0 text-primary"
                                    onClick={() => {
                                      this.closeSearchForm(
                                          () => {
                                            this.props.history.push(
                                                `/hospitals/${hospital.id}`,
                                            );
                                          },
                                      );
                                    }}
                                >
                                  {hospital.name} - {hospital.city.city}, India
                                </button>
                              </li>
                          ))
                        }
                      </ul>
                    </div>
                  </Show>

                  <Show when={this.state.treatments?.length > 0}>
                    <div className="col-md-4">
                      <h4>Treatments</h4>
                      <ul className="d-flex flex-column align-items-start list-unstyled">
                        {
                          this.state.treatments.map((treatment) => (
                              <li className="mb-2 w-100" key={treatment.id}>
                                <button
                                    className="btn btn-secondary w-100"
                                    onClick={() => {
                                      this.closeSearchForm(
                                          () => {
                                            this.props.history.push(
                                                `/treatments/${treatment.id}`,
                                            );
                                          },
                                      );
                                    }}
                                >
                                  {treatment.name}({treatment.parent.name})
                                </button>
                              </li>
                          ))
                        }
                      </ul>
                    </div>
                  </Show>

                  <Show when={this.state.doctors?.length > 0}>
                    <div className="col-md-4">
                      <h4>Doctors</h4>
                      <ul className="d-flex flex-column align-items-start">
                        {
                          this.state.doctors.map((doctor) => (
                              <li className="mb-2" key={doctor.id}>
                                <button
                                    className="btn bg-transparent p-0 text-primary"
                                    onClick={() => {
                                      this.closeSearchForm(
                                          () => {
                                            this.props.history.push(
                                                `/doctors/${doctor.id}`,
                                            );
                                          },
                                      );
                                    }}
                                >
                                  {doctor.name}
                                </button>
                              </li>
                          ))
                        }
                      </ul>
                    </div>
                  </Show>
                </div>
              </div>
            </div>
          </Show>
        </>
    );
  }
}

const MainSearchForm = withRouter(MainSearch);

export {MainSearchForm as MainSearch};
