import { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Language, PageStatus } from '../enums';

export const supportedLanguages = [Language.ar, Language.en, Language.uz];

type Props = RouteComponentProps & WithTranslation;

type State = {
  status: PageStatus,
  language: Language,
};

class Languages extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.Loaded,
      language: this.props.i18n.language as Language,
    };

    this.initialize = this.initialize.bind(this);
  }

  componentDidMount() {
    this.props.i18n.on('initialized', this.initialize);
  }

  componentWillUnmount() {
    this.props.i18n.off('initialized', this.initialize);
  }

  initialize() {
    this.setState({
      status: PageStatus.Loaded,
      language: this.props.i18n.language as Language,
    });
  }

  async onChange(language: Language): Promise<void> {
    try {
      this.setState({ status: PageStatus.Loading });
      await this.props.i18n.changeLanguage(language);
      this.replaceURL();
      this.setState({ status: PageStatus.Loaded, language });
    } catch (err: any) {
      this.setState({ status: PageStatus.Error });
    }
  }

  replaceURL(): void {
    const url: string[] = this.props.location.pathname.split('/');
    const path = this.props.history.location.pathname;
    const search = this.props.history.location.search;
    if (url[1].length === 2 && supportedLanguages.includes(url[1] as Language)) {
      history.replaceState(
        null,
        '',
        `/${this.props.i18n.language}${path.slice(3)}${search}`,
      );
    } else {
      history.replaceState(
        null,
        '',
        `/${this.props.i18n.language}${path}${search}`,
      );
    }
  }

  getText(lang: string): string {
    switch (lang) {
      case 'en': {
        return 'English';
      }
      case 'ar': {
        return 'العربية';
      }
      case 'uz': {
        return "O'zbek";
      }
      default: {
        return this.props.t('Select Languages');
      }
    }
  }

  render() {
    return (
      <>
        <Dropdown className="languages-wrapper" drop="up" id="language-selector">
          <Dropdown.Toggle
            size="sm"
            id="dropdown-basic"
            className="languages"
          >
            {this.getText(this.state.language)}
          </Dropdown.Toggle>

          <Dropdown.Menu className="language-selector-menu">
            <Dropdown.Item
              as="button"
              disabled={this.props.i18n.language === Language.en}
              onClick={() => {
                this.onChange(Language.en).then();
              }}
            >
              English (en)
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              disabled={this.props.i18n.language === Language.ar}
              onClick={() => {
                this.onChange(Language.ar).then();
              }}
            >
              العربية (ar)
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              disabled={this.props.i18n.language === Language.uz}
              onClick={() => {
                this.onChange(Language.uz).then();
              }}
            >
              O'zbek (uz)
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
}

const LanguagesWithRouter = withRouter(Languages);

const WithTrans = withTranslation()(LanguagesWithRouter);

export { WithTrans as Languages };
