import { PureComponent } from 'react';

import routes from '../routes';
import i18n from '../i18n';
import { Error404 } from "../modules/Common";

type Props = { pageStatus: number };
type State = Record<string, never>;

class LanguageCheck extends PureComponent<Props, State> {
  static getInitialProps({ match }) {
    console.log('route-failed');
    const splitRoute = match.url.split('/');
    const hasRoute = routes.find((item) => {
      return !!item.path && item.path.includes(match.url);
    });

    if (!hasRoute) {
      return { pageStatus: 404 };
    }
    if (hasRoute) {
      if (!!splitRoute.length && splitRoute.length < 2) {
        return { pageStatus: 404 };
      }
      if (splitRoute[1].length !== 2) {
        return {
          redirectTo: `/${i18n.language}${match.url}`,
        };
      }
      return { pageStatus: 404 };
    }
  }

  render() {
    if (this.props.pageStatus === 404 || this.props.pageStatus === undefined) {
      return (
          <Error404 />
      );
    }

    return (
      <div>
        Loading..
      </div>
    );
  }
}

export { LanguageCheck };

export default LanguageCheck;
