import { PureComponent } from 'react';

import { Hospital } from '../API/interfaces';
import { HospitalCardMain } from '../modules/Hospitals';

type Props = {
  hospitals: Hospital[],
  responsive?: any[]
};

type State = Record<string, never>;

class HospitalsSliderMain extends PureComponent<Props, State> {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 4000,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <>

        {/*<Slider*/}
        {/*  dots={false}*/}
        {/*  arrows*/}
        {/*  speed={500}*/}
        {/*  centerMode={false}*/}
        {/*  infinite*/}
        {/*  slidesToScroll={3}*/}
        {/*  slidesToShow={4}*/}
        {/*  responsive={this.props.responsive || [*/}
        {/*    {*/}
        {/*      breakpoint: 2500,*/}
        {/*      settings: this.props.hospitals.length > 5 ? {*/}
        {/*        slidesToShow: 4,*/}
        {/*        centerPadding: '30px',*/}
        {/*        slidesToScroll: 4,*/}
        {/*      } : 'unslick',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      breakpoint: 1919,*/}
        {/*      settings: this.props.hospitals.length > 4 ? {*/}
        {/*        slidesToShow: 4,*/}
        {/*        centerPadding: '30px',*/}
        {/*        slidesToScroll: 4,*/}
        {/*      } : 'unslick',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      breakpoint: 1450,*/}
        {/*      settings: this.props.hospitals.length > 3 ? {*/}
        {/*        slidesToShow: 3,*/}
        {/*        centerPadding: '30px',*/}
        {/*        slidesToScroll: 3,*/}
        {/*      } : 'unslick',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      breakpoint: 1099,*/}
        {/*      settings: this.props.hospitals.length >= 3 ? {*/}
        {/*        slidesToShow: 3,*/}
        {/*        centerPadding: '20px',*/}
        {/*        slidesToScroll: 3,*/}
        {/*      } : 'unslick',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      breakpoint: 1023,*/}
        {/*      settings: this.props.hospitals.length >= 3 ? {*/}
        {/*        slidesToShow: 2.5,*/}
        {/*        centerPadding: '20px',*/}
        {/*        slidesToScroll: 2,*/}
        {/*        autoplay: true,*/}
        {/*        autoplaySpeed: 4000,*/}
        {/*      } : 'unslick',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      breakpoint: 992,*/}
        {/*      settings: this.props.hospitals.length > 2 ? {*/}
        {/*        slidesToShow: 2,*/}
        {/*        centerPadding: '20px',*/}
        {/*        slidesToScroll: 2,*/}
        {/*        autoplay: true,*/}
        {/*        autoplaySpeed: 4000,*/}
        {/*      } : 'unslick',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      breakpoint: 768,*/}
        {/*      settings: this.props.hospitals.length > 2 ? {*/}
        {/*        slidesToShow: 2,*/}
        {/*        centerPadding: '20px',*/}
        {/*        slidesToScroll: 1,*/}
        {/*        autoplay: true,*/}
        {/*        autoplaySpeed: 4000,*/}
        {/*      } : 'unslick',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      breakpoint: 600,*/}
        {/*      settings: this.props.hospitals.length > 2 ? {*/}
        {/*        slidesToShow: 1.5,*/}
        {/*        centerPadding: '20px',*/}
        {/*        slidesToScroll: 1,*/}
        {/*        autoplay: true,*/}
        {/*        autoplaySpeed: 4000,*/}
        {/*      } : 'unslick',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      breakpoint: 450,*/}
        {/*      settings: this.props.hospitals.length > 1 ? {*/}
        {/*        slidesToShow: 1,*/}
        {/*        centerPadding: '20px',*/}
        {/*        slidesToScroll: 1,*/}
        {/*        autoplay: true,*/}
        {/*        autoplaySpeed: 4000,*/}
        {/*      } : 'unslick',*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*  className="tt-slider blog-slide slider-sp0 d-flex"*/}
        {/*>*/}
        {/*  {*/}
        {/*    this.props.hospitals.map((hospital) =>(*/}
        {/*      <div className="slider-item" key={hospital.id}>*/}
        {/*        <HospitalCardMain hospital={hospital}/>*/}
        {/*      </div>*/}
        {/*    ))}*/}
        {/*</Slider>*/}

        <div className="">
          <div className="row">
            {this.props.hospitals.map((hospital) => (
              <div
                className="col-12 col-md-6 col-lg-4 mb-3"
                key={hospital.id}
              >
                <HospitalCardMain hospital={hospital}/>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export { HospitalsSliderMain };
