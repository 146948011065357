import {asyncComponent} from "@jaredpalmer/after";
import {Loading} from "./shared/SuspenseWrapper";
import {LanguageCheck} from "./shared/LanguageCheck";

export default [
  {
    path: '/:lng',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Home/Home'),
      chunkName: 'Home',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/hospitals',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Hospitals/HospitalsWrapper'),
      chunkName: 'Hospitals',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/hospitals/:hospitalId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Hospitals/Details'),
      chunkName: 'HospitalDetails',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/doctors',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Doctors/DoctorsWrapper'),
      chunkName: 'Doctors',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/doctors/:doctorId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Doctors/Details'),
      chunkName: 'DoctorDetails',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/costs',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Costs/Costs'),
      chunkName: 'Costs',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/cost-of-treatments/:treatmentId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Costs/Details'),
      chunkName: 'CostDetails',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/specialities/:specialityId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Specialities/Details'),
      chunkName: 'SpecialityDetails',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/treatments/:treatmentId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Treatments/Details'),
      chunkName: 'TreatmentDetails',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/treatments/:treatmentId/cities/:city',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Treatments/DetailsWithCity'),
      chunkName: 'TreatmentDetailsWithCity',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/quotes/create',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Quotes/Create'),
      chunkName: 'QuotesCreate',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/best-doctors/specialities/:specialityId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/DoctorsWithSpeciality'),
      chunkName: 'DoctorsWithSpeciality',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/best-doctors/cities/:cityId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/DoctorsWithCity'),
      chunkName: 'DoctorsWithCity',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/best-doctors/specialities/:specialityId/cities/:cityId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/DoctorsWithCitySpeciality'),
      chunkName: 'DoctorsWithCitySpeciality',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/best-hospitals/specialities/:specialityId/cities/:cityId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/HospitalsWithCitySpeciality'),
      chunkName: 'HospitalsWithCitySpeciality',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/best-hospitals/specialities/:specialityId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/HospitalsWithSpeciality'),
      chunkName: 'HospitalsWithSpeciality',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/best-hospitals/cities/:cityId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/HospitalsWithCity'),
      chunkName: 'HospitalsWithCity',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/best-hospitals-and-doctors/cities/:cityId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/HospitalsAndDoctors'),
      chunkName: 'BestHospitalsAndDoctors',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/blogs',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Blogs/Blogs'),
      chunkName: 'Blogs',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/blogs/:blogId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Blogs/Details'),
      chunkName: 'BlogDetails',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/visa',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Visas/SelectVisa'),
      chunkName: 'Visas',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/visa/from/:fromCountryId/to/:toCountryId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Visas/Details'),
      chunkName: 'VisaDetails',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/about-us',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/AboutUs'),
      chunkName: 'AboutUs',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/testimonials',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Testimonials/Testimonials'),
      chunkName: 'Testimonials',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/testimonials/:testimonialId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Testimonials/Details'),
      chunkName: 'TestimonialDetails',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/cities-in-india',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/Cities'),
      chunkName: 'Cities',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/our-services',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/OurServices'),
      chunkName: 'OurServices',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/mission',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/Mission'),
      chunkName: 'Mission',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/disclaimer',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/Disclaimer'),
      chunkName: 'Disclaimer',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/terms-of-use',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/TermsOfUse'),
      chunkName: 'TermsOfUse',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/copyrights',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/Copyrights'),
      chunkName: 'Copyrights',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/legalities',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/Legalities'),
      chunkName: 'Legalities',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/partner-with-us',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/ContactUs'),
      chunkName: 'ContactUs',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/contact-us',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/ContactUs'),
      chunkName: 'ContactUs',
      Placeholder: Loading,
    }),
  },
  {
    path: '/:lng/team',
    exact: true,
    component: asyncComponent({
      loader: () => import('./modules/Common/Team'),
      chunkName: 'Team',
      Placeholder: Loading,
    }),
  },
  {
    component: LanguageCheck,
  },
];
