import {Component, ContextType} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Accordion, Alert, Spinner} from 'react-bootstrap';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';

import {PageStatus} from '../../enums';
import {Doctor} from '../../API/interfaces';
import {DoctorsAPI} from '../../API/DoctorsAPI';
import {DoctorId} from '../../interfaces';
import {getParam} from '../../utilities/helpers';
import {
  BookAppointmentLink,
  PageHeader,
  Show,
  WhatsappContact
} from '../../shared';
import {I18Context} from '../../shared/I18Context';
import {DoctorsSliderByCities} from "../../shared/Similars";
import {Quote} from "../Quotes";
import {BlogsSlider} from "../Blogs/BlogsSlider";

type Props = {
  doctor: Doctor | null,
} & RouteComponentProps<{
  doctorId: DoctorId,
}> & WithTranslation;

type State = {
  status: PageStatus,
  doctor: Doctor | null,
  error: string | null,
};

class Details extends Component<Props, State> {
  static contextType = I18Context;
  context!: ContextType<typeof I18Context>;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      doctor: this.props.doctor,
      error: null,
    };

    this.fetchDoctor = this.fetchDoctor.bind(this);
  }

  componentDidMount() {
    this.props.i18n.on('languageChanged', this.fetchDoctor);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.doctor?.id !== prevProps.doctor?.id) {
      this.setState({
        doctor: this.props.doctor,
      });
    }
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.fetchDoctor);
  }

  fetchDoctor(): void {
    if (!this.context.isInitialized) return;

    Promise.resolve()
        .then(() => {
          this.setState({status: PageStatus.Loading});
        })
        .then(() => {
          const doctorId = getParam<DoctorId>(this, 'doctorId');
          return DoctorsAPI.doctor(doctorId);
        })
        .then((doctor) => {
          this.setState({doctor, status: PageStatus.Loaded});
        })
        .catch((err) => {
          this.setState({status: PageStatus.Error, error: err.message});
        });
  }

  getFirstSpeciality(): string {
    const arr = this.state.doctor?.specialities?.split(', ')
    return arr?.length ? arr[0] : '';
  }

  getWhatsAppMessage(): string {
    return this.state.doctor
        ? `Hello, I want to contact, regarding ${this.state.doctor.name}-${this.state.doctor.hospital_name.name} https://www.alfplus.com/${this.props.i18n.language}/doctors/${this.state.doctor.id}, Thanks`
        : 'Hello, I have a query';
  }

  render() {
    return (
        <>
          <Helmet>
            <html lang={this.props.i18n.language}/>
            <title>
              {this.props.t(
                  'doctorTitle',
                  {
                    name: this.state.doctor?.name,
                    city: this.state.doctor?.hospital_name.name,
                  }
              )}
            </title>
            <meta
                name="description"
                content={this.props.t(
                    'doctorDescription',
                    {
                      name: this.state.doctor?.name,
                      position: this.state.doctor?.position,
                      experience: this.state.doctor?.experience,
                      city: this.state.doctor?.hospital_name.name,
                      speciality: this.getFirstSpeciality(),
                      hospital: this.state.doctor?.hospital_name.name,
                    }
                )}
            />
          </Helmet>

          <Show when={this.state.status === PageStatus.Loading}>
            <div className="d-flex justify-content-center mt-5">
              <Spinner animation="border"/>
            </div>
          </Show>

          <Alert show={this.state.status === PageStatus.Error}>
            {this.state.error}
          </Alert>

          <Show when={!!this.state.doctor}>
            <div className="page-content bg-white doctor-details">
              <PageHeader title={`${this.state.doctor?.name}`}/>

              <section className="section-area section-sp1">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8 mb-3">
                      <div className="clearfix">
                        <div className="head-text mb-3">
                          <h1 className="title mb-15">
                            {this.state.doctor?.name}
                          </h1>
                          <h6 className="mb-0">
                            {this.props.t(
                                '{{position}} with {{year}}+ years of experience',
                                {
                                  position: this.state.doctor?.position,
                                  year: this.state.doctor?.experience,
                                }
                            )}
                          </h6>

                          <hr/>

                          <p
                              className="mb-0"
                              dangerouslySetInnerHTML={{
                                __html: this.state.doctor?.about || '',
                              }}
                          />
                        </div>
                      </div>

                      <div className="clearfix">
                        <Accordion className="accordion ttr-accordion1">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              {this.props.t('Specialities')}
                            </Accordion.Header>
                            <Accordion.Body>
                              <ul className="row list-unstyled">
                                {
                                  this.state.doctor?.specialities?.split(', ').map((spec) => (
                                      <li
                                          className="col-md-3 col-lg-4"
                                          key={spec}
                                      >
                                        &bull; {spec}
                                      </li>
                                  ))
                                }
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <aside className="sticky-top pb-1">
                        <div className="widget">
                          <div>
                            <strong>
                              <u>{this.props.t('Hospital')}</u>:
                              {' '}
                            </strong>
                            <span
                                className="text-14"
                                dangerouslySetInnerHTML={{
                                  __html: this.state.doctor?.hospital_name.name || '',
                                }}
                            />
                          </div>
                          <div>
                            <strong>
                              <u>{this.props.t('Languages')}</u>:
                              {' '}
                            </strong>
                            <span className="text-14">
                            {this.state.doctor?.languages}
                          </span>
                          </div>

                          <hr/>

                          {!!this.state.doctor?.hospital_name.address && (
                              <div>
                                <strong><u>{this.props.t('Address')}</u>:
                                </strong>
                                <span className="text-14">
                                  {this.state.doctor?.hospital_name.address}
                                </span>
                              </div>
                          )}
                        </div>
                        <div className="widget">
                          <BookAppointmentLink
                              onClick={() => {
                                this.props.history.push(
                                    `/${this.props.i18n.language}/quotes/create?doctor=${this.state.doctor?.id}`,
                                );
                              }}
                          />
                        </div>

                        <div className="widget">
                          <WhatsappContact
                              size="fa-2x"
                              className="btn btn-danger-custom text-16 fw-bold d-inline-flex align-items-center py-1 px-3"
                              message={this.getWhatsAppMessage()}
                              showText
                          />
                        </div>
                      </aside>
                    </div>
                  </div>

                  <div className="similar-doctors mt-3">
                    <DoctorsSliderByCities
                        speciality={this.getFirstSpeciality()}
                        currentId={this.state.doctor?.id}
                    />
                  </div>

                  <Quote/>
                </div>

                <hr/>

                <BlogsSlider doctorId={this.state.doctor?.id}/>
              </section>
            </div>
          </Show>
        </>
    );
  }
}

const WithRouter = withRouter(Details);

const WithTrans = withTranslation()(WithRouter);

(WithTrans as any).getInitialProps = async function ({match}): Promise<any> {
  try {
    const doctor = await DoctorsAPI.doctor(match.params.doctorId);
    return {
      doctor,
    };
  } catch (err: any) {
    console.error(err);
  }
};

export {WithTrans as Details};

export default WithTrans;