import {Component, ContextType, createRef} from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';

import { PageStatus } from '../../enums';
import { Show } from '../Show';

import { PaginationContext } from './PaginationContext';

type Item = Record<string, any>;

type Props = {
  i18Initialized: boolean,
  items: Item[],

  onLoad: (page: number, limit: number) => Promise<Item[]>,
  onSet: (items: Item[]) => void,
} & WithTranslation;

type State = {
  status: PageStatus,
  error: string | null,
  previousY: number,
};

class InfiniteScroll extends Component<Props, State> {
  context!: ContextType<typeof PaginationContext>;
  static contextType = PaginationContext;

  observer: IntersectionObserver;
  ref = createRef<HTMLDivElement>();

  constructor(props) {
    super(props);

    this.state = {
      status: PageStatus.None,
      error: null,
      previousY: 0,
    };

    this.observer  = new IntersectionObserver(
      this.handleObserver.bind(this),
      {
        root: null,
        rootMargin: '0px',
        threshold: 1,
      },
    );
    this.fetch = this.fetch.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    if (this.ref.current) {
      this.observer.observe(this.ref.current);
    }

    this.context.emitter.on('PAGINATION', this.fetch);

    this.props.i18n.on('languageChanged', this.reset);

    this.context.setPage(1, true);
  }

  componentWillUnmount() {
    this.context.reset(true);
    this.context.emitter.off('PAGINATION', this.fetch);
    this.props.i18n.off('languageChanged', this.reset);
  }

  async handleObserver(
    entities: IntersectionObserverEntry[],
    observer: IntersectionObserver,
  ): Promise<void> {
    if (entities?.length) {
      if (entities[0].isIntersecting && this.state.previousY > entities[0].boundingClientRect.y) {
        this.context.setPage(this.context.page + 1);
      }
    }
    this.setState({
      previousY: entities[0].boundingClientRect.y,
    });
  }

  async fetch(): Promise<void> {
    try {
      this.setState({ status: PageStatus.Loading });
      const items = await this.props.onLoad(this.context.page, this.context.limit);
      if (this.context.page === 1) {
        this.props.onSet(items);
      } else {
        this.props.onSet([...this.props.items, ...items]);
      }
      if (!items.length) {
        this.context.setPage(this.context.page - 1, true);
      }
      this.setState({ status: PageStatus.Loaded });
    } catch (err: any) {
      this.setState({ status: PageStatus.Error, error: err.message });
    }
  }

  reset(): void {
    if (!this.props.i18Initialized) return;
    this.context.reset();
  }

  render() {
    return (
      <>
        <div
          ref={this.ref}
          style={{
            height: '150px',
            position: 'relative',
          }}
        >
          <Show when={this.state.status === PageStatus.Loading}>
            <div
              style={{
                position: 'absolute',
                width:'100%',
                display: 'flex',
                padding: '20px',
                justifyContent: 'center',
              }}
            >
              <Spinner  animation="border"/>
            </div>
          </Show>
        </div>

        <Alert show={this.state.status === PageStatus.Error} variant="danger">
          {this.state.error}
        </Alert>
      </>
    );
  }
}

const WithTrans = withTranslation()(InfiniteScroll);

export { WithTrans as InfiniteScroll };
