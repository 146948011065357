import { Component, ContextType } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import './Hospitals.scss';

import { PageHeader, Show } from '../../shared';
import { PageStatus } from '../../enums';
import { HospitalShort } from '../../API/interfaces';
import { HospitalsAPI } from '../../API/HospitalsAPI';
import {
  PaginationContext,
  PaginationWithTranslation,
} from '../../shared/Pagination';
import {
  FilterByCities,
  FilterBySpecialities,
  FiltersContext,
  FiltersMenu,
} from '../../shared/Filters';
import { Filters } from '../../shared/Filters/Filters';

import { HospitalCardMain } from './HospitalCardMain';

type Props = {
  i18Initialized: boolean,
  hospitals: HospitalShort[],
  count: number,
} & WithTranslation;

type State = {
  status: PageStatus,
  hospitals: HospitalShort[],
  error: string | null,
};

class Hospitals extends Component<Props, State> {
  static contextType = FiltersContext;
  context!: ContextType<typeof FiltersContext>;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      hospitals: this.props.hospitals,
      error: null,
    };

    this.fetchHospitals = this.fetchHospitals.bind(this);
  }

  fetchHospitals(page: number, limit: number): Promise<{
    data: HospitalShort[],
    count: number,
  }> {
    return Promise.resolve()
      .then(() => {
        return HospitalsAPI.hospitalsShortWithCount(
          {
            page,
            limit,
            city: this.context.city?.city,
            speciality: this.context.speciality?.name,
          },
        );
      });
  }

  render() {
    return (
      <>
        <Helmet>
          <html lang={this.props.i18n.language}/>
          <title>
            {this.props.t('hospitalsTitle')}
          </title>
          <meta
            name="description"
            content={this.props.t('hospitalsDescription')}
          />
        </Helmet>

        <div className="page-content bg-white hospitals">
          <PageHeader title={this.props.t('Best hospitals in India')}/>

          <Show when={this.state.status === PageStatus.Loading}>
            <div className="d-flex justify-content-center mt-5">
              <Spinner animation="border"/>
            </div>
          </Show>

          <Alert show={this.state.status === PageStatus.Error}>
            {this.state.error}
          </Alert>

          <section className="section-area section-sp1 hospitals-list">
            <div className="container">
              <h1>{this.props.t('Best hospitals in India')}</h1>

              <FiltersMenu/>

              <div className="row mb-5">
                <div className="col-lg-10 mb-3 g-0">
                  <div className="container">
                    <div className="row">
                      {
                        this.state.hospitals.map((hospital) => (
                          <div
                            className="col-12 col-md-6 col-xl-4 mb-3"
                            key={hospital.id}
                          >
                            <HospitalCardMain hospital={hospital}/>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>

                <div className="col-lg-2">
                  <PaginationContext.Consumer>
                    {
                      (pagination) => (
                        <Filters
                          onChange={() => {
                            pagination.reset();
                          }}
                        >
                          <FilterByCities
                            i18Initialized={this.props.i18Initialized}
                          />

                          <hr/>

                          <FilterBySpecialities
                            i18Initialized={this.props.i18Initialized}
                          />
                        </Filters>
                      )
                    }
                  </PaginationContext.Consumer>
                </div>
              </div>

              <PaginationWithTranslation
                i18Initialized={this.props.i18Initialized}
                items={this.state.hospitals}
                count={this.props.count}
                onSet={(hospitals) => {
                  this.setState({
                    hospitals: hospitals as HospitalShort[],
                  });
                }}
                onLoad={(page, limit) => {
                  return this.fetchHospitals(page, limit);
                }}
              />
            </div>
          </section>
        </div>
      </>
    );
  }
}

const WithTrans = withTranslation()(Hospitals);

export { WithTrans as Hospitals };
