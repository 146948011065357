import { Show } from './Show';
import { LazyImageSuspense } from './LazyImageSuspense';

export function AccreditationIcons(
  props: {
    acreditation: string | null,
  },
) {
  return <div className="d-flex align-items-center flex-wrap">
    <Show when={!!props.acreditation?.includes('NABH')}>
      <div style={{ height: '60px', width: '60px', margin: '2px' }}>
        <LazyImageSuspense
          className="w-100 h-100"
          src="https://res.cloudinary.com/alfplus-com/image/upload/v1677865971/Alfplus.com/icons/acreditation/resized/nabh.jpg"
          alt="NABH"
        />
      </div>
    </Show>

    <Show when={!!props.acreditation?.includes('NABL')}>
      <div style={{ height: '60px', width: '60px', margin: '2px' }}>
        <LazyImageSuspense
          className="w-100 h-100"
          src="https://res.cloudinary.com/alfplus-com/image/upload/v1677865971/Alfplus.com/icons/acreditation/resized/nabl.jpg"
          alt="NABL"
        />
      </div>
    </Show>

    <Show when={!!props.acreditation?.includes('JCI')}>
      <div style={{ height: '60px', width: '60px', margin: '2px' }}>
        <LazyImageSuspense
          className="w-100 h-100"
          src="https://res.cloudinary.com/alfplus-com/image/upload/v1677865971/Alfplus.com/icons/acreditation/resized/jci.jpg"
          alt="JCI"
        />
      </div>
    </Show>

    <Show when={!!props.acreditation?.includes('ISO')}>
      <div style={{ height: '60px', width: '60px', margin: '2px' }}>
        <LazyImageSuspense
          className="w-100 h-100"
          src="https://res.cloudinary.com/alfplus-com/image/upload/v1677865971/Alfplus.com/icons/acreditation/resized/iso.jpg"
          alt="ISO"
        />
      </div>
    </Show>

    <Show when={!!props.acreditation?.includes('UICC')}>
      <div style={{ height: '60px', width: '75px', margin: '2px' }}>
        <LazyImageSuspense
          className="w-100 h-100"
          src="https://res.cloudinary.com/alfplus-com/image/upload/v1677865971/Alfplus.com/icons/acreditation/resized/uicc.jpg"
          alt="UICC"
        />
      </div>
    </Show>
  </div>;
}