import { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Show } from '../../shared';
import { Language } from '../../enums';

/* eslint-disable max-len */

type Props = WithTranslation;
type State = Record<never, never>;

class DisclaimerSub extends PureComponent<Props, State> {
  render() {
    return (
      <>
        <h1>{this.props.t('Disclaimer')}</h1>

        <Show when={this.props.i18n.language === Language.en}>
          Alfplus.com is wholly indulged in medical tourism. Therefore, all the
          services and products that the company offers are only for making the
          consumers aware. It is very important to educate the users about the
          medical
          services that may affect their health in many ways. However, no results,
          content, diagnosis, information, or services are substitutes for the
          actual
          medical services. The company strictly expresses that these contents are
          not
          for any actual medical treatment.
          <br/>
          You must go through all the points in this legal Agreement very
          cautiously.
          Acknowledge what you read in Terms of Use for the website and put your
          trust
          in the &quot;Disclaimers&quot; made over here. These points are the
          basic and most crucial part of the Agreement. We present the Contract
          without any hidden objects and in an explicit way for all the users or
          visitors viewing the website. However, Alfplus.com does not give any
          warranty or representations for the benefit or limitations,
          merchantability, or quality of the content
          or expressions present. Every information contained herein should be
          construed as advice to guide the users and not a conclusive decision for
          medical treatment.
          <br/>
          The company also does not have responsibility for any kind of virus,
          unauthorized disclosure or intervention, bugs, technical failure, or
          theft of the information contained on the site. You cannot hold the
          company liable
          for any kind of loss of security, misrepresentation of facts, deletion,
          delay, or fairness connected with downloading any content. We process
          the transactions as far as the law makes it permissible for the company
          on this behalf. No suggestion or information on your part can be deemed
          to change any part of this disclaimer published by the company in any
          manner or will give rise to any warranty. We assume no responsibility,
          whether express or implied, for any kind of breach of Contract, error,
          omission, or negligence from any user of the website. We only have the
          security arrangements to stop
          any kind of sentimental attack, and you can complain about the same. The
          company will immediately take strict actions and suspend such users from
          accessing any of the objects of the website further.
          <br/>
          Furthermore, no action on this website is directed towards any
          diagnosis, professional advice for medication, nursing, or professional
          treatment. You can only get some useful pieces of advice from our
          medical experts and receive quotes for the same if required.
          But we advise you to take suggestions from your physician before
          taking or following any of the advice
          seriously. Our professionals conduct the medical tours only to make you
          aware of certain points. But they never claim to be responsible for any
          action on your part due to such suggestions. Not everyone has the exact
          needs. Therefore, get the best quotes depending on your specific needs
          from
          our medical professionals. When it is a matter of personal healthcare,
          we will always recommend proper advice from the physician. No
          compromise must be made regarding your health. If you notice any
          serious problem, please contact us on immediate. We will provide
          accurate medical help from qualified doctors in this field. You can
          also get solutions from specialized
          medical experts on payment of the required amount. We accept
          installments only after payment of the down payment at the time of
          initial booking. Our company can arrange for a personal appointment
          with the doctor also. At present, we are providing the services in
          India only and will soon expand in
          other parts too.
        </Show>

        <Show when={this.props.i18n.language === Language.ar}>
          إن موقع ألف بلس ميديكلس منغمس بالكامل في السياحة العلاجية. لذلك ، فإن جميع
          الخدمات والمنتجات التي تقدمها الشركة هي فقط لتوعية المستهلكين. من المهم
          جدًا توعية المستخدمين بالخدمات الطبية التي قد تؤثر على صحتهم بعدة طرق. ومع
          ذلك ، لا توجد نتائج أو محتوى أو تشخيص أو معلومات أو خدمات هي بدائل للخدمات
          الطبية الفعلية. تعرب الشركة بدقة عن أن هذه المحتويات ليست مخصصة لأي علاج
          طبي فعلي.
          يجب عليك مراجعة جميع النقاط الواردة في هذه الاتفاقية القانونية بحذر شديد.
          أقر بما قرأته في شروط الاستخدام الخاصة بالموقع وثق في "إخلاء المسؤولية"
          الوارد هنا. هذه النقاط هي الجزء الأساسي والأكثر أهمية من الاتفاقية. نقدم
          العقد بدون أي أشياء مخفية وبطريقة صريحة لجميع المستخدمين أو الزوار الذين
          يتصفحون الموقع. ومع ذلك ، لا يقدم ألف بلس ميديكلس أي ضمانات أو إقرارات
          لفائدة
          أو قيود أو قابلية تسويق أو جودة المحتوى أو التعبيرات الموجودة. يجب تفسير
          كل المعلومات الواردة هنا على أنها نصيحة لتوجيه المستخدمين وليست قرارًا
          قاطعًا بشأن العلاج الطبي.
          <br/>
          لا تتحمل الشركة أيضًا أي مسؤولية عن أي نوع من الفيروسات أو الكشف أو التدخل
          غير المصرح به أو الأخطاء أو الأعطال الفنية أو سرقة المعلومات الواردة في
          الموقع. لا يمكنك تحميل الشركة المسؤولية عن أي نوع من فقدان الأمان أو تحريف
          الحقائق أو الحذف أو التأخير أو الإنصاف المرتبط بتنزيل أي محتوى. نقوم
          بمعالجة المعاملات بقدر ما يسمح القانون للشركة نيابة عن ذلك. لا يمكن اعتبار
          أي اقتراح أو معلومات من جانبك لتغيير أي جزء من إخلاء المسؤولية هذا الذي
          تنشره الشركة بأي شكل من الأشكال أو يؤدي إلى أي ضمان. نحن لا نتحمل أي
          مسؤولية ، سواء كانت صريحة أو ضمنية ، عن أي نوع من خرق العقد أو الخطأ أو
          السهو أو الإهمال من أي مستخدم للموقع. لدينا فقط الترتيبات الأمنية لوقف أي
          نوع من الاعتداء العاطفي ، ويمكنك تقديم شكوى بشأن نفس الشيء. ستتخذ الشركة
          على الفور إجراءات صارمة وتوقف هؤلاء المستخدمين عن الوصول إلى أي من كائنات
          الموقع بشكل أكبر.
          <br/>
          علاوة على ذلك ، لا يتم توجيه أي إجراء على هذا الموقع تجاه أي تشخيص أو
          نصيحة مهنية للأدوية أو التمريض أو العلاج المهني. يمكنك فقط الحصول على بعض
          النصائح المفيدة من خبرائنا الطبيين والحصول على عروض أسعار لها إذا لزم
          الأمر. لكننا ننصحك بأخذ اقتراحات طبيبك قبل أخذ أو اتباع أي من النصائح على
          محمل الجد. يقوم المتخصصون لدينا بإجراء الجولات الطبية فقط لإطلاعك على نقاط
          معينة. لكنهم لا يزعمون أبدًا أنهم مسؤولون عن أي إجراء من جانبك بسبب هذه
          الاقتراحات. ليس كل شخص لديه الاحتياجات الدقيقة. لذلك ، احصل على أفضل
          الأسعار بناءً على احتياجاتك الخاصة من المتخصصين الطبيين لدينا. عندما يتعلق
          الأمر بالرعاية الصحية الشخصية ، فإننا نوصي دائمًا بالنصيحة المناسبة من
          الطبيب. لا يجب تقديم أي تنازلات فيما يتعلق بصحتك. إذا لاحظت أي مشكلة خطيرة
          ، يرجى الاتصال بنا على الفور. سوف نقدم مساعدة طبية دقيقة من الأطباء
          المؤهلين في هذا المجال. يمكنك أيضًا الحصول على حلول من خبراء طبيين متخصصين
          عند دفع المبلغ المطلوب. لا نقبل الأقساط إلا بعد دفع الدفعة الأولى في وقت
          الحجز الأولي. يمكن لشركتنا ترتيب موعد شخصي مع الطبيب أيضًا. في الوقت
          الحالي ، نقدم الخدمات في الهند فقط وسنتوسع قريبًا في أجزاء أخرى أيضًا.
          <br/>
          لا تتوقع ما يلي
          <br/>
          نحن هنا فقط لتوفير الوصول للمستخدمين الذين يسعون للحصول على مساعدة طبية
          جادة. بصرف النظر عن هذا ، فإن ألف بلس ميديكلس لا يشجع أو يوصي أو يوافق على
          محتوى أي مزود آخر ، باستثناء الممارسين الطبيين المؤهلين والمعاهد المعتمدة.
          الشركة ليست مؤسسة طبية في حد ذاتها. يتم إرسال الدفعة التي تقوم بها عبر
          موقعنا الإلكتروني إلى المتخصصين المعتمدين الذين يعملون وفقًا لترخيص المجلس
          الطبي الهندي.
          لا يمكننا التحكم في جميع المنشورات التي ينشرها المستخدمون على موقعنا. لا
          يوفر ألف بلس ميديكلس أيضًا ضمانًا فيما يتعلق بدقة أي معاملة أو بيانات كما
          تم
          بواسطة المزود. لا يمكن لأي شخص تحميل شركتنا المسؤولية بأي شكل من الأشكال
          عن الإجراء أو المعاملة التي يقوم بها أي طرف.
        </Show>

        <Show when={this.props.i18n.language === Language.uz}>
          Alfplus.com butunlay tibbiy turizm bilan shug&apos;ullanadi. Shunday qilib,
          kompaniya taklif qilayotgan barcha xizmatlar va mahsulotlar faqat
          iste&apos;molchilarni xabardor qilish uchun. Foydalanuvchilarga sog&apos;lig&apos;iga
          ko&apos;p jihatdan ta&apos;sir ko&apos;rsatishi mumkin bo&apos;lgan tibbiy xizmatlar
          to&apos;g&apos;risida ma&apos;lumot berish juda muhimdir. Biroq, hech qanday natija,
          tarkib, tashxis, ma&apos;lumot yoki xizmatlar haqiqiy tibbiy xizmatlarning
          o&apos;rnini bosa olmaydi. Kompaniya ushbu tarkib hech qanday tibbiy davolanish
          uchun mo&apos;ljallanmaganligini qat&apos;iy bildiradi.
          <br/>
          Siz ushbu qonuniy shartnomaning barcha bandlarini juda ehtiyotkorlik bilan
          o&apos;tishingiz kerak. Veb -sayt uchun Foydalanish shartlarida
          o&apos;qiganlaringizni tan oling va bu erda berilgan &quot;Ogohlantirishlar&quot; ga
          ishoning. Bu bandlar Shartnomaning asosiy va eng muhim qismi hisoblanadi.
          Biz Shartnomani hech qanday yashirin ob&apos;ektlarsiz va veb -saytni
          ko&apos;rayotgan barcha foydalanuvchilar yoki tashrif buyuruvchilar uchun ochiq
          tarzda taqdim etamiz. Biroq, Alfplus.com foyda yoki cheklovlar, sotish
          qobiliyati yoki mavjud tarkib yoki iboralarning sifati uchun hech qanday
          kafolat bermaydi. Bu erda mavjud bo&apos;lgan har bir ma&apos;lumot
          foydalanuvchilarga ko&apos;rsatma sifatida tushunilishi kerak va davolanish
          to&apos;g&apos;risida qat&apos;iy qaror emas.
          <br/>
          Kompaniya, shuningdek, har qanday virus, ruxsatsiz oshkor qilish yoki
          aralashish, xatolar, texnik nosozliklar yoki saytdagi ma&apos;lumotlarni
          o&apos;g&apos;irlash uchun javobgarlikni o&apos;z zimmasiga olmaydi. Siz kompaniyaning
          xavfsizligini yo&apos;qotishi, faktlarni noto&apos;g&apos;ri ko&apos;rsatishi, o&apos;chirilishi,
          kechiktirilishi yoki har qanday tarkibni yuklab olish bilan bog&apos;liq
          adolati uchun javobgar bo&apos;lolmaysiz. Biz tranzaktsiyalarni qonun nomidan
          kompaniyaga ruxsat bergan darajada qayta ishlaymiz. Siz tomondan hech
          qanday taklif yoki ma&apos;lumot kompaniya tomonidan e&apos;lon qilingan rad
          etishning biron bir qismini hech qanday tarzda o&apos;zgartirishi mumkin emas
          yoki hech qanday kafolat bermaydi. Biz har qanday Shartnomani buzish,
          xato, harakatsizlik yoki veb -sayt foydalanuvchisining beparvoligi uchun
          aniq yoki bilvosita javobgarlikni o&apos;z zimmamizga olmaymiz. Bizda har
          qanday sentimental hujumni to&apos;xtatish uchun xavfsizlik choralari bor va
          siz bu haqda shikoyat qilishingiz mumkin. Kompaniya zudlik bilan qat&apos;iy
          choralar ko&apos;radi va bunday foydalanuvchilarga veb -saytning boshqa
          ob&apos;ektlariga kirishni to&apos;xtatadi.
          <br/>
          Bundan tashqari, ushbu veb -saytda hech qanday tashxis qo&apos;yish, dori
          -darmonlar, hamshiralik ishlari yoki professional davolanish bo&apos;yicha
          professional maslahatlar ko&apos;rsatilmagan. Agar kerak bo&apos;lsa, siz faqat
          bizning tibbiy mutaxassislarimizdan foydali maslahatlar olishingiz va
          shunga o&apos;xshash takliflarni olishingiz mumkin. Ammo biz sizga biron bir
          maslahatni jiddiy qabul qilishdan yoki unga amal qilishdan oldin,
          shifokorning tavsiyalarini qabul qilishni maslahat beramiz. Bizning
          mutaxassislarimiz tibbiy sayohatlarni faqat sizga ma&apos;lum jihatlarni bilish
          uchun o&apos;tkazadilar. Ammo ular bunday takliflar tufayli hech qachon sizning
          tarafingizdan qilingan har qanday harakat uchun javobgarlikni da&apos;vo
          qilmaydi. Hamma ham aniq ehtiyojlarga ega emas. Shuning uchun, bizning
          tibbiy mutaxassislarimizdan sizning ehtiyojlaringizga qarab, eng yaxshi
          narxlarni oling. Agar shaxsiy sog&apos;liqni saqlash masalasi bo&apos;lsa, biz har
          doim shifokorning to&apos;g&apos;ri maslahatini tavsiya qilamiz. Sizning
          sog&apos;lig&apos;ingiz haqida hech qanday murosaga kelmaslik kerak. Agar biron bir
          jiddiy muammoni sezsangiz, darhol biz bilan bog&apos;laning. Biz bu sohada
          malakali shifokorlardan aniq tibbiy yordam ko&apos;rsatamiz. Siz, shuningdek,
          ixtisoslashtirilgan tibbiyot mutaxassislaridan kerakli miqdorni to&apos;lash
          bo&apos;yicha echimlarni olishingiz mumkin. To&apos;lovlarni faqat dastlabki
          bronlash vaqtida dastlabki to&apos;lov to&apos;langanidan keyin qabul qilamiz.
          Bizning kompaniyamiz shifokor bilan shaxsiy uchrashuvni ham tashkil
          qilishi mumkin. Hozirda biz xizmatlarni faqat Hindistonda ko&apos;rsatmoqdamiz
          va tez orada boshqa hududlarda ham kengaytiramiz.
        </Show>
      </>
    );
  }
}

const WithTrans = withTranslation()(DisclaimerSub);

export { WithTrans as DisclaimerSub };
