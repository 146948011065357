import { EventEmitter } from 'events';

import { Component, ReactElement } from 'react';

import { City, Speciality } from '../../API/interfaces';

import { FiltersContext } from './FiltersContext';

type Props = {
  children: ReactElement,
};

type State = {
  city: City | null,
  speciality: Speciality | null,
  show: boolean,
  emitter: EventEmitter,

  onSpeciality: (speciality: Speciality | null) => void,
  onCity: (city: City | null) => void,
  onToggle: () => void,
  onReset: () => void,
};

class FiltersProvider extends Component<Props, State> {
  emitter = new EventEmitter();

  constructor(props) {
    super(props);
    this.state = {
      city: null,
      speciality: null,
      show: false,
      emitter: this.emitter,

      onSpeciality: this.onSpeciality.bind(this),
      onCity: this.onCity.bind(this),
      onToggle: this.onToggle.bind(this),
      onReset: this.onReset.bind(this),
    };
    this.emitter.setMaxListeners(1000);
  }

  componentDidMount() {
    this.onReset(true);
  }

  componentWillUnmount() {
    this.emitter.removeAllListeners('FILTER');
  }

  onSpeciality(speciality: Speciality | null, silent?: boolean): void {
    this.setState({ speciality }, () => {
      if (!silent) {
        this.emitter.emit(
          'FILTER',
          {
            city: this.state.city,
            speciality: this.state.speciality,
          },
        );
      }
    });
  }

  onCity(city: City | null, silent?: boolean): void {
    this.setState({ city }, () => {
      if (!silent) {
        this.emitter.emit(
          'FILTER',
          {
            city: this.state.city,
            speciality: this.state.speciality,
          },
        );
      }
    });
  }

  onToggle(): void {
    this.setState((state) => {
      return {
        show: !state.show,
      };
    });
  }

  onReset(silent?: boolean): void {
    this.setState({
      city: null,
      speciality: null,
    }, () => {
      if (!silent) {
        this.emitter.emit(
          'FILTER',
          {
            city: this.state.city,
            speciality: this.state.speciality,
          },
        );
      }
    });
  }

  render() {
    return (
      <FiltersContext.Provider value={this.state}>
        {this.props.children}
      </FiltersContext.Provider>
    );
  }
}

export { FiltersProvider };
