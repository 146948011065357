import get from 'lodash/get';

import { api } from '../axiosConfig';
import { treatmentsURL } from '../utilities/urls';
import { injectPageNumber, Paginated } from '../utilities/tools';
import { SpecialityId, TreatmentId } from '../interfaces';

import { Treatment } from './interfaces';

export class TreatmentsAPI {
  static treatments(
    params: {
      page: number,
      limit: number,
      specialityId?: SpecialityId,
      name?: string,
    },
  ): Promise<Paginated<Treatment>[] | never> {
    return api.get(`${treatmentsURL}/`, {
      timeout: 0,
      params: {
        page: params.page,
        limit: params.limit,
        name: params.name,
        speciality: params.specialityId,
      },
    })
      .then((response) => {
        const data = get(response, ['data', 'results'], null);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return data as Treatment[];
      })
      .then(injectPageNumber(params.page, params.limit));
  }

  static treatment(
    treatmentId: TreatmentId,
  ): Promise<Treatment | never> {
    return api.get(`${treatmentsURL}/${treatmentId}/`)
      .then((response) => {
        const data = get(response, ['data'], null);
        if (!data) {
          throw new Error('NO_DATA');
        }
        return data;
      });
  }
}