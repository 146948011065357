import { CSSProperties, useEffect, useRef, useState } from 'react';

import loadingImg from 'images/loading.jpg';

type Props = {
  className: string,
  src: string,
  alt?: string,
  style?: CSSProperties,
};

export function LazyImage(props: Props) {
  const ref = useRef<HTMLImageElement | null>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const disposer = initialize();

    return () => {
      disposer();
    };
  }, []);

  function getSrc(): string {
    return loaded ? props.src : loadingImg;
  }

  function initialize(): () => void {
    if ('IntersectionObserver' in window) {
      const lazyImageObserver = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting && !loaded) {
            const lazyImage: any = entry.target;
            setLoaded(true);
            lazyImage.classList.remove('lazy');
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      });

      if (ref?.current) {
        lazyImageObserver.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          lazyImageObserver.unobserve(ref.current);
        }
      };
    }

    return () => null;
  }

  return (
    <img
      className={props.className}
      ref={ref}
      src={getSrc()}
      alt={props.alt}
      style={props.style}
    />
  );
}

export default LazyImage;
