import {Component, ContextType} from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { PageHeader, Show } from '../../shared';
import { PageStatus } from '../../enums';
import { City } from '../../API/interfaces';
import { CitiesAPI } from '../../API/CitiesAPI';
import { I18Context } from '../../shared/I18Context';

type Props = {
  cities: City[],
} & WithTranslation;

type State = {
  status: PageStatus,
  cities: City[],
  error: string | null,
};

class Cities extends Component<Props, State> {
  context!: ContextType<typeof I18Context>;
  static contextType = I18Context;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      cities: this.props.cities || [],
      error: null,
    };
    this.fetchCities = this.fetchCities.bind(this);
  }

  componentDidMount() {
    this.props.i18n.on('languageChanged', this.fetchCities);
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.fetchCities);
  }

  fetchCities(): void {
    if (!this.context.isInitialized) return;

    Promise.resolve()
      .then(() => {
        this.setState({ status: PageStatus.Loading });
      })
      .then(() => {
        return CitiesAPI.cities(1, 1000);
      })
      .then((cities) => {
        this.setState({ cities, status: PageStatus.Loaded });
      })
      .catch((err) => {
        this.setState({ status: PageStatus.Error, error: err.message });
      });
  }

  render(){
    return (
      <>
        <Helmet>
          <html lang={this.props.i18n.language} />
          <title>
            {this.props.t('citiesTitle')}
          </title>
          <meta
              name="description"
              content={this.props.t('citiesDescription')}
          />
        </Helmet>

        <div className="page-content bg-white">
          <PageHeader title={this.props.t('Cities in India')} />

          <Show when={this.state.status === PageStatus.Loading}>
            <div className="d-flex justify-content-center mt-5">
              <Spinner animation="border" />
            </div>
          </Show>

          <Alert show={this.state.status === PageStatus.Error}>
            {this.state.error}
          </Alert>

          <section className="section-area section-sp1 cities-list">
            <div className="container">
              <h1>
                {this.props.t('Cities in India')}
              </h1>

              <div className="p-5 rounded">
                <div className="row gx-0">
                  {
                    this.state.cities.map((city) => (
                      <div className="col-md-6 h6" key={city.id}>
                        <Link
                          to={`/${this.props.i18n.language}/best-hospitals-and-doctors/cities/${city.id}`}
                        >
                          &bull; {city.city}
                        </Link>
                      </div>
                    ))
                  }

                  <Alert
                    variant="warning"
                    show={this.state.cities.length === 0}
                  >
                    {this.props.t('No cities')}.
                  </Alert>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const WithTrans = withTranslation()(Cities);

(WithTrans as any).getInitialProps = async function (): Promise<any> {
  try {
    const cities = await CitiesAPI.cities(1, 1000);
    return {
      cities,
    };
  } catch (err: any) {
    console.error(err);
  }
};

export { WithTrans as Cities };

export default WithTrans;
