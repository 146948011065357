import { PureComponent } from 'react';

import { QuoteForm } from './QuoteForm';

type Props = Record<string, never>;
type State = Record<string, never>;

import './Quotes.scss';

class Quote extends PureComponent<Props, State> {
  render() {
    return (
      <>
        <section className="section-area account-wraper1 pt-5">
          <div className="container-fluid">
            <div
              className="appointment-inner section-sp2"
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-12">
                    <QuoteForm />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </>
    );
  }
}

export { Quote };
