import { Component, Suspense } from 'react';
import {
  Field,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from 'redux-form';
import { Alert, Modal, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WithTranslation, withTranslation } from 'react-i18next';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { PageStatus } from '../../enums';
import { CountryField } from '../../shared/CountryField';
import { QuotesAPI } from '../../API/QuotesAPI';

import { Show } from 'shared';
import { emailValidate, phoneValidate } from 'utilities/helpers';

type FormValue = {
  name: string,
  phone: string,
  email: string,
  message: string,
  country: string,
};

type State = {
  status: PageStatus,
  showModal: boolean,
  countryCode: string | null,
  countryName: string | null,
  countryNameCode: string | null,
  error: string | null,
};

type PropsFromParent = Record<never, never>;
type Props = PropsFromParent
& WithTranslation
& InjectedFormProps<FormValue, PropsFromParent>;

class QuoteForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      showModal: false,
      countryCode: '',
      countryName: '',
      countryNameCode: '',
      error: null,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values: FormValue): Promise<void | never> {
    this.formValidate(values);
    return this.submit(values);
  }

  formValidate(values: FormValue): void | never {
    if (!values.name) {
      throw new SubmissionError({
        _error: this.props.t('Please fill name'),
      });
    }
    if (values.name?.length <= 3) {
      throw new SubmissionError({
        _error: this.props.t('Name too short'),
      });
    }
    if (!values.message) {
      throw new SubmissionError({
        _error: this.props.t('Please enter message'),
      });
    }
    if (values.message?.length <= 30) {
      throw new SubmissionError({
        _error: this.props.t('Message too short'),
      });
    }
    if (!phoneValidate(values.phone)) {
      throw new SubmissionError({
        _error: this.props.t('Please enter correct phone number'),
      });
    }
    if (!emailValidate(values.email)) {
      throw new SubmissionError({
        _error: this.props.t('Please enter correct email'),
      });
    }
  }

  submit(values: FormValue): Promise<void> {
    return Promise.resolve()
      .then(() => {
        this.setState({ status: PageStatus.Loading });
      })
      .then(() => {
        const data = {
          patient_name: values.name,
          user_name: values.name,
          phone_code: values.country,
          phone: values.phone,
          email: values.email,
          country: `${this.state.countryName} (${this.state.countryNameCode})`,
          message: values.message,
        };
        return QuotesAPI.create(data);
      })
      .then(() => {
        this.setState({
          status: PageStatus.Loaded,
          countryName: '',
          countryNameCode: '',
          countryCode: '',
          showModal: true,
        }, () => {
          this.props.reset();
        });
      })
      .catch((err) => {
        this.setState({ status: PageStatus.Error, error: err.message });
      });
  }

  render() {
    return (
      <>
        <div className="appointment-form form-wraper">
          <div className="title h5">
            {this.props.t('quoteFormDescription')}
          </div>

          <form
            className="position-relative"
            onSubmit={this.props.handleSubmit(this.onSubmit)}
          >
            <Show when={this.state.status === PageStatus.Loading}>
              <div
                className="position-absolute w-100 h-100 bg-gray"
                style={{ zIndex: 1000 }}
              >
                <Spinner animation="grow" variant="primary"/>
              </div>
            </Show>

            <div className="form-group">
              <label htmlFor="name" className="text-black fw-bolder text-15">
                {this.props.t('Name')}<code>*</code>
              </label>
              <Field
                component="input"
                className="form-control"
                id="name"
                name="name"
                placeholder={this.props.t('Enter patient name')}
                disabled={this.props.submitting}
                required
              />
            </div>

            <CountryField
              onSelect={(name, code, nameCode) => {
                this.setState({
                  countryName: name,
                  countryCode: code,
                  countryNameCode: nameCode,
                });
              }}
              disabled={this.props.submitting}
            />

            <div className="form-group">
              <label htmlFor="phone" className="text-black fw-bolder text-15">
                {this.props.t('Phone')}<code>*</code>
              </label>
              <div className="input-group">
                <Show when={!!this.state.countryCode}>
                  {/* eslint-disable-next-line max-len */}
                  <span
                    className="input-group-text bg-warning text-white fst-italic"
                  >
                  +{this.state.countryCode}
                  </span>
                </Show>
                <Field
                  component="input"
                  className="form-control"
                  id="phone"
                  name="phone"
                  placeholder={this.props.t('Enter your phone number')}
                  disabled={this.props.submitting}
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="email" className="text-black fw-bolder text-15">
                {this.props.t('Email')}<code>*</code>
              </label>
              <Field
                component="input"
                className="form-control"
                id="email"
                name="email"
                placeholder={this.props.t('Enter your email')}
                disabled={this.props.submitting}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="message"
                className="text-black fw-bolder text-15">
                {this.props.t('Message')}<code>*</code>
              </label>
              <Field
                component="textarea"
                className="form-control"
                id="message"
                name="message"
                placeholder={this.props.t('Enter your message')}
                disabled={this.props.submitting}
                required
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary btn-lg shadow"
              disabled={this.props.submitting || this.props.pristine}
            >
              <Show
                when={
                  this.props.submitting
                    || this.state.status === PageStatus.Loading
                }
              >
                <Spinner animation="grow" size="sm"/>
              </Show>

              <Show when={!this.props.submitting}>
                <Suspense>
                  <FontAwesomeIcon icon={solid('envelope')} className="me-2"/>
                </Suspense>
              </Show>
              {this.props.t('Submit')}
            </button>

            <Alert show={this.state.status === PageStatus.Error}>
              {this.state.error}
            </Alert>

            <Alert show={!!this.props.error}>
              {this.props.error}
            </Alert>
          </form>
        </div>

        <Modal
          centered
          show={this.state.showModal}
          onHide={() => {
            this.setState({ showModal: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.t('Quote Received')}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {this.props.t('Please check your email for updates')}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const ReduxForm = reduxForm<FormValue, PropsFromParent>({
  form: 'quotesForm',
})(QuoteForm);

const WithTrans = withTranslation()(ReduxForm);

export { WithTrans as QuoteForm };
