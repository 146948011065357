import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Show} from "./Show";
import {WithTranslation, withTranslation} from "react-i18next";
import {brands} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Suspense} from "react";

function WhatsappContact(
    props: {
      className: string,
      message?: string,
      showText?: boolean,
      size?: string,
    } & WithTranslation
) {
  function getWhatsAppMessage(): string {
    return !!props.message
        ? props.message
        : 'Hello, I have a query';
  }

  return (
      <a
          href={`whatsapp://send?phone=+919990085860&text=${getWhatsAppMessage()}`}
          className={props.className}
      >
        <Suspense>
          <FontAwesomeIcon
              icon={brands('whatsapp')}
              className={`${props.size} fa-whatsapp-color`}
          />
        </Suspense>
        <Show when={!!props.showText}>
          <span className="text-white fw-bolder ms-2">
            {props.t('Whatsapp us')}
          </span>
        </Show>
      </a>
  )
}

WhatsappContact.defaultProps = {
  size: 'fa-3x',
};

const WithTrans = withTranslation()(WhatsappContact);

export {WithTrans as WhatsappContact};
