import EventEmitter from 'events';

import { City, Speciality } from '../../API/interfaces';
import {createContext} from "react";

const FiltersContext = createContext<{
  speciality: Speciality | null,
  city: City | null,
  show: boolean,
  emitter: EventEmitter,

  onSpeciality: (speciality: Speciality | null, silent?: boolean) => void,
  onCity: (city: City | null, silent?: boolean) => void,
  onToggle: () => void,
  onReset: (silent?: boolean) => void,
}>(
    {
      speciality: null,
      city: null,
      show: false,
      emitter: new EventEmitter(),

      onCity: () => null,
      onSpeciality: () => null,
      onReset: () => null,
      onToggle: () => null,
    },
    );

export { FiltersContext };