import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { Language } from './enums';

export const options: InitOptions = {
  ns: ['translation'],
  defaultNS: 'translation',
  debug: true,
  react: {
    useSuspense: false,
  },
  supportedLngs: [Language.en, Language.ar, Language.uz],
  fallbackLng: Language.en,
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ['path', 'querystring', 'cookie', 'header'],
  },
};

if (process && !process.release) {
  i18n.use(Backend).use(initReactI18next).use(LanguageDetector);
}

if (!i18n.isInitialized) {
  console.log('initialized-client');
  i18n.init(options);
}


export default i18n;