import {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import {WithTranslation, withTranslation} from 'react-i18next';

import {Doctor} from 'API/interfaces';

type Props = {
  doctor: Doctor,
} & WithTranslation;

type State = Record<string, never>;

class DoctorCardShort extends PureComponent<Props, State> {
  render() {
    const position = this.props.doctor.position;
    const hospital = this.props.doctor.hospital_name.name || 'hospital';
    const experience = this.props.doctor.experience;

    return (
        <div className="feature-container feature-bx2 feature1 doctor-card">
          <div className="feature-box-xl">
          <span className="icon-cell">
            <i className="fa fa-user-md w-100"/>
          </span>
          </div>

          <div className="icon-content">
            <Link
                to={`/${this.props.i18n.language}/doctors/${this.props.doctor.id}`}>
              <div className="ttr-title">
                {this.props.doctor.name}
              </div>
            </Link>

            <p className="description">
              {this.props.t(
                  '{{position}} at {{hospital}} with {{experience}}+ years of experience',
                  {
                    position,
                    hospital,
                    experience,
                  }
              )}
            </p>
          </div>
        </div>
    );
  }
}

const WithTrans = withTranslation()(DoctorCardShort);

export {WithTrans as DoctorCardShort};
