import { EventEmitter } from 'events';

import { Component, ReactElement, ContextType } from 'react';

import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  PaginationContext,
  TPaginationContext,
} from './PaginationContext';

type Props = {
  children: ReactElement,
};

class PaginationProvider extends Component<Props, TPaginationContext> {
  context!: ContextType<typeof PaginationContext>;
  static contextType = PaginationContext;

  emitter = new EventEmitter();

  constructor(props) {
    super(props);
    this.state = {
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT,
      emitter: this.emitter,
      setLimit: this.setLimit.bind(this),
      setPage: this.setPage.bind(this),
      reset: this.reset.bind(this),
    };
    this.emitter.setMaxListeners(1000);
  }

  componentWillUnmount() {
    this.emitter.removeAllListeners('PAGINATION');
  }

  setLimit(limit: number, silent = false): void {
    this.setState({ limit }, () => {
      if (!silent) {
        this.emitter.emit(
          'PAGINATION',
          this.state.page,
          this.state.limit,
        );
      }
    });
  }

  setPage(page: number, silent = false): void {
    this.setState({ page }, () => {
      if (!silent) {
        this.emitter.emit(
          'PAGINATION',
          this.state.page,
          this.state.limit,
        );
      }
    });
  }

  reset(silent = false) {
    this.setState({
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT,
    }, () => {
      if (!silent) {
        this.emitter.emit(
          'PAGINATION',
          this.state.page,
          this.state.limit,
        );
      }
    });
  }

  render() {
    return (
      <PaginationContext.Provider value={this.state}>
        {this.props.children}
      </PaginationContext.Provider>
    );
  }
}

export { PaginationProvider };
