import {Component, ContextType} from 'react';
import {Alert, Spinner} from 'react-bootstrap';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';

import {PageHeader, Show} from '../../shared';
import {PageStatus} from '../../enums';
import {CityShort, Doctor, DoctorShort} from '../../API/interfaces';
import {getParam} from '../../utilities/helpers';
import {DoctorsAPI} from '../../API/DoctorsAPI';
import {DoctorCard} from '../Doctors';
import {I18Context} from '../../shared/I18Context';
import {Quote} from "../Quotes";
import {CitiesAPI} from "../../API/CitiesAPI";
import {CityId} from "../../interfaces";

type Props = {
  doctors: DoctorShort[],
  city: CityShort | null,
} & RouteComponentProps<{
  cityId?: string,
}> & WithTranslation;

type State = {
  status: PageStatus,
  doctors: DoctorShort[],
  city: CityShort | null,
  error: string | null,
};

class DoctorsWithCity extends Component<Props, State> {
  static contextType = I18Context;
  context!: ContextType<typeof I18Context>;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      city: this.props.city,
      doctors: this.props.doctors || [],
      error: null,
    };

    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    this.props.i18n.on('languageChanged', this.fetch);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.city?.id !== prevProps.city?.id) {
      this.setState({
        city: this.props.city,
        doctors: this.props.doctors,
      });
    }
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.fetch);
  }

  fetch(): void {
    if (!this.context.isInitialized) return;

    Promise.resolve()
        .then(() => {
          this.setState({status: PageStatus.Loading});
        })
        .then(() => {
          const cityId = getParam<CityId>(this, 'cityId');

          return Promise.all([
            CitiesAPI.cityShort(cityId),
            DoctorsAPI.doctorsShort({
              page: 1,
              limit: 15,
              cityId,
            }),
          ]);
        })
        .then(([city, doctors]) => {
          this.setState({
            doctors,
            city,
            status: PageStatus.Loaded,
          });
        })
        .catch((err) => {
          this.setState({status: PageStatus.Error, error: err.message});
        });
  }

  render() {
    const city = this.state.city?.city;

    return (
        <>
          <Helmet>
            <html lang={this.props.i18n.language}/>
            <title>
              {this.props.t(
                  'bestDoctorsTitle3',
                  {city},
              )}
            </title>
            <meta
                name="description"
                content={this.props.t(
                    'bestDoctorsDescription3',
                    {city},
                )}
            />
          </Helmet>

          <div className="page-content bg-white best-hospitals-and-doctors">
            <PageHeader title={this.props.t('Best doctors in India')}/>

            <Show when={this.state.status === PageStatus.Loading}>
              <div className="d-flex justify-content-center mt-5">
                <Spinner animation="border"/>
              </div>
            </Show>

            <Alert show={this.state.status === PageStatus.Error}>
              {this.state.error}
            </Alert>

            <section className="section-area section-sp1 hospitals-list">
              <div className="container">
                <Show when={this.state.doctors.length > 0}>

                  <div className="mt-5 doctors-list">
                    <h1 className="ms-2">
                      {this.props.t(
                          'Best doctors in {{city}}, India',
                          {city},
                      )}
                    </h1>

                    <div className="row">
                      {
                        this.state.doctors.map((doctor) => (
                            <div className="col-12 col-md-4 col-lg-3 mb-3">
                              <DoctorCard
                                  doctor={doctor as Doctor}
                                  key={doctor.id}
                              />
                            </div>
                        ))
                      }
                    </div>
                  </div>
                </Show>
              </div>

              <Quote/>
            </section>
          </div>
        </>
    );
  }
}

const WithRouter = withRouter(DoctorsWithCity);

const WithTrans = withTranslation()(WithRouter);

(WithTrans as any).getInitialProps = async function (
    {match},
): Promise<any> {
  try {
    let city: CityShort | null = null;
    if (match.params.city !== 'All') {
      city = await CitiesAPI.cityShort(match.params.cityId);
    }
    const doctors = await DoctorsAPI.doctorsShort({
      page: 1,
      limit: 15,
      cityId: match.params.city === 'All' ? undefined : match.params.cityId,
    });
    return {
      city,
      doctors,
    };

  } catch (err: any) {
    console.error(err);
  }
};

export {WithTrans as DoctorsWithCity};

export default WithTrans;
