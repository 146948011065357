import { Component, ContextType } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import './Doctors.scss';

import { PageHeader, Show } from '../../shared';
import { PageStatus } from '../../enums';
import { Doctor } from '../../API/interfaces';
import { DoctorsAPI } from '../../API/DoctorsAPI';
import {
  PaginationContext,
  PaginationWithTranslation,
} from '../../shared/Pagination';
import {
  FilterBySpecialities,
  FiltersContext,
  FiltersMenu,
} from '../../shared/Filters';
import { Filters } from '../../shared/Filters/Filters';
import { Paginated } from '../../utilities/tools';

import { DoctorCardWithDetails } from './DoctorCardWithDetails';

type Props = {
  i18Initialized: boolean,
  doctors: Doctor[],
  count: number,
} & WithTranslation;

type State = {
  status: PageStatus,
  doctors: Doctor[],
  error: string | null,
};

class Doctors extends Component<Props, State> {
  static contextType = FiltersContext;
  context!: ContextType<typeof FiltersContext>;

  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      doctors: this.props.doctors,
      error: null,
    };
    this.fetchDoctors = this.fetchDoctors.bind(this);
  }

  fetchDoctors(page: number, limit: number): Promise<{
    data: Paginated<Doctor>[],
    count: number,
  }> {
    return Promise.resolve()
      .then(() => {
        return DoctorsAPI.doctorsWithCount(
          {
            page,
            limit,
            speciality: this.context.speciality?.name,
          },
        );
      });
  }

  render() {
    return (
      <>
        <Helmet>
          <html lang={this.props.i18n.language}/>
          <title>
            {this.props.t('doctorsTitle')}
          </title>
          <meta
            name="description"
            content={this.props.t('doctorsDescription')}
          />
        </Helmet>

        <div className="page-content bg-white doctors">
          <PageHeader title={this.props.t('Best doctors in India')}/>

          <Show when={this.state.status === PageStatus.Loading}>
            <div className="d-flex justify-content-center mt-5">
              <Spinner animation="border"/>
            </div>
          </Show>

          <Alert show={this.state.status === PageStatus.Error}>
            {this.state.error}
          </Alert>

          <section className="section-area section-sp1 doctors-list">
            <div className="container">
              <h1>{this.props.t('Best doctors in India')}</h1>

              <div className="mb-3">
                <FiltersMenu/>
              </div>

              <div className="row">
                <div className="col-lg-10 mb-3 g-0">
                  <div className="container">
                    <div className="row">
                      {
                        this.state.doctors.map((doctor) => (
                          <div
                            className="col-12 col-md-6 col-lg-4 mb-3"
                            key={doctor.id}
                          >
                            <DoctorCardWithDetails doctor={doctor}/>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>

                <div className="col-lg-2">
                  <PaginationContext.Consumer>
                    {
                      (pagination) => (
                        <Filters
                          onChange={() => {
                            pagination.reset();
                          }}
                        >
                          <FilterBySpecialities
                            i18Initialized={this.props.i18Initialized}
                          />
                        </Filters>
                      )
                    }
                  </PaginationContext.Consumer>
                </div>
              </div>

              <PaginationWithTranslation
                i18Initialized={this.props.i18Initialized}
                items={this.state.doctors}
                count={this.props.count}
                onSet={(doctors) => {
                  this.setState({
                    doctors: doctors as Doctor[],
                  });
                }}
                onLoad={(page, limit) => {
                  return this.fetchDoctors(page, limit);
                }}
              />
            </div>
          </section>
        </div>
      </>
    );
  }
}

const WithTrans = withTranslation()(Doctors);

export { WithTrans as Doctors };
