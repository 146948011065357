import { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import {WithTranslation, withTranslation} from "react-i18next";

type Props = WithTranslation;

type State = Record<string, never>;

import './Error404.scss';

class Error404 extends PureComponent<Props, State> {
  render(){
    return (
        <>
          <div className="page-content bg-white">
            <section className="section-area section-sp2 error-404">
              <div className="container">
                <div className="inner-content">
                  <h2 className="error-title">404</h2>
                  <h3 className="error-text">
                    The Page you were looking
                    for, couldn't be found.
                  </h3>
                  <p>The page you are looking for might have been removed,
                    had its name changed, or is temporarily unavailable.
                  </p>
                  <div className="clearfix">
                    <Link
                        to={`/${this.props.i18n.language}`}
                        className="btn btn-primary m-r5"
                    >
                      Back
                    </Link>
                    <Link
                        to={`/${this.props.i18n.language}`}
                        className="btn btn-primary"
                    >
                      Back To Home
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
    );
  }
}

const WithTrans = withTranslation()(Error404);

export { WithTrans as Error404 };

export default WithTrans;